import React, { useEffect, useState } from "react";
import NewFooterIndex from "../NewLanding/NewFooterIndex";
// import NewLandingHeader from "../NewLanding/NewLandingHeader";
import { useDispatch, useSelector } from "react-redux";
import { profileStart } from "../../store/actions/UserAction";
import PageLoader from "../helper/PageLoader";
import SomethingWentWrong from "../Common/SomethingWentWrong";
import HeaderIndex from "./Header/HeaderIndex";

const LandingLayout = (props) => {
  const dispatch= useDispatch()
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("token") || sessionStorage.getItem("token")
      ? true
      : false
  );
  const profile= useSelector((state)=>state.user.profile)

  useEffect(()=>{
  
    if(isLoggedIn &&!Object.keys(profile.data).length>0){
      dispatch(profileStart())
    }
  },[])


  return (
    <>
    {profile.buttonDisable? <PageLoader/>:(Object.keys(profile.data).length>0||!isLoggedIn)?
    <>
      <div className="main-wrapper">
        {/* <NewLandingHeader /> */}
        <HeaderIndex/>
        {props.children}
      </div>
      <NewFooterIndex/>
      </>
    :<SomethingWentWrong/>
    }
    </>
  );
};

export default LandingLayout;
