import React, { useState } from "react";
import {Container, Row, Col, Accordion, Image } from "react-bootstrap";
import { Link } from "react-router-dom";

const FAQIndex = (props) => {
  return (
    <>
      <div className="faq-sec sm-padding">
        <Container fluid>
            <Row className="justify-content-md-center">
                <Col md={8} lg={9} className="text-center">
                    <div className="section-title">
                        <h2>FAQ</h2>
                    </div>
                </Col>
            </Row>
            <div className="faq-box">
            <Row className="align-items-center">
                <Col md={12} xl={6}>
                    <div className="faq-img-sec">
                        <Image
                            className="faq-img-1"
                          
                            src={
                                window.location.origin +
                                "/img/gif/faq.gif"
                            }
                            type="image/png"
                        />
                    </div>
                </Col>
                <Col md={12} xl={6}>
                  <Accordion defaultActiveKey="0" className="faq-accordion-1">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>What is HeraldEX?</Accordion.Header>
                      <Accordion.Body>
                      HeraldEX is an advanced cryptocurrency exchange platform that facilitates the buying, selling, Swapping and trading a variety of digital currencies. It provides a user-friendly interface designed to cater for both beginners and experienced traders, enabling seamless transactions.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>How do I create an account on HeraldEX?</Accordion.Header>
                      <Accordion.Body>
                      To create an account on HeraldEX, Click on 'Sign Up.' Fill in the required personal details, verify your email, and complete the KYC (Know Your Customer) process. Once verified, you can start using the platform immediately.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>What security measures does HeraldEX implement?</Accordion.Header>
                      <Accordion.Body>
                      HeraldEX enforces robust security protocols, including two-factor authentication (2FA), cold storage for assets, advanced data encryption, and continuous real-time monitoring to protect user funds and personal information.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>What fees are associated with trading on HeraldEX?</Accordion.Header>
                      <Accordion.Body>
                      HeraldEX offers a transparent fee structure with competitive rates for trading, deposits, and withdrawals. Fees are detailed clearly on the platform, ensuring no hidden costs for users.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>How can I access customer support on HeraldEX?</Accordion.Header>
                      <Accordion.Body>
                      Customer support is available 24/7 via the support section on the HeraldEX, by email, or through live chat. Our support team is ready to assist with any inquiries or issues that may arise during trading.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>What educational resources does HeraldEX offer for traders?</Accordion.Header>
                      <Accordion.Body>
                      HeraldEX features a Crypto Learning Hub, providing tutorials and guides to help users enhance their understanding of cryptocurrency trading.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                      <Accordion.Header>How does the KYC process enhance security on  HeraldEX?</Accordion.Header>
                      <Accordion.Body>
                      The KYC process verifies user identities to prevent fraud and ensure secure transactions, fostering a safe trading environment.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7">
                      <Accordion.Header>What deposit methods are available on HeraldEX?</Accordion.Header>
                      <Accordion.Body>
                      HeraldEX allows deposits through bank transfers, credit/debit cards, and various cryptocurrencies.
                      </Accordion.Body>
                    </Accordion.Item>
                    {/* <Accordion.Item eventKey="8">
                      <Accordion.Header>What assets can I trade on HeraldEX?</Accordion.Header>
                      <Accordion.Body>
                      A wide variety of cryptocurrencies, including major and emerging tokens.
                      </Accordion.Body>
                    </Accordion.Item> */}
                    <Accordion.Item eventKey="8">
                      <Accordion.Header>How does HERALDEX ensure user privacy?</Accordion.Header>
                      <Accordion.Body>
                      User privacy is protected with strict data handling policies and encryption.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Col>
            </Row>
            </div>
        </Container>
      </div>
    </>
  );
};

export default FAQIndex;
