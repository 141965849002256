import React, { useEffect, useState } from "react";
// import FooterIndex from "./Footer/FooterIndex";
import HeaderIndex from "./Header/HeaderIndex";
import NewFooterIndex from "../NewLanding/NewFooterIndex";
import { useDispatch, useSelector } from "react-redux";
import { profileStart } from "../../store/actions/UserAction";
import PageLoader from "../helper/PageLoader";
import SomethingWentWrong from "../Common/SomethingWentWrong";

const MainLayout = (props) => {
  const dispatch= useDispatch()
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("token") || sessionStorage.getItem("token")
      ? true
      : false
  );
  const profile= useSelector((state)=>state.user.profile)

  useEffect(()=>{
    if(isLoggedIn&&!Object.keys(profile.data).length>0){
      dispatch(profileStart())
    }
  },[])

  return (
    <>
    {profile.buttonDisable? <PageLoader/>:(Object.keys(profile.data).length>0||!isLoggedIn)?
    <>
      <HeaderIndex />
      <div className="main-wrapper">
        {props.children}
      </div>
      {/* <FooterIndex/> */}
      <div className="auth-footer">
        <NewFooterIndex/>
      </div>
    </>
    :<SomethingWentWrong/>
    }
    </>
  );
};

export default MainLayout;
