import React, { useEffect, useCallback, useState, useRef } from "react";
import { Button, Form, Image, Tab, Nav, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { updateBuyData } from "../../store/actions/UserAction";
import { useTranslation } from "react-multi-lang";
import {
  buyTokensViaWalletStart,
  cardTransactionsStart,
  getAdminBankAccountStart,
  tokenBuyStart,
} from "../../store/actions/BuyAction";
import CommonCenterLoader from "../helper/CommonCenterLoader";
import { useDropzone } from "react-dropzone";
import { setTokenBuyData } from "../../store/actions/BuyAction";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { getErrorNotificationMessage } from "../helper/ToastNotification";
import CustomLazyLoad from "../helper/CustomLazyLoad";
import { fetchWalletDetailsStart } from "../../store/actions/WalletAction";
import NoDataFound from "../helper/NoDataFound";
import Upcomingevent from "./Upcomingevent";
import { Checkbox, Radio, Switch } from "pretty-checkbox-react";
import "@djthoms/pretty-checkbox";

const TokenBuy = (props) => {
  const dispatch = useDispatch();
  const t = useTranslation("buy_index");
  const navigate = useNavigate();
  const formRef = useRef(null);
  const formBankRef = useRef(null);
  const tokenBuyData = useSelector((state) => state.buy.tokenBuyData);
  const buyData = useSelector((state) => state.user.buyData);
  const setBuyData = (data) => dispatch(updateBuyData(data));
  const adminBankAccount = useSelector((state) => state.buy.adminBankAccount);
  const cardTransactions = useSelector((state) => state.buy.cardTransactions);
  const buyTokensViaWallet = useSelector((state) => state.buy.buyTokensViaWallet);
  const updateTokenBuyData = (data) => dispatch(setTokenBuyData(data));
  const tokenBuy = useSelector((state) => state.buy.tokenBuy);
  const wallet = useSelector((state) => state.wallet.walletData);
  const [errorMessage, setErrorMessage] = useState(false);
  const [paymentType, setPaymentType] = useState("bank");
  const [cardNumber, setCardNumber] = useState("");
  const [skipRender, setSkipRender] = useState(true);
  const [buyBankstep, setBuyBankStep] = useState(1);
  const [agreement, setAgreement] = useState(false);
  const validationSchema = Yup.object().shape({
    payment_id: Yup.string()
      .required(t("payment_id.required"))
      .matches(/^(?=.*[a-zA-Z-0-9])/, t("invalid"))
      .min(3, t("payment_id.invalid"))
      .max(35, t("max_length_invalid")),
    // payment_file: Yup.string().required(t("please_upload_payment_screenshot")),
  });

  const cardSchema = Yup.object().shape({
    card_number: Yup.string()
      .required(t("card_number.required"))
      .test(
        "len",
        t("card_number.mandatory"),
        (val) => String(val).replace(/\s+/g, "").length === 16
      ),
    cvv: Yup.string()
      .required(t("cvv.required"))
      .matches(/^\d{3,5}$/, t("cvv.invalid")),
    expiry_month: Yup.string()
      .required(t("expiry_month.required"))
      .matches(
        /^(0[1-9]|1[0-2])$/,
        t("expiry_month.invalid")
      ),
    expiry_year: Yup.number()
      .required(t("expiry_year.required"))
      .min(
        new Date().getFullYear(),
        t("expiry_year.min_invalid", { value: new Date().getFullYear() })
      )
      .typeError(t("expiry_year.max_invalid"))
      .integer(t("expiry_year.max_invalid"))
      .test(
        "len",
        t("expiry_year.max_invalid"),
        (val) => String(val).length === 4
      ),
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(
      getAdminBankAccountStart({
        currency: tokenBuyData.buy_via_currency,
      })
    );
  }, []);

  useEffect(() => {
    dispatch(fetchWalletDetailsStart());
  }, []);

  useEffect(() => {
    if (
      adminBankAccount.data &&
      Object.keys(adminBankAccount.data).length > 0
    ) {
      setBuyData({
        ...buyData,
        admin_bank_account_id:
          adminBankAccount.data.admin_bank_account.admin_bank_account_id,
      });
      updateTokenBuyData({
        ...tokenBuyData,
        admin_bank_account_id:
          adminBankAccount.data.admin_bank_account.admin_bank_account_id,
      });
      setPaymentType("bank");
    } else {
      setPaymentType("card");
    }
  }, [adminBankAccount]);

  const [files, setFiles] = useState([]);

  const onDropRejected = useCallback((rejectedFiles) => {
    if (rejectedFiles.length > 1) {
      setErrorMessage(t("please_upload_only_one_file_at_a_time"));
    } else {
      const invalidFiles = rejectedFiles.filter(
        (file) => !["image/jpeg", "image/png", "image/jpg"].includes(file.type)
      );
      if (invalidFiles.length > 0) {
        setErrorMessage(t("invalid_file_format"));
      }
    }
  }, []);

  const onDropAccepted = () => {
    setErrorMessage("");
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDropRejected,
    onDropAccepted,
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/jpg": [],
    },
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      formBankRef?.current.setFieldValue("payment_file", acceptedFiles[0]);
    },
  });

  const images = files.map((file, key) => (
    <div className="preview-added-image" key={key}>
      <Button
        variant="link"
        className="preview-cancel"
        onClick={(e) => {
          e.stopPropagation();
          formBankRef.current.setFieldValue("payment_file", "");
          setErrorMessage(t("please_upload_payment_screenshot"));
          setFiles([]);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="#fff"
          viewBox="0 0 24 24"
        >
          <path d="M18 6a1 1 0 00-1.414 0L12 10.586 7.414 6A1 1 0 006 6a1 1 0 000 1.414L10.586 12 6 16.586A1 1 0 006 18a1 1 0 001.414 0L12 13.414 16.586 18A1 1 0 0018 18a1 1 0 000-1.414L13.414 12 18 7.414A1 1 0 0018 6z"></path>
        </svg>
      </Button>
      <CustomLazyLoad
        className="product-preview"
        key={file.name}
        src={file.preview}
        alt="image-preview"
      />
    </div>
  ));

  const onSubmit = (values) => {
    const {
      step,
      payment_id,
      amountConversion,
      payment_file,
      forex_exchange_rate,
      forex_source_currency,
      crypto_exchange_rate,
      crypto_source_currency,
      commission,
      minAmount,
      ...rest
    } = tokenBuyData;
    if (files.length > 0) {
      dispatch(
        tokenBuyStart({
          ...rest,
          payment_id: values.payment_id,
          payment_file: files[0],
          exchange_rate: !isNaN((tokenBuyData.to_currency_conversion_value/tokenBuyData.from_amount))?(tokenBuyData.to_currency_conversion_value/tokenBuyData.from_amount).toFixed(8):0.0000,
        })
      );
    } else {
      // getErrorNotificationMessage(t("please_upload_payment_screenshot"));
      dispatch(
        tokenBuyStart({
          ...rest,
          payment_id: values.payment_id,
          exchange_rate: !isNaN((tokenBuyData.to_currency_conversion_value/tokenBuyData.from_amount))?(tokenBuyData.to_currency_conversion_value/tokenBuyData.from_amount).toFixed(8):0.0000,
        })
      );
    }
  };

  const onCardTransaction = (values) => {
    const data = {
      ...values,
      card_number: values.card_number.replace(/\s+/g, ""),
    };
    const {
      step,
      payment_id,
      amountConversion,
      payment_file,
      forex_exchange_rate,
      forex_source_currency,
      crypto_exchange_rate,
      crypto_source_currency,
      commission,
      minAmount,
      ...rest
    } = tokenBuyData;
    dispatch(
      cardTransactionsStart({
        ...rest,
        ...data,
      })
    );
  };

  useEffect(() => {
    if (
      !skipRender &&
      !tokenBuy.loading &&
      Object.keys(tokenBuy.data).length > 0
    ) {
      updateTokenBuyData({
        ...tokenBuyData,
        step: 3,
      });
    }
    setSkipRender(false);
  }, [tokenBuy]);

  useEffect(() => {
    if (
      !skipRender &&
      !cardTransactions.loading &&
      Object.keys(cardTransactions.data).length > 0
    ) {
      updateTokenBuyData({
        ...tokenBuyData,
        step: 3,
      });
    }
    setSkipRender(false);
  }, [cardTransactions]);

  useEffect(() => {
    if (
      !skipRender &&
      !buyTokensViaWallet.loading &&
      Object.keys(buyTokensViaWallet.data).length > 0
    ) {
      updateTokenBuyData({
        ...tokenBuyData,
        step: 3,
      });
    }
    setSkipRender(false);
  }, [buyTokensViaWallet]);

  const walletSubmit = () => {
    const {
      step,
      payment_id,
      amountConversion,
      payment_file,
      forex_exchange_rate,
      forex_source_currency,
      crypto_exchange_rate,
      crypto_source_currency,
      admin_bank_account_id,
      commission,
      minAmount,
      ...rest
    } = tokenBuyData;
    dispatch(
      buyTokensViaWalletStart({
        ...rest,
      })
    );
  };
  const handleChange = (e) => {
    const { value } = e.target;
    const unformattedValue = value.replace(/\s+/g, "");
    if (unformattedValue.length > 16) {
      return;
    }
    const formattedValue = formatNumber(value);
    setCardNumber(formattedValue);
    if (formRef.current) {
      formRef.current.setFieldValue("card_number", formattedValue);
    }
  };

  const formatNumber = (number) => {
    return number
      .replace(/\s+/g, "")
      .split("")
      .reduce((seed, next, index) => {
        if (index !== 0 && index % 4 === 0) {
          seed += " ";
        }
        return seed + next;
      }, "");
  };

  return (
    <>
      <div className="single-page-header"></div>
      {adminBankAccount.loading ? (
        <CommonCenterLoader />
      ) : (
        // ) : Object.keys(adminBankAccount.data).length > 0 ? (
        <div>
          <div
            className="back-arrow-btn-sec out_space_mx_1_5"
            onClick={() => updateTokenBuyData({ ...tokenBuyData, step: 1 })}
          >
            <Image
              src={window.location.origin + "/img/exchange/back-arrow-icon.svg"}
              className="back-arrow-btn"
            />
            <h4>{t("payment_type")}</h4>
          </div>

          <Tab.Container id="deposit-tabs" defaultActiveKey="bank">
            <div className="deposit_fiat_nav_list out_space_mx_1_5">
              <Nav variant="pills" className="">
                <Nav.Item>
                  <Nav.Link eventKey="bank" className="wallet_tab_btn">
                    {t("deposit_to_bank")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="card" className="wallet_tab_btn">
                    {t("deposit_by_card")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="wallet" className="wallet_tab_btn">
                    {t("deposit_by_wallet")}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <Tab.Content>
              <Tab.Pane eventKey="bank">
                {Object.keys(adminBankAccount.data).length > 0 ? (
                  <React.Fragment>
                    {buyBankstep == 1 ? (
                      <>
                        {tokenBuyData.buy_via_currency == "INR" ? (
                          <>
                            <div className="bank-grid-layout">
                              <div className="deposit-amt-details over_space_2 out_space_mx_1_5">
                                <div className="deposit-amt-details-info out_space_mx_1_5">
                                  <div className="deposit-amt-pay" >
                                    <span className="amt-pay-label">{t("send")} </span>
                                    <span className="amt-pay-amt">{tokenBuyData.from_amount} {parseFloat(tokenBuyData.buy_via_currency)}</span>
                                  </div>
                                  <div className="deposit-amt-get">
                                    <span className="amt-pay-label">{t("recieve")} </span>
                                    <span className="amt-pay-amt">{tokenBuyData.to_currency_conversion_value }{tokenBuyData.token_type}</span>
                                  </div>
                                </div>
                                <div className="efi-swap-exchange-info efi-info-frame">
                                  <p>
                                    {" "}
                                    <span>{t("estimated_rate")} </span>{" "}
                                    <span>
                                    1 {tokenBuyData.buy_via_currency}={!isNaN((tokenBuyData.to_currency_conversion_value/tokenBuyData.from_amount))?(tokenBuyData.to_currency_conversion_value/tokenBuyData.from_amount).toFixed(8):0.0000} {tokenBuyData.token_type}
                                    </span>
                                  </p>
                                </div>
                              </div>

                              <div className="deposit_foreign_bank_details out_space_mx_1_5">
                                <div className="deposit_bank_account_details over_space_2">
                                  <div className="deposit_bank_head item_line_between out_space_mx_1_5">
                                    <div className="deposit_bank_name">
                                      <div className="deposit_bank_icons">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="22"
                                          height="22"
                                          enableBackground="new 0 0 512 512"
                                          viewBox="0 0 24 24"
                                        >
                                          <path
                                            d="M24 23a1 1 0 01-1 1H1a1 1 0 010-2h22a1 1 0 011 1zM.291 8.552a2.443 2.443 0 01.153-2.566 4.716 4.716 0 011.668-1.5L9.613.582a5.174 5.174 0 014.774 0l7.5 3.907a4.716 4.716 0 011.668 1.5 2.443 2.443 0 01.153 2.566A2.713 2.713 0 0121.292 10H21v8h1a1 1 0 010 2H2a1 1 0 010-2h1v-8h-.292A2.713 2.713 0 01.291 8.552zM5 18h3v-8H5zm5-8v8h4v-8zm9 0h-3v8h3zM2.063 7.625A.717.717 0 002.708 8h18.584a.717.717 0 00.645-.375.452.452 0 00-.024-.5 2.7 2.7 0 00-.949-.864l-7.5-3.907a3.176 3.176 0 00-2.926 0l-7.5 3.907a2.712 2.712 0 00-.949.865.452.452 0 00-.026.499z"
                                            data-original="#000000"
                                          ></path>
                                        </svg>
                                      </div>
                                      <div className="deposit_bank_info">
                                        <h5>
                                          {
                                            adminBankAccount.data
                                              .admin_bank_account.bank_name
                                          }
                                        </h5>
                                      </div>
                                    </div>
                                    <div className="deposit_bank_type item_flex_x_5">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        fill="none"
                                        viewBox="0 0 16 17"
                                      >
                                        <path
                                          stroke="#252525"
                                          strokeLinecap="round"
                                          strokeWidth="2"
                                          d="M1.333 15.813V15a4.875 4.875 0 014.875-4.875h3.25A4.875 4.875 0 0114.333 15v.813M7.833 7.688a3.25 3.25 0 110-6.5 3.25 3.25 0 010 6.5z"
                                        ></path>
                                      </svg>
                                      <span>
                                        {adminBankAccount.data?.admin_bank_account
                                          ?.account_holder_name
                                          ? adminBankAccount.data
                                            ?.admin_bank_account
                                            ?.account_holder_name
                                          : "N/A"}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="deposit_bank_accout_number ">
                                    <h3>
                                      {
                                        adminBankAccount.data.admin_bank_account
                                          .account_number
                                      }
                                    </h3>
                                  </div>
                                </div>

                                <div className="deposit_bank_other_details over_space_2">
                                  <div>
                                    <div className="deposit_other_titles out_space_mx_1_5">
                                      <h5>{t("beneficiary")}</h5>
                                    </div>
                                    <div className="deposit_other_card">
                                      <div className="deposit_other_info_card">
                                        <h4>{t("ifsc_code")}</h4>

                                        <p>
                                          {adminBankAccount.data
                                            ?.admin_bank_account?.bank_code
                                            ? adminBankAccount.data
                                              ?.admin_bank_account?.bank_code
                                            : "N/A"}
                                        </p>
                                      </div>
                                      <div className="deposit_other_info_card">
                                        <h4>{t("account_type")}</h4>

                                        <p>
                                          {adminBankAccount.data.admin_bank_account.account_type
                                            ?.charAt(0)
                                            ?.toUpperCase() +
                                            adminBankAccount.data.admin_bank_account.account_type?.slice(
                                              1
                                            )}{" "}
                                          account
                                        </p>
                                      </div>
                                      {/* <div className="deposit_other_info_card">
                                  <h4>
                                    {" "}
                                    {t("swift_code_correspondent_bank")}
                                  </h4>
                                  <p>
                                    {
                                      adminBankAccount.data
                                        .admin_bank_account
                                        .swift_code_correspondent_bank
                                    }
                                  </p>
                                </div> */}
                                    </div>
                                  </div>

                                  {/* <div className="deposit_space_bar"></div>
                            <div>
                              <div className="deposit_other_titles out_space_mx_1_5">
                                <h5>Correspondent</h5>
                              </div>
                              <div className="deposit_other_card">
                                <div className="deposit_other_info_card">
                                  <h4>
                                    {" "}
                                    {t("swift_code_correspondent_bank")}
                                  </h4>
                                  <p>
                                    {
                                      adminBankAccount.data
                                        .admin_bank_account
                                        .swift_code_correspondent_bank
                                    }
                                  </p>
                                </div>
                                <div className="deposit_other_info_card">
                                  <h4>{t("correspondent_account_no")}</h4>
                                  <p>
                                    {
                                      adminBankAccount.data
                                        .admin_bank_account
                                        .correspondent_account_no
                                    }
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="deposit_space_bar"></div>
                            <div>
                              <div className="deposit_other_titles out_space_mx_1_5">
                                <h5>Address</h5>
                              </div>
                              <div className="deposit_other_info_card">
                                <h4> {t("bank_address")} </h4>
                                <p>
                                  {
                                    adminBankAccount.data.admin_bank_account
                                      .bank_address
                                  }
                                </p>
                              </div>
                            </div> */}
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="bank-grid-layout">
                              <div className="deposit-amt-details over_space_2 out_space_mx_1_5">
                                <div className="deposit-amt-details-info out_space_mx_1_5">
                                  <div className="deposit-amt-pay" >
                                    <span className="amt-pay-label">{t("send")} </span>
                                    <span className="amt-pay-amt">{tokenBuyData.from_amount} {tokenBuyData.buy_via_currency}</span>
                                  </div>
                                  <div className="deposit-amt-get">
                                    <span className="amt-pay-label">{t("recieve")} </span>
                                    <span className="amt-pay-amt">{tokenBuyData.to_currency_conversion_value} {tokenBuyData.token_type}</span>
                                  </div>
                                </div>
                                <div className="efi-swap-exchange-info efi-info-frame">
                                  <p>
                                    {" "}
                                    <span>{t("estimated_rate")} </span>{" "}
                                    <span>
                                    1 {tokenBuyData.buy_via_currency}={!isNaN((tokenBuyData.to_currency_conversion_value/tokenBuyData.from_amount))?(tokenBuyData.to_currency_conversion_value/tokenBuyData.from_amount).toFixed(8):0.0000} {tokenBuyData.token_type}
                                    </span>
                                  </p>
                                </div>
                              </div>
                              <div className="deposit_foreign_bank_details out_space_mx_1_5">
                                <div className="deposit_bank_account_details over_space_2">
                                  <div className="deposit_bank_head item_line_between out_space_mx_1_5">
                                    <div className="deposit_bank_name">
                                      <div className="deposit_bank_icons">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="22"
                                          height="22"
                                          enableBackground="new 0 0 512 512"
                                          viewBox="0 0 24 24"
                                        >
                                          <path
                                            d="M24 23a1 1 0 01-1 1H1a1 1 0 010-2h22a1 1 0 011 1zM.291 8.552a2.443 2.443 0 01.153-2.566 4.716 4.716 0 011.668-1.5L9.613.582a5.174 5.174 0 014.774 0l7.5 3.907a4.716 4.716 0 011.668 1.5 2.443 2.443 0 01.153 2.566A2.713 2.713 0 0121.292 10H21v8h1a1 1 0 010 2H2a1 1 0 010-2h1v-8h-.292A2.713 2.713 0 01.291 8.552zM5 18h3v-8H5zm5-8v8h4v-8zm9 0h-3v8h3zM2.063 7.625A.717.717 0 002.708 8h18.584a.717.717 0 00.645-.375.452.452 0 00-.024-.5 2.7 2.7 0 00-.949-.864l-7.5-3.907a3.176 3.176 0 00-2.926 0l-7.5 3.907a2.712 2.712 0 00-.949.865.452.452 0 00-.026.499z"
                                            data-original="#000000"
                                          ></path>
                                        </svg>
                                      </div>
                                      <div className="deposit_bank_info">
                                        <h5>
                                          {
                                            adminBankAccount.data
                                              .admin_bank_account.bank_name
                                          }
                                        </h5>
                                      </div>
                                    </div>
                                    <div className="deposit_bank_type item_flex_x_5">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        fill="none"
                                        viewBox="0 0 16 17"
                                      >
                                        <path
                                          stroke="#252525"
                                          strokeLinecap="round"
                                          strokeWidth="2"
                                          d="M1.333 15.813V15a4.875 4.875 0 014.875-4.875h3.25A4.875 4.875 0 0114.333 15v.813M7.833 7.688a3.25 3.25 0 110-6.5 3.25 3.25 0 010 6.5z"
                                        ></path>
                                      </svg>
                                      <span>
                                        {
                                          adminBankAccount.data.admin_bank_account
                                            .account_holder_name
                                        }
                                      </span>
                                    </div>
                                  </div>
                                  <div className="deposit_bank_accout_number ">
                                    <h3>
                                      {
                                        adminBankAccount.data.admin_bank_account
                                          .account_number
                                      }
                                    </h3>
                                  </div>
                                </div>

                                <div className="deposit_bank_other_details over_space_2">
                                  {(adminBankAccount.data?.admin_bank_account
                                    .iban_no ||
                                    adminBankAccount.data?.admin_bank_account
                                      ?.swift_code_correspondent_bank ||
                                    adminBankAccount.data?.admin_bank_account
                                      ?.account_holder_name) && (
                                      <>
                                        <div>
                                          <div className="deposit_other_titles out_space_mx_1_5">
                                            <h5>{t("beneficiary")}</h5>
                                          </div>
                                          <div className="deposit_other_card">
                                            {adminBankAccount.data
                                              ?.admin_bank_account?.bank_code && (
                                                <div className="deposit_other_info_card">
                                                  <h4>{t("iban_no")}</h4>
                                                  <p>
                                                    {
                                                      adminBankAccount.data
                                                        .admin_bank_account.bank_code
                                                    }
                                                  </p>
                                                </div>
                                              )}
                                            {adminBankAccount.data
                                              ?.admin_bank_account
                                              ?.swift_code_correspondent_bank && (
                                                <div className="deposit_other_info_card">
                                                  <h4>
                                                    {" "}
                                                    {t(
                                                      "swift_code_correspondent_bank"
                                                    )}
                                                  </h4>
                                                  <p>
                                                    {
                                                      adminBankAccount.data
                                                        .admin_bank_account
                                                        .swift_code_correspondent_bank
                                                    }
                                                  </p>
                                                </div>
                                              )}
                                            {adminBankAccount.data
                                              ?.admin_bank_account
                                              ?.account_holder_name && (
                                                <div className="deposit_other_info_card">
                                                  <h4> {t("account_holder_name")}</h4>
                                                  <p>
                                                    {
                                                      adminBankAccount.data
                                                        .admin_bank_account
                                                        .account_holder_name
                                                    }
                                                  </p>
                                                </div>
                                              )}
                                            {adminBankAccount.data
                                              ?.admin_bank_account
                                              ?.account_type && (
                                                <div className="deposit_other_info_card">
                                                  <h4> {t("account_type")}</h4>
                                                  <p>
                                                    {adminBankAccount.data.admin_bank_account?.account_type
                                                      ?.charAt(0)
                                                      ?.toUpperCase() +
                                                      adminBankAccount.data.admin_bank_account?.account_type?.slice(
                                                        1
                                                      )}{" "}
                                                    account
                                                  </p>
                                                </div>
                                              )}
                                          </div>
                                        </div>

                                        <div className="deposit_space_bar"></div>
                                      </>
                                    )}
                                  {(adminBankAccount.data?.admin_bank_account
                                    ?.swift_code_correspondent_bank ||
                                    adminBankAccount.data?.admin_bank_account
                                      ?.correspondent_account_no) && (
                                      <>
                                        <div>
                                          <div className="deposit_other_titles out_space_mx_1_5">
                                            <h5>{t("correspondent")}</h5>
                                          </div>
                                          <div className="deposit_other_card">
                                            {adminBankAccount.data
                                              ?.admin_bank_account
                                              ?.swift_code_correspondent_bank && (
                                                <div className="deposit_other_info_card">
                                                  <h4>
                                                    {" "}
                                                    {t(
                                                      "swift_code_correspondent_bank"
                                                    )}
                                                  </h4>
                                                  <p>
                                                    {
                                                      adminBankAccount.data
                                                        .admin_bank_account
                                                        .swift_code_correspondent_bank
                                                    }
                                                  </p>
                                                </div>
                                              )}
                                            {adminBankAccount.data
                                              ?.admin_bank_account
                                              ?.correspondent_account_no && (
                                                <div className="deposit_other_info_card">
                                                  <h4>
                                                    {t("correspondent_account_no")}
                                                  </h4>
                                                  <p>
                                                    {
                                                      adminBankAccount.data
                                                        .admin_bank_account
                                                        .correspondent_account_no
                                                    }
                                                  </p>
                                                </div>
                                              )}
                                          </div>
                                        </div>
                                        <div className="deposit_space_bar"></div>
                                      </>
                                    )}
                                  <div>
                                    <div className="deposit_other_titles out_space_mx_1_5">
                                      <h5>{t("address")}</h5>
                                    </div>
                                    <div className="deposit_other_info_card">
                                      <h4> {t("bank_address")} </h4>
                                      <p>
                                        {
                                          adminBankAccount.data.admin_bank_account
                                            .bank_address
                                        }
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                        <div className="deposit_bank_action_check mb-4">
                          <Checkbox
                            onClick={() => {
                              setAgreement(!agreement);
                            }}
                            checked={agreement}
                            icon={
                              <Image
                                src={window.location.origin + "/img/tick.svg"}
                                className="tick-mark-checkbox"
                              />
                            }
                          >
                            I've read and agree to the Exchange{" "}
                        </Checkbox>
                         <div className="static-page-link-sec">
                         <Link to="/terms-conditions" className="auth_link" target="_blank">
                            Terms & Conditions,
                          </Link>
                          {" "}
                          <Link to="/privacy-agreement" className="auth_link" target="_blank">
                            Privacy Policy
                          </Link> and{" "}
                          <Link to="/risk-disclosure-policy" className="auth_link" target="_blank">
                            Risk Disclosure Statement
                          </Link>{" "}
                         </div>
                        {/* <div className="form_card_others">
                          <p className="out_space_mx_1_5">
                            By continuing you accept our{" "}
                            <Link to="/terms-conditions" className="auth_link">
                              Terms & conditions
                            </Link>{" "}
                            and{" "}
                            <Link to="/privacy-agreement" className="auth_link">
                              Privacy agreement
                            </Link>
                          </p>
                          </div> */}
                        </div>
                        <div className="deposit_bank_action">
                          <Button
                            className="action-btn primary w-100"
                            onClick={() => setBuyBankStep(2)}
                            disabled={!agreement}
                          >
                            {t("continue")}
                          </Button>
                        </div>
                      </>
                    ) : (
                      <>
                        <Formik
                          initialValues={{
                            payment_id: "",
                            payment_file: "",
                          }}
                          validationSchema={validationSchema}
                          innerRef={formBankRef}
                          onSubmit={(values) => onSubmit(values)}
                        >
                          {({
                            values,
                            touched,
                            errors,
                            setFieldValue,
                            setErrors,
                          }) => (
                            <FORM>
                              <div className="deposit_modal_form">
                                <div className="deposit_address_card out_space_mx_1_5">
                                  <div className="deposit_address_field">
                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label>
                                        {t("payment_id.label")}
                                        <span>*</span>
                                      </Form.Label>
                                      <Field
                                        className="form-control"
                                        type="text"
                                        placeholder={t(
                                          "payment_id.placeholder"
                                        )}
                                        name="payment_id"
                                      />
                                      <ErrorMessage
                                        component={"div"}
                                        name="payment_id"
                                        className="text-danger text-right"
                                      />
                                    </Form.Group>
                                  </div>
                                  <div className="crypto_address_field">
                                    <Form.Group
                                      className="mb-0"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label>
                                        {t("payment_picture")}
                                      </Form.Label>

                                      <div {...getRootProps()}>
                                        <input
                                          name="payment_file"
                                          {...getInputProps()}
                                        />
                                        {files.length <= 0 && (
                                          <div className="add-product-upload-box">
                                            <CustomLazyLoad
                                              src={
                                                window.location.origin +
                                                "/img/upload.png"
                                              }
                                              className="upload-icon"
                                            />
                                            <p>
                                              {t(
                                                "upload_your_payment_screenshot_here"
                                              )}
                                              <br />{" "}
                                              <span>
                                                {" "}
                                                {t("accept_only_jpeg_jpg_png")}
                                              </span>
                                            </p>
                                          </div>
                                        )}
                                        <div>{images}</div>
                                      </div>
                                      {!errorMessage && (
                                        <ErrorMessage
                                          component={"div"}
                                          name="payment_file"
                                          className="text-danger text-right mb-0"
                                        />
                                      )}
                                    </Form.Group>
                                    {errorMessage && (
                                      <p className="text-danger text-right mb-0">
                                        {errorMessage}
                                      </p>
                                    )}
                                  </div>
                                </div>
                                <div className="deposit_amount_action item_flex_x_5">
                                  <Button
                                    className="action-btn secondary w-100"
                                    disabled={tokenBuy.buttonDisable}
                                    onClick={() => {
                                      setBuyBankStep(buyBankstep - 1);
                                    }}
                                  >
                                    {t("back")}
                                  </Button>
                                  <Button
                                    className="action-btn primary w-100"
                                    type="submit"
                                    disabled={tokenBuy.buttonDisable}
                                  >
                                    {tokenBuy.buttonDisable
                                      ? t("loading")
                                      : t("continue")}
                                  </Button>
                                </div>
                                <div className="deposit_bank_action">
                                  <p className="out_space_mt_1 other_links">
                                    {t("by_continuing_you_agree_to_our")}{" "}
                                    <Link
                                      className="cookie-txt"
                                      to="/page/privacy-agreement"
                                      target="_blank"
                                    >
                                      {t("cookie_policy")}.
                                    </Link>
                                  </p>
                                </div>
                              </div>
                            </FORM>
                          )}
                        </Formik>
                      </>
                    )}
                  </React.Fragment>
                ) : (
                  <>
                    <NoDataFound />
                  </>
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="card">
                <React.Fragment>
                  <Upcomingevent />
                  {/* <Formik
                    initialValues={{
                      card_number: "",
                      cvv: "",
                      expiry_month: "",
                      expiry_year: "",
                    }}
                    validationSchema={cardSchema}
                    innerRef={formRef}
                    onSubmit={(values) => onCardTransaction(values)}
                  >
                    {({ values, touched, errors, setFieldValue }) => (
                      <FORM>
                        <div className="deposit_modal_form">
                          <div className="deposit_address_card out_space_mx_1_5">
                            <div className="deposit_address_field">
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                              >
                                <Form.Label>
                                  {t("card_number.label")} <span>*</span>{" "}
                                </Form.Label>
                                <Field
                                  className="form-control"
                                  type="text"
                                  onChange={handleChange}
                                  onKeyDown={(e) => {
                                    if (
                                      !/[0-9.]|Backspace|Tab/.test(e.key) &&
                                      !(
                                        (e.ctrlKey &&
                                          (e.key === "v" ||
                                            e.key === "c" ||
                                            e.key === "x" ||
                                            e.key === "a")) ||
                                        e.code == "Space"
                                      )
                                    ) {
                                      e.preventDefault();
                                    }
                                  }}
                                  placeholder={t("card_number.placeholder")}
                                  name="card_number"
                                />
                                <ErrorMessage
                                  component={"div"}
                                  name="card_number"
                                  className="text-danger text-right"
                                />
                              </Form.Group>
                            </div>
                            <div className="deposit_address_field">
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicCVV"
                              >
                                <Form.Label>
                                  {t("cvv.name")} <span>*</span>{" "}
                                </Form.Label>
                                <Field
                                  className="form-control"
                                  type="text"
                                  placeholder={t("cvv.placeholder")}
                                  name="cvv"
                                />
                                <ErrorMessage
                                  component={"div"}
                                  name="cvv"
                                  className="text-danger text-right"
                                />
                              </Form.Group>
                            </div>
                            <div className="deposit_address_field">
                              <Row>
                                <Col>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="formBasicExpiryMonth"
                                  >
                                    <Form.Label>
                                      {t("expiry_month.name")} <span>*</span>
                                    </Form.Label>
                                    <Field
                                      className="form-control"
                                      type="text"
                                      placeholder={t("expiry_month.placeholder")}
                                      name="expiry_month"
                                    />
                                    <ErrorMessage
                                      component={"div"}
                                      name="expiry_month"
                                      className="text-danger text-right"
                                    />
                                  </Form.Group>
                                </Col>
                                <Col>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="formBasicExpiryYear"
                                  >
                                    <Form.Label>
                                      {t("expiry_year.name")} <span>*</span>
                                    </Form.Label>
                                    <Field
                                      className="form-control"
                                      type="text"
                                      placeholder={t("expiry_year.placeholder")}
                                      name="expiry_year"
                                    />
                                    <ErrorMessage
                                      component={"div"}
                                      name="expiry_year"
                                      className="text-danger text-right"
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                            </div>
                          </div>
                          <div className="deposit_amount_action item_flex_x_5">
                            <Button
                              className="action-btn primary w-100"
                              type="submit"
                              disabled={cardTransactions.buttonDisable}
                            >
                              {cardTransactions.buttonDisable
                                ? t("loading")
                                : t("continue")}
                            </Button>
                          </div>
                          <div className="deposit_bank_action">
                            <p className="out_space_mt_1 other_links">
                              {t("by_continuing_you_agree_to_our")}{" "}
                              <Link
                                className="cookie-txt"
                                to="/page/privacy-agreement"
                                target="_blank"
                              >
                                {t("cookie_policy")}.
                              </Link>
                            </p>
                          </div>
                        </div>
                      </FORM>
                    )}
                  </Formik> */}

                </React.Fragment>
              </Tab.Pane>
              <Tab.Pane eventKey="wallet">
                {Object.keys(wallet.data).length > 0 &&
                  Math.round(
                    (wallet.data?.user_wallets?.filter(
                      (data) =>
                        data.currency_code == tokenBuyData.buy_via_currency
                    )[0]?.remaining_original) * 100) / 100 < tokenBuyData.from_amount && (
                    <>
                        <div className="buy-amount-card wallet-deposit-grid">
                          <div className="deposit-amt-details ">
                            <div className="deposit-amt-details-info">
                              <div className="deposit-amt-pay" >
                                <span className="amt-pay-label">{t("send")} </span>
                                <span className="amt-pay-amt">{tokenBuyData.from_amount} {tokenBuyData.buy_via_currency}</span>
                              </div>
                              <div className="deposit-amt-get">
                                <span className="amt-pay-label">{t("recieve")} </span>
                                <span className="amt-pay-amt">{tokenBuyData.to_currency_conversion_value} {tokenBuyData.token_type}</span>
                              </div>
                            </div>
                            <div className="efi-swap-exchange-info efi-info-frame">
                              <p>
                                {" "}
                                <span>{t("estimated_rate")} </span>{" "}
                                <span>
                                {1} {tokenBuyData.buy_via_currency}={!isNaN((tokenBuyData.to_currency_conversion_value/tokenBuyData.from_amount))?(tokenBuyData.to_currency_conversion_value/tokenBuyData.from_amount).toFixed(8):0.0000} {tokenBuyData.token_type}
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className=" buy-amt-frame item_center_column ">
                            <div className="buy-amount-card-avater item_center_column">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                              >
                                <g data-name="Layer 19">
                                  <path
                                    fill="#ff8f00"
                                    d="M20 11h10V9a5 5 0 00-5-5H7a5 5 0 00-5 5v14a5 5 0 005 5h18a5 5 0 005-5v-2H20a5 5 0 010-10zm0 2a3 3 0 000 6h10v-6zm0 5a2 2 0 112-2 2 2 0 01-2 2z"
                                  ></path>
                                  <g fill="#ff6f00">
                                    <path d="M30 9v2h-3a15.05 15.05 0 00-1.36-3.95 15.46 15.46 0 00-2-3H25A5 5 0 0130 9zM27.27 13H30v6h-3.57a15.23 15.23 0 00.84-6zM25.55 21H30v2a5 5 0 01-5 5h-7.13c.37-.15.74-.32 1.1-.5a15.09 15.09 0 006.58-6.5z"></path>
                                  </g>
                                </g>
                              </svg>
                            </div>
                            <div className="buy-amount-card-info mb-3 buy-inr-para">
                              <p>
                                {" "}
                                {t("balance_is_insufficient")}
                                {
                                  wallet.data?.user_wallets?.filter(
                                    (data) =>
                                      data.currency_code ==
                                      tokenBuyData.buy_via_currency
                                  )[0]?.remaining
                                }
                                {t("balance_is_insufficient1")}
                              </p>
                            </div>
                            <div className="buy-amount-card-action">
                              <Button
                                type="button"
                                className="action-btn primary"
                                onClick={() =>
                                  navigate(
                                    `/wallet/${wallet.data?.user_wallets?.filter(
                                      (data) =>
                                        data.currency_code ==
                                        tokenBuyData.buy_via_currency
                                    )[0]?.currency_code
                                    }`
                                  )
                                }
                              >
                                {t("add_token")}
                              </Button>
                            </div>
                          </div>
                        </div>
                    </>
                  )}
                {Object.keys(wallet.data).length > 0 &&
                  parseFloat(
                    wallet.data?.user_wallets?.filter(
                      (data) =>
                        data.currency_code == tokenBuyData.buy_via_currency
                    )[0]?.remaining_original
                  ) >= tokenBuyData.from_amount && (
                    <>
                      <div className="buy-amount-card wallet-deposit-grid">
                        <div className="deposit-amt-details">
                          <div className="deposit-amt-details-info">
                            <div className="deposit-amt-pay" >
                              <span className="amt-pay-label">{t("send")} </span>
                              <span className="amt-pay-amt">{tokenBuyData.from_amount} {tokenBuyData.buy_via_currency}</span>
                            </div>
                            <div className="deposit-amt-get">
                              <span className="amt-pay-label">{t("recieve")} </span>
                              <span className="amt-pay-amt">{tokenBuyData.to_currency_conversion_value} {tokenBuyData.token_type}</span>
                            </div>
                          </div>
                          <div className="efi-swap-exchange-info efi-info-frame">
                            <p>
                              {" "}
                              <span>{t("estimated_rate")} </span>{" "}
                              <span>
                                {1} {tokenBuyData.buy_via_currency}={!isNaN((tokenBuyData.to_currency_conversion_value/tokenBuyData.from_amount))?(tokenBuyData.to_currency_conversion_value/tokenBuyData.from_amount).toFixed(8):0.0000} {tokenBuyData.token_type}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className=" buy-amt-frame item_center_column ">
                          <div className="buy-amount-card-avater item_center_column">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="42"
                              height="42"
                              viewBox="0 0 32 32"
                            >
                              <g data-name="Layer 19">
                                <path
                                  fill="#ff8f00"
                                  d="M20 11h10V9a5 5 0 00-5-5H7a5 5 0 00-5 5v14a5 5 0 005 5h18a5 5 0 005-5v-2H20a5 5 0 010-10zm0 2a3 3 0 000 6h10v-6zm0 5a2 2 0 112-2 2 2 0 01-2 2z"
                                ></path>
                                <g fill="#ff6f00">
                                  <path d="M30 9v2h-3a15.05 15.05 0 00-1.36-3.95 15.46 15.46 0 00-2-3H25A5 5 0 0130 9zM27.27 13H30v6h-3.57a15.23 15.23 0 00.84-6zM25.55 21H30v2a5 5 0 01-5 5h-7.13c.37-.15.74-.32 1.1-.5a15.09 15.09 0 006.58-6.5z"></path>
                                </g>
                              </g>
                            </svg>
                          </div>
                          <div>
                            <div className="buy-amount-card-info mb-3 text-center">
                              <p>
                                {" "}
                                {t("balance_is")}{" "}
                                {
                                  wallet.data?.user_wallets?.filter(
                                    (data) =>
                                      data.currency_code ==
                                      tokenBuyData.buy_via_currency
                                  )[0]?.remaining
                                }
                              </p>
                            </div>
                            <div className="buy-amount-card-action text-center">
                              <Button
                                className="action-btn primary"
                                onClick={walletSubmit}
                                disabled={buyTokensViaWallet.buttonDisable}
                              >
                                {buyTokensViaWallet.buttonDisable
                                  ? t("loading")
                                  : t("continue")}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                {/* <p class="out_space_mt_1 other_links">
                  {t("by_continuing_you_agree_to_our")}{" "}
                  <Link
                    className="cookie-txt"
                    to="/privacy-agreement"
                    target="_blank"
                  >
                    {t("cookie_policy")}.
                  </Link>
                </p> */}
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      )}
    </>
  );
};

export default TokenBuy;
