import React, { useEffect, useState } from "react";
import { Modal, Button, Table, Tab, Nav, Image } from "react-bootstrap";
import { singleTradeTransactionStart } from "../../../store/actions/BuyAction";
import Skeleton from "react-loading-skeleton";
import CopyToClipboard from "react-copy-to-clipboard";
import "../transaction.css";
import { useTranslation } from "react-multi-lang";
import { useSelector, useDispatch } from "react-redux";
import { getSuccessNotificationMessage } from "../../helper/ToastNotification";
import NoDataFound from "../../helper/NoDataFound";
import { useLocation } from "react-router-dom";

const TradingTransactionViewModal = (props) => {
    const location = useLocation()
    const { transactionId } = props;
    const dispatch = useDispatch();
    const t = useTranslation("transactions");
    const singleTradeTransaction = useSelector(
        (state) => state.buy.singleTradeTransaction
    );
    const [view, setView] = useState(false);
    const [skipRender, setSkipRender] = useState(true);
    const [fineryResponse, setFineryResponse] = useState("");

    useEffect(() => {
        dispatch(
            singleTradeTransactionStart({
                trade_transaction_id: transactionId,
            })
        );
        setSkipRender(false);
    }, []);

    useEffect(() => {
        if (
            !skipRender &&
            !singleTradeTransaction.loading &&
            Object.keys(singleTradeTransaction.data).length > 0
        ) {
            singleTradeTransaction.data.trade_transaction.finery_response !== "" &&
                setFineryResponse(
                    JSON.parse(
                        singleTradeTransaction.data.trade_transaction.finery_response
                    )
                );
        }
    }, [singleTradeTransaction]);

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className=" view-modal-transaction"
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">  Trade Transaction Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {singleTradeTransaction.loading ? (
                        <div className="transaction-view-body">
                            <Skeleton height={210} borderRadius={15} />
                            <div className="transaction-basic-tab-wrap out_space_mt_1">
                                <Skeleton height={210} borderRadius={15} />
                            </div>
                        </div>
                    ) : Object.keys(singleTradeTransaction.data).length > 0 ? (

                        <div className="transaction-view-body">
                            <div className="transaction-basic-info-wrap">
                                <div className="transaction-basic-info-head">
                                    <div className="transaction-basic-info-head-titles">
                                        <h3>
                                            {singleTradeTransaction.data.trade_transaction.to_amount_formatted}
                                        </h3>
                                        <p>Tokens Transferred</p>
                                    </div>
                                    {/* <div className="transaction-basic-info-head-titles">
                  <Button className="action-btn overlay">
                    {" "}
                    View Transaction Hash{" "}
                  </Button>
                </div> */}
                                </div>
                                <div className="transaction-space"></div>
                                <div className="transaction-basic-info-content">
                                    <div className="transaction-basic-content-card">
                                        <p>Amount Paid</p>
                                        <h5>
                                            {
                                                singleTradeTransaction.data.trade_transaction
                                                    .from_amount_formatted
                                            }{" "}
                                        </h5>
                                    </div>
                                    <div className="transaction-basic-content-card">
                                        <p>Status</p>
                                        {/* <div className="pay-badge succes">Success</div> */}
                                        {/* <p
                      className={`status ${
                        singleTradeTransaction.data.trade_transaction.status == 1
                          ? "succes"
                          :singleTradeTransaction.data.trade_transaction.status == 2? "denied":"pending"
                      } `}
                    >
                      {singleTradeTransaction.data.trade_transaction.status_formatted}
                    </p> */}
                                        <div
                                            className={`table_badge  ${singleTradeTransaction.data.trade_transaction.status == 1 ? "success" : singleTradeTransaction.data.trade_transaction.status == 0 ? "pending" : "fail"
                                                }`}
                                        >
                                            {" "}
                                            {singleTradeTransaction.data.trade_transaction.status_formatted}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="transaction-basic-tab-wrap out_space_mt_1">
                                <Tab.Container
                                    id="transaction-basic-tab"
                                    defaultActiveKey="transaction"
                                >
                                    <Nav variant="pills">
                                        <Nav.Item>
                                            <Nav.Link eventKey="transaction" className="view_tab_btn">
                                                Transaction Details
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="bank" className="view_tab_btn">
                                                Deals Details
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="transaction">
                                            <div className="transaction-tab-frame">
                                                <div className="transaction-tab-info-card">
                                                    <p>Exchange Rate</p>
                                                    <h4>
                                                        {
                                                            singleTradeTransaction.data.trade_transaction
                                                                .adjusted_exchange_rate_formatted
                                                        }
                                                    </h4>
                                                </div>
                                                {singleTradeTransaction.data.trade_transaction.commission_rate ? <div className="transaction-tab-info-card">
                                                    <p>Commission (%)</p>
                                                    <h4>{singleTradeTransaction.data.trade_transaction.commission_rate}</h4>
                                                </div> : null}
                                                {singleTradeTransaction.data.trade_transaction.commission ? <div className="transaction-tab-info-card">
                                                    <p>Commission Rate</p>
                                                    <h4>{singleTradeTransaction.data.trade_transaction.commission}</h4>
                                                </div> : null}
                                                <div className="transaction-tab-info-card">
                                                    <p>Transaction Date</p>
                                                    <h4>
                                                        {
                                                            singleTradeTransaction.data.trade_transaction
                                                                .created_at_formatted
                                                        }{" "}
                                                    </h4>
                                                </div>
                                                {singleTradeTransaction.data.trade_transaction.unique_id ? (
                                                    <div className="transaction-tab-info-card">
                                                        <p>Transaction Reference Id</p>
                                                        <h4 className="copy-view-card">
                                                            {" "}
                                                            <span>
                                                                {
                                                                    singleTradeTransaction.data.trade_transaction.unique_id
                                                                }
                                                            </span>{" "}
                                                            <CopyToClipboard
                                                                text={
                                                                    singleTradeTransaction.data.trade_transaction.unique_id
                                                                }
                                                                onCopy={() =>
                                                                    getSuccessNotificationMessage(
                                                                        t("Transaction reference id")
                                                                    )
                                                                }
                                                            >
                                                                <Button className="action-btn copy-btn item_center">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="20"
                                                                        height="20"
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                        stroke="#298BFF"
                                                                        stroke-width="2"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                        class="icon icon-tabler icons-tabler-outline icon-tabler-copy"
                                                                    >
                                                                        <path
                                                                            stroke="none"
                                                                            d="M0 0h24v24H0z"
                                                                            fill="none"
                                                                        ></path>
                                                                        <path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z"></path>
                                                                        <path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1"></path>
                                                                    </svg>
                                                                </Button>
                                                            </CopyToClipboard>
                                                        </h4>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="bank">
                                            {fineryResponse &&
                                                <div className="transaction-tab-frame">
                                                    <div className="transaction-tab-info-card">
                                                        <p>Id</p>
                                                        <h4>

                                                            {fineryResponse?.data?.id}

                                                        </h4>
                                                    </div>
                                                    <div className="transaction-tab-info-card">
                                                        <p>Client Order Id</p>
                                                        <h4>

                                                            {fineryResponse.data?.clientOrderId}

                                                        </h4>
                                                    </div>
                                                    <div className="transaction-tab-info-card">
                                                        <p>Size</p>
                                                        <h4>

                                                            {fineryResponse.data?.deals[0].size}

                                                        </h4>
                                                    </div>
                                                    <div className="transaction-tab-info-card">
                                                        <p>Price</p>
                                                        <h4>

                                                            {fineryResponse.data?.deals[0].price}

                                                        </h4>
                                                    </div>
                                                    <div className="transaction-tab-info-card">
                                                        <p>Volume</p>
                                                        <h4>

                                                            {fineryResponse.data.deals[0].volume}

                                                        </h4>
                                                    </div>
                                                    <div className="transaction-tab-info-card">
                                                        <p>Counter Party Id</p>
                                                        <h4>

                                                            {fineryResponse.data.deals[0].counterpartyId}

                                                        </h4>
                                                    </div>
                                                </div>}
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                            </div>
                        </div>
                    ) : (
                        <NoDataFound />
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default TradingTransactionViewModal;
