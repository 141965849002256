import React, { useState, useEffect } from "react";
import { Image, Button, Form, Row, Col } from "react-bootstrap";
import "../NewLanding/HomeBanner.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getErrorNotificationMessage } from "../helper/ToastNotification";
import { useTranslation } from "react-multi-lang";
import {
  getCurrenciesListStart,
  resetTokenExchangeData,
  setTokenExchangeData,
  setTokenSellData,
} from "../../store/actions/BuyAction";
import Select from "react-select";
import Skeleton from "react-loading-skeleton";
import {
  createKycApplicantStart,
  profileStart,
} from "../../store/actions/UserAction";
import KYCUpdateModal from "../KYC/KYCUpdateModal";
import CustomLazyLoad from "../helper/CustomLazyLoad";
import configuration from "react-global-configuration";
import { fetchWalletDetailsStart } from "../../store/actions/WalletAction";
import NoDataFound from "../helper/NoDataFound";
import { networkOption } from "../helper/NetworkOption";

const ExchangeCrptoForm = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const t = useTranslation("exchange_crypto_form");
  const currencies = useSelector((state) => state.buy.currencies);
  const tokenExchangeData = useSelector((state) => state.buy.tokenExchangeData);
  const [cryptoCurrencyList, setCryptoCurrencyList] = useState([]);
  const updateTokenExchangeData = (data) =>
    dispatch(setTokenExchangeData(data));
  const [skipRender, setSkipRender] = useState(true);
  const [errorMessage, setErrorMessage] = useState(false);
  const [networkTypeErrorMessage, setNetworkTypeErrorMessage] = useState(false);
  const [toNetworkTypeErrorMessage, setToNetworkTypeErrorMessage] =
    useState(false);
  const kycApplicant = useSelector((state) => state.user.kycApplicant);
  const kycStatusUpdate = useSelector((state) => state.user.updateKYC);

  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [toCryptoOptions, setToCryptoOptions] = useState([]);
  const [selectedCrypto, setSelectedCrypto] = useState(null);
  const [selectedForex, setSelectedForex] = useState(null);
  const [kyc, setKyc] = useState(false);
  const wallet = useSelector((state) => state.wallet.walletData);
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("token") || sessionStorage.getItem("token")
      ? true
      : false
  );
  const profile = useSelector((state) => state.user.profile);
  const [toCurrencyValue, setToCurrencyValue] = useState(0);
  const [commission, setCommission] = useState(0);

  const closeKycModal = () => {
    setKyc(false);
  };

  useEffect(() => {
    dispatch(getCurrenciesListStart({ user_id: profile.data?.user_id ? profile.data?.user_id : "" }));
  }, []);

  const onTokenChange = (value) => {
    if (Number(value) == value) {
      updateTokenExchangeData({
        ...tokenExchangeData,
        from_amount: value,
        // from_network_type: "",
        // to_network_type: "",
      });
    }
  };

  const onComplete = () => {
    if (
      (!sessionStorage.getItem("userId") || !sessionStorage.getItem("token")) &&
      (!localStorage.getItem("userId") || !localStorage.getItem("token"))
    ) {
      navigate("/login");
    } else if (
      Object.keys(profile.data).length > 0 &&
      ((profile.data.user_type == 1 && profile.data.kyc_verified == 0) || (profile.data.user_type == 2 && profile.data.kyb_status != 3))
    ) {
      profile.data.user_type == 1 ? dispatch(createKycApplicantStart()) : navigate("/kyb-verification");
    } else {
      tokenExchangeData.from_amount > 0
        ?  updateTokenExchangeData({
            ...tokenExchangeData,
            to_currency_conversion_value: toCurrencyValue,
            step: 2,
           })
        : getErrorNotificationMessage("Please enter valid amount");
    }
  };

  const invalidAmount =
    tokenExchangeData.from_amount < tokenExchangeData.minAmount ||
    tokenExchangeData.from_amount == "" ||
    tokenExchangeData.from_amount <= 0;

  useEffect(() => {
    if (
      !skipRender &&
      !currencies.loading &&
      Object.keys(currencies.data).length > 0
    ) {
      const categories = currencies.data.crypto_currencies.map((item) => ({
        label: item.currency_code,
        value: item.id,
      }));
      setCategoriesOptions(categories);
      const selected_crypto = tokenExchangeData.token_type
        ? currencies.data.crypto_currencies.find(
          (category) => category.currency_code == tokenExchangeData.token_type
        )
        : currencies.data.crypto_currencies[0];

      setSelectedCrypto(
        tokenExchangeData.from_currency
          ? categories.find(
            (category) => category.label == tokenExchangeData.from_currency
          )
          : categories[0]
      );


      const toCurrency = ["USDT", "USDC"].includes(selected_crypto.currency_code) ? currencies.data.crypto_currencies
        .map((currency) => ({
          label: currency.currency_code,
          value: currency.id,
        })) : currencies.data.crypto_currencies
          .filter((currency) => (currency.id != selected_crypto?.id))
          .map((currency) => ({
            label: currency.currency_code,
            value: currency.id,
          }));

      const selected_currency = currencies.data.crypto_currencies.filter((currency) => currency.id != selected_crypto?.id)[0];

      setToCryptoOptions(toCurrency);
      setSelectedForex(tokenExchangeData.to_currency ? toCurrency.find((forex) => forex.label == tokenExchangeData.to_currency) : toCurrency[0]);
      tokenExchangeData.from_currency == "" &&
        updateTokenExchangeData({
          ...tokenExchangeData,
          minAmount:
            parseInt(
              currencies.data?.user_commissions?.min_exchange_amount
            ) <= 0
              ? 0.00000001
              : parseInt(
                currencies.data?.user_commissions?.min_exchange_amount
              ).toFixed(8),
          maxAmount:
            parseInt(
              currencies.data?.user_commissions?.max_exchange_amount
            ) <= 0
              ? 100000
              : parseInt(
                currencies.data?.user_commissions?.max_exchange_amount
              ).toFixed(8),
          from_currency: selected_crypto?.currency_code,
          to_currency: selected_currency?.currency_code,
          from_exchange_rate: selected_crypto?.exchange_rate,
          from_source_currency: selected_crypto?.source_currency,
          to_exchange_rate: selected_currency?.exchange_rate,
          to_source_currency: selected_currency?.source_currency,
          commission: currencies.data.user_commissions.exchange_commission,
        });

      const cryptoExchangeUrl =
        configuration.get("configData.socket_url") != ""
          ? configuration.get("configData.socket_url")
          : "wss://cms-proexchange.rare-able.com:3091";

      const socket = new WebSocket(cryptoExchangeUrl);

      socket.onopen = () => {
        const subscribeMessage = JSON.stringify({
          action: "subscribe",
          asset: currencies.data?.crypto_currencies?.map(
            (currency) => currency.currency_code
          ),
        });
        socket.send(subscribeMessage);
      };

      socket.onmessage = (event) => {
        try {
          const parsedData = JSON.parse(event.data);

          parsedData.map(([asset, value]) => ({
            asset,
            value,
          }));
          setCryptoCurrencyList(
            parsedData.map(([asset, value]) => ({
              asset,
              value,
            }))
          );
        } catch (error) { }
      };
      return () => {
        if (socket) {
          socket.close();
        }
      };
    }
    setSkipRender(false);
  }, [currencies]);

  useEffect(() => {
    if (selectedForex && selectedForex.label) {
      !["USDT", "USDC"].includes(selectedForex.label) && setCategoriesOptions(categoriesOptions.filter((items) => items.label != selectedForex.label));
      updateTokenExchangeData({
        ...tokenExchangeData,
        to_currency: selectedForex.label,
      });
    }
  }, [selectedForex])

  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    input: (provided, state) => ({
      ...provided,
      color: "#010101",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 200,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "8px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "100%" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid transparent!important",
      borderRadius: "10px!important",
      boxShadow: "none!important",
      height: "42px",
      minWidth: "100px",
      cursor: "pointer",
      fontSize: "1em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#111",
      fontSize: "1.1em",
      fontWeight: "600",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#010101",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#298bff!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#298bff!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#010101",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused || state.isSelected ? "#F9C201" : "#fff",
      color: state.isFocused || state.isSelected ? "#000" : "#000",
      ":hover": {
        backgroundColor: "#F9C201",
        color: "#000",
      },
    }),
  };

  const onCryptoChange = (selectedOption) => {
    const crypto = currencies.data.crypto_currencies.find((crypto) => crypto.id == selectedOption.value);

    if (["USDT", "USDC"].includes(selectedOption.label)) {
      setToCryptoOptions(currencies.data.crypto_currencies.map((item) => ({ label: item.currency_code, value: item.id })));
    } else {
      setToCryptoOptions(currencies.data.crypto_currencies.map((item) => ({ label: item.currency_code, value: item.id })).filter((item) => item.value != selectedOption.value));
    }

    updateTokenExchangeData({
      ...tokenExchangeData,
      minAmount:
        parseInt(
          currencies.data?.user_commissions?.min_exchange_amount
        ) <= 0
          ? 0.0000001
          : parseInt(
            currencies.data?.crypto_currencies.filter(
              (data) => selectedOption?.label == data.currency_code
            )[0]?.min_exchange_amount
          ).toFixed(8),
      maxAmount:
        parseInt(
          currencies.data?.user_commissions?.max_exchange_amount
        ) <= 0
          ? 100000
          : parseInt(
            currencies.data?.crypto_currencies.filter(
              (data) => selectedOption?.label == data.currency_code
            )[0]?.max_exchange_amount
          ).toFixed(8),
      from_currency: selectedOption.label,
      from_exchange_rate: crypto.exchange_rate,
      from_source_currency: crypto.source_currency,
    });
    setSelectedCrypto(selectedOption);
  };

  const onCurrencyChange = (selectedOption) => {
    const forex = currencies.data.currencies.find((forex) => forex.id == selectedOption.value);

    if (["USDT", "USDC"].includes(selectedOption.label)) {
      setCategoriesOptions(currencies.data.crypto_currencies.map((item) => ({ label: item.currency_code, value: item.id })));
    } else {
      setCategoriesOptions(currencies.data.crypto_currencies.map((item) => ({ label: item.currency_code, value: item.id })).filter((item) => item.value != selectedOption.value));
    }

    updateTokenExchangeData({
      ...tokenExchangeData,
      to_exchange_rate: forex.exchange_rate,
      to_source_currency: forex.source_currency,
      to_currency: selectedOption.label,
    });
    setSelectedForex(selectedOption);
  };

  useEffect(() => {
    if (
      !skipRender &&
      !kycApplicant.loading &&
      Object.keys(kycApplicant.data).length > 0
    ) {
      kycApplicant.data?.link && kycApplicant.data?.link !== ""
        ? window.open(kycApplicant.data?.link, "_blank")
        : dispatch(profileStart());
    }
    setSkipRender(false);
  }, [kycApplicant]);

  useEffect(() => {
    if (
      !skipRender &&
      !kycStatusUpdate.loading &&
      Object.keys(kycStatusUpdate.data).length > 0
    ) {
      closeKycModal();
      window.location.reload();
    }
    setSkipRender(false);
  }, [kycStatusUpdate]);

  const customStylesone = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    input: (provided, state) => ({
      ...provided,
      color: "#010101",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 200,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "8px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "100%" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "#fff!important",
      border: "1px solid transparent!important",
      borderRadius: "10px!important",
      boxShadow: "none!important",
      height: "42px",
      // minWidth: "200px",
      cursor: "pointer",
      fontSize: "0.8em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#111",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#010101",
      display: "flex",
      alignItems: "center",
      // justifyContent: "flex-end",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#298bff!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#298bff!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#010101",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused || state.isSelected ? "#F9C201" : "#fff",
      color: state.isFocused || state.isSelected ? "#000" : "#000",
      ":hover": {
        backgroundColor: "#F9C201",
        color: "#000",
      },
    }),
  };

  useEffect(() => {
    if (cryptoCurrencyList.length > 0) {

      let exchange_rate = ((1/(cryptoCurrencyList?.filter(
        (data) => tokenExchangeData.to_currency == data.asset
      ))[0]?.value)) / (1/(cryptoCurrencyList?.filter(
        (data) => tokenExchangeData.from_currency == data.asset
      ))[0]?.value);

      setToCurrencyValue(
        !isNaN(((tokenExchangeData.from_amount * exchange_rate) - (tokenExchangeData.commission * tokenExchangeData.from_amount * exchange_rate) / 100).toFixed(8))
          ? ((tokenExchangeData.from_amount * exchange_rate) - (tokenExchangeData.commission * tokenExchangeData.from_amount * exchange_rate) / 100).toFixed(8)
          : 0.0
      );
      setCommission(!isNaN(
        (tokenExchangeData.commission * tokenExchangeData.from_amount * exchange_rate) /
        100
      )
        ? (
          (tokenExchangeData.commission * tokenExchangeData.from_amount * exchange_rate) /
          100
        ).toFixed(8)
        : 0.000)
    }
  }, [cryptoCurrencyList, tokenExchangeData]);

  useEffect(() => {
    updateTokenExchangeData({
      ...tokenExchangeData,
      to_amount: toCurrencyValue
    })
  }, [toCurrencyValue])

  useEffect(()=> {
    if(!tokenExchangeData.from_network_type){
      updateTokenExchangeData({
        ...tokenExchangeData,
        from_network_type: networkOption[0].value,
      });
    }
    if(!tokenExchangeData.to_network_type){
      updateTokenExchangeData({
        ...tokenExchangeData,
        to_network_type: networkOption[0].value,
      });
    }
    // if(tokenExchangeData.from_currency == tokenExchangeData.to_currency){
    //   if(tokenExchangeData.from_network_type == tokenExchangeData.to_network_type){
    //     let fromNetwork = (tokenExchangeData.from_currency != tokenExchangeData.to_currency) ? networkOption : networkOption.filter(item => (item.value != tokenExchangeData.to_network_type))
    //     if(!fromNetwork.find(item => item.value == tokenExchangeData.from_network_type)){
    //       updateTokenExchangeData({
    //         ...tokenExchangeData,
    //         from_network_type: fromNetwork[0].value
    //       })
    //     }
    //     let toNetwork = (tokenExchangeData.from_currency != tokenExchangeData.to_currency) ? networkOption : networkOption.filter(item => (item.value != tokenExchangeData.from_network_type))
    //     if(!toNetwork.find(item => item.value == tokenExchangeData.to_network_type)){
    //       updateTokenExchangeData({
    //         ...tokenExchangeData,
    //         from_network_type: toNetwork[0].value
    //       })
    //     }
    //   }
    // }
  }, [tokenExchangeData]);

  return (
    <>
      {currencies.loading ? (
        <div className="efi-exchange-swap-full-frame">
          <div className="efi-exchange-input-wrapped">
            <div className="efi-exchange-fields-card">
              {[...Array(2)].map((i) => (
                <Skeleton count={1} borderRadius={5} height={160} />
              ))}
            </div>
            <div className="efi-exchange-fields-card">
              {[...Array(2)].map((i) => (
                <Skeleton
                  count={1}
                  className="mt-3"
                  borderRadius={5}
                  height={160}
                />
              ))}
            </div>
            <div className="efi-swap-icons">
              <Skeleton count={1} circle={true} width={70} height={70} />
            </div>
          </div>
          <Skeleton count={1} height={50} />
          <div className="efi-swap-action">
            <Skeleton count={1} height={50} borderRadius={10} />
          </div>
          <div className="d-flex justify-content-center align-item-center ">
            <Skeleton count={1} height={22} width={100} borderRadius={5} />
          </div>
        </div>
      ) : Object.keys(currencies.data).length > 0 &&
        currencies.data.forex_currencies.length > 0 &&
        currencies.data.crypto_currencies.length > 0 ? (
        <div className="efi-exchange-swap-full-frame">
          <div className="efi-exchange-input-wrapped">
            <div className="efi-exchange-fields-card">
              <div className="efi-exchange-fields-enter">
                <div className="efi-fields-label-enter">{t("send")}</div>
                <Form>
                  <Form.Group
                    className=""
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Control
                      type="text"
                      value={tokenExchangeData.from_amount}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        onTokenChange(
                          newValue <= 100000
                            ? newValue
                            : tokenExchangeData.from_amount
                        );
                      }}
                    />
                  </Form.Group>
                </Form>
              </div>
              {currencies.data.crypto_currencies.length > 0 && (
                <div className="efi-exchange-fields-type">
                  <div className="efi-fields-label">
                    <CustomLazyLoad
                      src={
                        tokenExchangeData.from_currency
                          ? currencies.data.crypto_currencies.find(
                            (item) =>
                              item.currency_code ==
                              tokenExchangeData.from_currency
                          ).picture
                          : currencies.data.crypto_currencies[0].picture
                      }
                      className="swap-icon crypto-icon"
                    />
                    <span>{tokenExchangeData.from_currency}</span>
                  </div>
                  <div className="sell-crypto-grid">
                  <Select
                    className="fillert-drop"
                    isSearchable={false}
                    options={categoriesOptions}
                    styles={customStyles}
                    value={selectedCrypto}
                    onChange={(selectedOption) =>
                      onCryptoChange(selectedOption)
                    }
                  />
                  {["USDT", "USDC"].includes(tokenExchangeData.from_currency) ? (
                    <Select
                    className="fillert-drop"
                    // options={(tokenExchangeData.from_currency != tokenExchangeData.to_currency) ? networkOption : networkOption.filter(item => (item.value != tokenExchangeData.to_network_type))}
                    options={networkOption}
                    onChange={(selectedOption) => {
                      setNetworkTypeErrorMessage(false);
                      updateTokenExchangeData({
                        ...tokenExchangeData,
                        from_network_type: selectedOption.value,
                      });
                    }}
                    onBlur={() => {
                      tokenExchangeData.from_network_type == "" &&
                        setNetworkTypeErrorMessage(true);
                    }}
                    value={networkOption.find(
                      (item) =>
                        item.value == tokenExchangeData.from_network_type
                    )}
                    styles={customStylesone}
                    placeholder="Select Network"
                  />
                    ) : null}
                
                </div>
                {networkTypeErrorMessage && ["USDT", "USDC"].includes(tokenExchangeData.from_currency) && (
                    <p className="error-msg text-danger select-date buy-form error-text">
                      *{t("required")}
                    </p>
                  )}
                </div>
              )}
            </div>
            <div className="efi-exchange-fields-card">
              <div className="efi-exchange-fields-enter">
                <div className="efi-fields-label-enter">{t("recieve")}</div>
                <div className="efi-receive-text">
                  {tokenExchangeData.from_amount > 0
                    ? toCurrencyValue
                    : "0.0000"}
                </div>
              </div>
              {currencies.data.currencies.length > 0 && (
                <div className="efi-exchange-fields-type">
                  <div className="efi-fields-label">
                    <CustomLazyLoad
                      src={
                        tokenExchangeData.to_currency
                          ? currencies.data.currencies.find(
                            (item) =>
                              item.currency_code ==
                              tokenExchangeData.to_currency
                          ).picture
                          : currencies.data.currencies[0].picture
                      }
                      className="swap-icon crypto-icon"
                    />
                    <span>{tokenExchangeData.to_currency}</span>
                  </div>
                  <div className="sell-crypto-grid">
                  <Select
                    className="fillert-drop"
                    isSearchable={false}
                    options={toCryptoOptions}
                    styles={customStyles}
                    value={selectedForex}
                    onChange={(selectedOption) =>
                      onCurrencyChange(selectedOption)
                    }
                  />
                  {["USDT", "USDC"].includes(tokenExchangeData.to_currency) ? (
                    <Select
                      className="fillert-drop"
                      // options={(tokenExchangeData.from_currency != tokenExchangeData.to_currency) ? networkOption : networkOption.filter(item => (item.value != tokenExchangeData.from_network_type))}
                      options={networkOption}
                      onChange={(selectedOption) => {
                        setToNetworkTypeErrorMessage(false);
                        updateTokenExchangeData({
                          ...tokenExchangeData,
                          to_network_type: selectedOption.value,
                        });
                      }}
                      onBlur={() => tokenExchangeData.to_network_type == "" && setToNetworkTypeErrorMessage(true)}
                      value={networkOption.find((item) => item.value == tokenExchangeData.to_network_type)}
                      styles={customStylesone}
                      placeholder="Select Network"
                    />
                    ) : null}
                      {/* <Select
                        className="fillert-drop"
                        isSearchable={false}
                        options={toCryptoOptions}
                        styles={customStyles}
                        value={selectedForex}
                        onChange={(selectedOption) =>
                          onCurrencyChange(selectedOption)
                        }
                      /> */}
                    </div>
                    {toNetworkTypeErrorMessage && ["USDT", "USDC"].includes(tokenExchangeData.to_currency) && (
                      <p className=" error-msg text-danger select-date buy-form error-text">
                        *{t("required")}
                      </p>
                    )}
                    </div>
                  )}
            </div>
            <div className="efi-swap-icons">
              <Image
                src={window.location.origin + "/img/icon/swap.svg"}
                className="swap-icon"
              />
            </div>
          </div>
          {invalidAmount && (
            <p className="error-msg text-danger select-date buy-form">
              {t("invalid", { value: tokenExchangeData.minAmount .toFixed(8)})}
            </p>
          )}
          { parseFloat(tokenExchangeData.from_amount) > tokenExchangeData.maxAmount && (
            <p className="error-msg text-danger select-date buy-form">
              {" "}
              {t("max_invalid", { value: tokenExchangeData.maxAmount })}
              {/* max amount validation data come from api in future */}
            </p>
          )}
          <div className="efi-swap-exchange-info efi-info-frame">
            <p>
              {" "}
              <span> {t("estimated_rate")} </span>{" "}
              <span>
                {" "}
                1 {""}
                {currencies.data.crypto_currencies.length > 0
                  ? tokenExchangeData.from_currency
                  : "N/A"}
                = {""}
                {!isNaN(
                  ((1/(cryptoCurrencyList?.filter(
                      (data) => tokenExchangeData.to_currency == data.asset
                    ))[0]?.value)) / (1/(cryptoCurrencyList?.filter(
                      (data) => tokenExchangeData.from_currency == data.asset
                    ))[0]?.value)
                  ) 
                  ? (
                    (((1/(cryptoCurrencyList?.filter(
                      (data) => tokenExchangeData.to_currency == data.asset
                    ))[0]?.value)) / (1/(cryptoCurrencyList?.filter(
                      (data) => tokenExchangeData.from_currency == data.asset
                    ))[0]?.value)) 
                  ).toFixed(8)
                  : "0.0000"}
                {""} {tokenExchangeData.to_currency}{" "}
              </span>
            </p>
          </div>
          <div className="commission-based-landing-card">
            <div className="commission-box">
              <div className="commission-card">
                <h5> Service Fee:</h5>
                <h5>{tokenExchangeData.from_amount * commission}{" "}{(tokenExchangeData.to_currency)}</h5>
              </div>
            </div>
          </div>
          <div className="crypto_address_card">
            <div className="crypto_address_field">
              <Form.Group className="mb-3">
                <Form.Label>{t("receiver_wallet_address.label")}</Form.Label>
                <Form.Control
                  value={tokenExchangeData.receiver_wallet_address}
                  type="text"
                  onChange={(e) => {
                    e.target.value != ""
                      ? setErrorMessage(false)
                      : setErrorMessage(true);
                    updateTokenExchangeData({
                      ...tokenExchangeData,
                      receiver_wallet_address: e.target.value,
                    });
                  }}
                  onBlur={() =>
                    tokenExchangeData.receiver_wallet_address == "" &&
                    setErrorMessage(true)
                  }
                  placeholder="Enter receiver wallet address"
                />
                {errorMessage && (
                  <p className="error-msg text-danger select-date buy-form mt-1">
                    {t("required")}
                  </p>
                )}
              </Form.Group>
            </div>
          </div>
          <div className="efi-swap-action">
            <Button
              className="action-btn primary w-100 item_flex_x_5 justify-content-center"
              onClick={onComplete}
              disabled={
                invalidAmount || (tokenExchangeData.to_amount < 0) ||
                kycApplicant.buttonDisable ||
                parseFloat(tokenExchangeData.from_amount) > tokenExchangeData.maxAmount ||
                tokenExchangeData.receiver_wallet_address == "" ||
                (["USDT", "USDC"].includes(tokenExchangeData.from_currency) &&
                  tokenExchangeData.from_network_type == "") ||
                (["USDT", "USDC"].includes(tokenExchangeData.to_currency) &&
                  tokenExchangeData.to_network_type == "")
              }
            >
              {t("exchange")}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="#171717"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M18.01 19.99A9.964 9.964 0 0112 22c-5.52 0-8.89-5.56-8.89-5.56m0 0h4.52m-4.52 0v5M22 12c0 1.82-.49 3.53-1.34 5M6.03 3.97A9.921 9.921 0 0112 2c6.67 0 10 5.56 10 5.56m0 0v-5m0 5h-4.44M2 12c0-1.82.48-3.53 1.33-5"
                ></path>
              </svg>
            </Button>
          </div>
          <div className="efi-swap-exchange-info">
            <p>{t("no_extra_fees")}</p>
          </div>
        </div>
      ) : (
        <NoDataFound />
      )}
    </>
  );
};

export default ExchangeCrptoForm;
