import React, { useEffect, useState } from "react";
import "../Buy/Buy.css";
import { ButtonLoader } from "../helper/Loader";

const ButtonStepper = ({ status }) => {
  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    return () => {
      setCompleted(false);
    };
  }, []);

  return (
    <>
      <div className="flow-stepper-wrap">
        <div className="flow-stepper-frame">
          <ul
            className={`list-unstyled w-100 ${
              ![6, 7, 3,0].includes(status)
                ? "completed"
                : status == 0
                ? ""
                : "failed"
            }`}
          >
            <li  className={` ${
                  status > 0 ? "completed" : status == 0 ? "" : "failed"
                }`}>
              <div
                className="flow-stepper-card "
              >
                <div className="flow-stepper-process">
                  {status > 0 ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      enableBackground="new 0 0 512 512"
                      viewBox="0 0 20 20"
                      fill="#e4e0e0"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                        data-original="#000000"
                      ></path>
                    </svg>
                  ) : (
                    <div class="flow-loader"></div>
                  )}
                </div>
                <span>
                Initiated
                </span>
              </div>
            </li>
            <li   className={` ${
                  status >= 2 && status != 3
                    ? "completed"
                    : status == 0
                    ? ""
                    : "failed"
                }`}>
              <div
                className="flow-stepper-card "
              >
                <div className="flow-stepper-process">
                  {status >= 2 && status != 3 ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      enableBackground="new 0 0 512 512"
                      viewBox="0 0 20 20"
                      fill="#fff"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                        data-original="#000000"
                      ></path>
                    </svg>
                  ) :  (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      enableBackground="new 0 0 512 512"
                      viewBox="0 0 329.269 329"
                    >
                      <path
                        fill="#fff"
                        d="M194.8 164.77L323.013 36.555c8.343-8.34 8.343-21.825 0-30.164-8.34-8.34-21.825-8.34-30.164 0L164.633 134.605 36.422 6.391c-8.344-8.34-21.824-8.34-30.164 0-8.344 8.34-8.344 21.824 0 30.164l128.21 128.215L6.259 292.984c-8.344 8.34-8.344 21.825 0 30.164a21.266 21.266 0 0015.082 6.25c5.46 0 10.922-2.09 15.082-6.25l128.21-128.214 128.216 128.214a21.273 21.273 0 0015.082 6.25c5.46 0 10.922-2.09 15.082-6.25 8.343-8.34 8.343-21.824 0-30.164zm0 0"
                        data-original="#000000"
                      ></path>
                    </svg>
                  ) }
                </div>
                <span>
                Transaction Hash<br />Valid
                </span>
              </div>
            </li>
            <li   className={` ${
                  status >= 4
                    ? "completed"
                    : status == 0 || status < 4
                    ? ""
                    : "failed"
                }`}>
              <div
                className="flow-stepper-card "
              >
                <div className="flow-stepper-process">
                  {status >= 4 ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      enableBackground="new 0 0 512 512"
                      viewBox="0 0 20 20"
                      fill="#fff"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                        data-original="#000000"
                      ></path>
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      enableBackground="new 0 0 512 512"
                      viewBox="0 0 20 20"
                      fill="#e4e0e0"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                        data-original="#000000"
                      ></path>
                    </svg>
                  )}
                </div>
                <span>
                   Transfer Initiated
                </span>
              </div>
            </li>
            <li  className={`${
                  status >= 5 && status != 6
                    ? "completed"
                    : status == 0 || status < 5
                    ? ""
                    : "failed"
                }`}>
              <div
                className="flow-stepper-card "
              >
                <div className="flow-stepper-process">
                  {status >= 5 && status != 6 ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      enableBackground="new 0 0 512 512"
                      viewBox="0 0 20 20"
                      fill="#fff"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                        data-original="#000000"
                      ></path>
                    </svg>
                  ) :  (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      enableBackground="new 0 0 512 512"
                      viewBox="0 0 329.269 329"
                    >
                      <path
                        fill="#fff"
                        d="M194.8 164.77L323.013 36.555c8.343-8.34 8.343-21.825 0-30.164-8.34-8.34-21.825-8.34-30.164 0L164.633 134.605 36.422 6.391c-8.344-8.34-21.824-8.34-30.164 0-8.344 8.34-8.344 21.824 0 30.164l128.21 128.215L6.259 292.984c-8.344 8.34-8.344 21.825 0 30.164a21.266 21.266 0 0015.082 6.25c5.46 0 10.922-2.09 15.082-6.25l128.21-128.214 128.216 128.214a21.273 21.273 0 0015.082 6.25c5.46 0 10.922-2.09 15.082-6.25 8.343-8.34 8.343-21.824 0-30.164zm0 0"
                        data-original="#000000"
                      ></path>
                    </svg>
                  )}
                </div>
                <span>
                Success
                </span>
              </div>
            </li>
            <li   className={` ${
                  status == 5 && status != 7
                    ? "completed"
                    : status == 0 || status <= 6
                    ? ""
                    : "failed"
                }`}>
              <div
                className="flow-stepper-card "
              >
                <div className="flow-stepper-process">
                  {status == 6 ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      enableBackground="new 0 0 512 512"
                      viewBox="0 0 20 20"
                      fill="#fff"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                        data-original="#000000"
                      ></path>
                    </svg>
                  ) : status == 7 ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      enableBackground="new 0 0 512 512"
                      viewBox="0 0 329.269 329"
                    >
                      <path
                        fill="#fff"
                        d="M194.8 164.77L323.013 36.555c8.343-8.34 8.343-21.825 0-30.164-8.34-8.34-21.825-8.34-30.164 0L164.633 134.605 36.422 6.391c-8.344-8.34-21.824-8.34-30.164 0-8.344 8.34-8.344 21.824 0 30.164l128.21 128.215L6.259 292.984c-8.344 8.34-8.344 21.825 0 30.164a21.266 21.266 0 0015.082 6.25c5.46 0 10.922-2.09 15.082-6.25l128.21-128.214 128.216 128.214a21.273 21.273 0 0015.082 6.25c5.46 0 10.922-2.09 15.082-6.25 8.343-8.34 8.343-21.824 0-30.164zm0 0"
                        data-original="#000000"
                      ></path>
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      enableBackground="new 0 0 512 512"
                      viewBox="0 0 20 20"
                      fill="#e4e0e0"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                        data-original="#000000"
                      ></path>
                    </svg>
                  )}
                </div>
                <span>
                Transaction Hash <br/>Failed
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default ButtonStepper;
