import React from 'react';
import { Image } from 'react-bootstrap';
import CustomLazyLoad from "../helper/CustomLazyLoad";
import { Link } from 'react-router-dom';

const ThankYou = () => {
  return (
    <div className="conventional-data-wrapped">
    <div className="conventional-data-frame">
    <div className="conventional-data-avater">
    <CustomLazyLoad
        className="conventional-data-img"
        src={window.location.origin + "/img/phase_1/icons/platfrom/thany-avater.png"}
      />
      </div>
    <div className="conventional-data-info">
    Thank you! Your response has been submitted.<br/> Our team will get back to you shortly.
    <Link className="mt-3 action-btn primary">Back to Home</Link>
      </div>
    </div>
  </div>
  )
}

export default ThankYou;
