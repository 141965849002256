import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

const UserAgreement = () => {
    return (
        <div className="static-pag-sec">
            <div className="static-page-header">
                <Container fluid>
                    <Row className="justify-content-center">
                        <Col md="9">
                            <div className="static-section-title">
                                <h1>User Agreement</h1>
                                <h4>Effective Date: October 8, 2024</h4>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container fluid>
                <div className="static-box">
                    <Row>
                        <Col md={12}>
                            <div className="static-card">
                            <h4>User Agreement for Herald Exchange</h4>
                                <h4>1. Introduction</h4>
                                <p>Welcome to Herald.Exchange! This User Agreement ("Agreement") outlines the terms and conditions under which you may access and use our services. By creating an account or using our platform, you agree to be bound by this Agreement. If you do not agree to these terms, please do not use our services.</p>

                                <h4>2. Eligibility</h4>
                                <p>To use our services, you must:</p>
                                <ul>
                                    <li>Be at least 18 years old or the age of majority in your jurisdiction.</li>
                                    <li>Have the legal capacity to enter into this Agreement.</li>
                                    <li>Comply with all applicable laws and regulations regarding cryptocurrency trading.</li>
                                </ul>

                                <h4>3. Account Registration</h4>
                                <p>To access our services, you must create an account by providing accurate and complete information. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</p>

                                <h4>4. User Responsibilities</h4>
                                <p>You agree to:</p>
                                <ul>
                                    <li>Use the platform for lawful purposes only.</li>
                                    <li>Not engage in any fraudulent or deceptive activities.</li>
                                    <li>Not attempt to gain unauthorized access to any part of our services.</li>
                                    <li>Comply with all applicable laws and regulations related to your use of our services.</li>
                                </ul>

                                <h4>5. Trading and Transactions</h4>
                                <p>By using our platform, you acknowledge and agree to the following:</p>
                                <ul>
                                    <li>All trades are conducted at your own risk.</li>
                                    <li>We do not guarantee the accuracy of any information or data provided on the platform.</li>
                                    <li>We reserve the right to impose limits on the number of transactions or the amounts of funds you can trade.</li>
                                </ul>

                                <h4>6. Fees and Charges</h4>
                                <p>You agree to pay all applicable fees associated with your use of our services as detailed on our website. We reserve the right to change our fee structure at any time, with notice provided through the platform.</p>

                                <h4>7. Data Privacy</h4>
                                <p>Your use of our services is subject to our Privacy Agreement, which outlines how we collect, use, and protect your personal information.</p>

                                <h4>8. Limitation of Liability</h4>
                                <p>To the fullest extent permitted by law, Herald.Exchange shall not be liable for any indirect, incidental, or consequential damages arising out of or in connection with your use of our services.</p>

                                <h4>9. Indemnification</h4>
                                <p>You agree to indemnify and hold Herald.Exchange and its affiliates harmless from any claims, losses, liabilities, damages, costs, or expenses (including legal fees) arising out of your use of our services or violation of this Agreement.</p>

                                <h4>10. Governing Law</h4>
                                <p>This Agreement shall be governed by and construed in accordance with applicable laws. Any disputes arising from this Agreement shall be resolved in the competent courts.</p>

                                <h4>11. Amendments</h4>
                                <p>We reserve the right to modify this Agreement at any time. Any changes will be effective immediately upon posting on our website. Your continued use of our services after any changes signifies your acceptance of the new terms.</p>

                                <h4>12. Contact Us</h4>
                                <p>If you have any questions or concerns about this Agreement, please contact us at: <a href="mailto:support@herald.exchange">support@herald.exchange</a>.</p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}

export default UserAgreement