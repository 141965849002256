import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const AboutUs = () => {
    return (
        <>
        <div className="static-pag-sec">
            <div className="static-page-header">
                <Container fluid>
                    <Row className="justify-content-center">
                        <Col md="9">
                            <div className="static-section-title">
                                <h1>About Us</h1>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container fluid>
                <div className="static-box">
                    <Row>
                        <Col md={12}>
                            <div className="static-card">
                                <h4>About Us</h4>
                                <p>At HERALDEX, we’re committed to simplifying cryptocurrency exchanges for everyone. Whether you’re a seasoned crypto trader or just stepping into the world of digital assets, our platform is designed to offer seamless and secure cryptocurrency transactions. From converting real money into crypto to exchanging one cryptocurrency for another, HERALDEX is your one-stop solution for all your digital currency needs.</p>

                                <h4>What We Offer</h4>
                                <ul>
                                    <li><strong>Instant Crypto Conversions:</strong> Easily convert your fiat money into crypto and vice versa with just a few clicks.</li>
                                    <li><strong>Currency Exchange:</strong> Exchange one cryptocurrency for another at competitive rates, ensuring you get the most value out of your trades.</li>
                                    <li><strong>User-Friendly Platform:</strong> Our interface is designed to be intuitive, making it easy for both beginners and advanced users to navigate.</li>
                                    <li><strong>Security You Can Trust:</strong> We prioritize the safety of your transactions and data, using advanced encryption and security measures to protect your assets.</li>
                                </ul>

                                <h4>Join Us on Our Journey</h4>
                                <p>At HERALDEX, we believe in building a future where cryptocurrency transactions are easy, reliable, and accessible to everyone.</p>
                                <div className="call-to-action-btn-sec">
                                <Link className="new-default-primary-btn" to="/contact-us">
                                    Contact Us
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 24 24"><path stroke="#171717" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5" d="M14.43 5.93L20.5 12l-6.07 6.07M11.01 12h9.32M3.5 12h3.47"></path></svg>
                                </Link>
                            </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
        </>
    )
}

export default AboutUs