import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "../Wallet/Wallet.css";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import {
  getCryptoCurrency,
  getCurrenciesListStart,
  getForexExchangeRateStart,
  tokenSwapStart,
} from "../../store/actions/BuyAction";
import {
  fetchAllTransactionStart,
  fetchSingleWalletViewStart,
  fetchWalletDetailsStart,
} from "../../store/actions/WalletAction";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";
import CommonCenterLoader from "../helper/CommonCenterLoader";
import { useParams } from "react-router-dom";
import configuration from "react-global-configuration";
import { getSwapWalletsListStart } from "../../store/actions/BuyAction";
import TransactionSuccess from "../helper/TransactionSuccess";
import ButtonStepper from "../Buy/ButtonStepper";

const SwapModalNew = (props) => {
  const t = useTranslation("swap_modal");
  const params = useParams();
  const dispatch = useDispatch();
  const tokenSwap = useSelector((state) => state.buy.tokenSwap);
  const getSwapWalletsList = useSelector(
    (state) => state.buy.getSwapWalletsList
  );
  const wallet = useSelector((state) => state.wallet.walletData);
  const [skipRender, setSkipRender] = useState(true);
  const [filteredData, setFilteredData] = useState({});
  const [walletData, setWalletData] = useState({});
  const [minAmount, setMinAmount] = useState(0.00000001);
  const [maxAmount, setMaxAmount] = useState(1);
  const [commission, setCommission] = useState(0);
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const currencies = useSelector((state) => state.buy.currencies);
  const [cryptoCurrencyList, setCryptoCurrencyList] = useState([]);
  const [transactionSuccess, setTransactionSuccess] = useState(false);
  const [rangeValue, setRangeValue] = useState(0);
  const forexExchangeRate = useSelector((state) => state.buy.forexExchangeRate);
  const [buttonStepper, setButtonStepper] = useState(false);
  const profile = useSelector((state) => state.user.profile);
  const cryptoCurrency = useSelector((state) => state.buy.cryptoCurrency);

  const validationSchema = Yup.object().shape({
    value: Yup.number()
      .required(t("amount.required"))
      .min(
        !isNaN(minAmount) ? minAmount?.toFixed(8) : 0,
        t("amount.invalid", {
          value: !isNaN(minAmount) ? minAmount?.toFixed(8) : 0,
        })
      )
      .max(maxAmount < parseFloat(walletData?.remaining_original) ? maxAmount :
        parseFloat(walletData?.remaining_original).toFixed(8),
        t("invalid_tokens", { value: maxAmount })
      ),
    to_wallet_id: Yup.string().required(t("to_wallet.required")),
  });

  const handleSubmit = (values) => {
    const userWallet = getSwapWalletsList.data.user_wallet;

    const userCurrencyType = userWallet?.currency_type;
    const filteredCurrencyType = filteredData.currency_type;

    let endpoint;

    switch (`${userCurrencyType}-${filteredCurrencyType}`) {
      case "crypto-forex":
        endpoint = "efi/swap_crypto_fiat";
        break;
      case "crypto-crypto":
        endpoint = "efi/swap_tokens";
        break;
      case "forex-crypto":
        endpoint = "efi/swap_fiat_crypto";
        break;
      case "forex-forex":
        endpoint = "efi/swap_fiats";
        break;
    }
    dispatch(
      tokenSwapStart({
        ...values,
        endpoint: endpoint,
        from_wallet_id: props.user_wallet_id,
        user_wallet_id: props.user_wallet_id,
      })
    );
  };

  useEffect(() => {
    dispatch(getCurrenciesListStart({ user_id: profile.data?.user_id ? profile.data?.user_id : "" }))
    dispatch(getSwapWalletsListStart({ user_wallet_id: props.user_wallet_id }));
  }, []);

  useEffect(() => {
    if (
      !skipRender &&
      !tokenSwap.loading &&
      Object.keys(tokenSwap.data).length > 0
    ) {
      setButtonStepper(true)
      setTimeout(() => {
        setTransactionSuccess(true);
      }, 3000);

    }
    setSkipRender(false);
  }, [tokenSwap]);

  const onHide = () => {
    setTransactionSuccess(false);
    props.onHide();
    dispatch(
      fetchSingleWalletViewStart({
        user_wallet_id: props.user_wallet_id,
      })
    );
    dispatch(
      fetchAllTransactionStart({
        user_wallet_id: props.user_wallet_id,
        skip: 0,
        take: 12,
      })
    );
  };

  useEffect(() => {
    if (
      !skipRender &&
      !getSwapWalletsList.loading &&
      Object.keys(getSwapWalletsList.data).length > 0
    ) {
      setWalletData(getSwapWalletsList.data.user_wallet);
      const categories = getSwapWalletsList.data.other_user_wallets.filter((item) => props.show == "crypto" ? item : props.show == "forex" && getSwapWalletsList.data.user_wallet.currency_code == "USD" ? ("USDT" == item.currency_code || props.show == item.currency_type) : props.show == item.currency_type).map(
        (item) => ({
          label: item.currency_code,
          value: item.id,
        })
      )
      setCategoriesOptions(categories);
    }
    setSkipRender(false);
  }, [getSwapWalletsList]);

  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "10px",
      overflow: "hidden",
      minWidth: "100px",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #E5EAF4!important",
      borderRadius: "10px!important",
      boxShadow: "none!important",
      height: "42px",
      cursor: "pointer",
      minWidth: "auto",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#878e96",
      fontSize: "0.9em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      color: "#DDE1E6!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#DDE1E6!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#50506B80",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused || state.isSelected ? "#F9C201" : "#fff",
      color: state.isFocused || state.isSelected ? "#000" : "#000",
      ":hover": {
        backgroundColor: "#F9C201",
        color: "#000",
      },
    }),
  };

  useEffect(() => {
    if (
      !skipRender &&
      !currencies.loading &&
      Object.keys(currencies.data).length > 0
    ) {
      setMinAmount(
        parseInt(
          currencies.data?.user_commissions?.[props.show == "crypto" ? "min_swap_crypto_amount" : "min_swap_amount"]) <= 0
          ? 0.00000001
          : parseInt(
            currencies.data?.user_commissions?.[props.show == "crypto" ? "min_swap_crypto_amount" : "min_swap_amount"]
          ).toFixed(8)

      );
      setMaxAmount(
        parseInt(
          currencies.data?.user_commissions?.[props.show == "crypto" ? "max_swap_crypto_amount" : "max_swap_amount"]) <= 0
          ? 100000
          : parseInt(
            currencies.data?.user_commissions?.[props.show == "crypto" ? "max_swap_crypto_amount" : "max_swap_amount"]
          ).toFixed(8)
      )
      // const cryptoExchangeUrl = configuration.get("configData.socket_url") != ""
      //   ? configuration.get("configData.socket_url")
      //   : "wss://cms-proexchange.rare-able.com:3091";

      // const socket = new WebSocket(cryptoExchangeUrl);

      // socket.onopen = () => {
      //   const subscribeMessage = JSON.stringify({
      //     action: "subscribe",
      //     asset: currencies.data?.crypto_currencies?.map(
      //       (currency) => currency.currency_code
      //     ),
      //   });
      //   socket.send(subscribeMessage);
      // };

      // socket.onmessage = (event) => {
      //   try {
      //     const parsedData = JSON.parse(event.data);

      //     parsedData.map(([asset, value]) => ({
      //       asset,
      //       value,
      //     }));
      //     setCryptoCurrencyList(
      //       parsedData.map(([asset, value]) => ({
      //         asset,
      //         value,
      //       }))
      //     );
      //     dispatch(
      //       getCryptoCurrency(
      //         parsedData.map(([asset, value]) => ({
      //           asset,
      //           value,
      //         }))
      //       )
      //     );
      //   } catch (error) { }
      // };
      // return () => {
      //   if (socket) {
      //     socket.close();
      //   }
      // };
    }
    setSkipRender(false);
  }, [currencies]);

  useEffect(() => {
    if (filteredData) {
      filteredData?.currency_type == "forex" && filteredData?.currency_code !== "USD" &&
        dispatch(getForexExchangeRateStart({ currency: filteredData.currency_code, user_id: profile.data?.user_id ? profile.data?.user_id : "" }))

      switch (`${props.show}-${filteredData?.currency_type}`) {

        case "crypto-crypto":
          setCommission(currencies.data.user_commissions.swap_crypto_commission);
          break;
        case "forex-forex":
          setCommission(currencies.data.user_commissions.swap_fiat_commission);
          break;
        default:
          setCommission(currencies.data.user_commissions.swap_commission)
          break

      }
    }
  }, [filteredData])

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="swap-modal-new swap_transaction_modal"
      >
        <Modal.Header closeButton className="border-0">
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!transactionSuccess ? (
            <>
              <div className="swap_modal_content">
                <div className="swap_modal_avater out_space_mx_1_5">
                  <Image
                    className="auth_2fa-sec"
                    src={
                      window.location.origin +
                      "/img/phase_1/wallet/swap_coin.svg"
                    }
                    alt="onboard"
                  />
                </div>
                <div className="auth_modal_titles out_space_mx_1_5">
                  <h4>{t("heading")}</h4>
                </div>
              </div>

              {getSwapWalletsList.loading ? (
                <CommonCenterLoader />
              ) : Object.keys(getSwapWalletsList.data).length > 0 &&
                Object.keys(getSwapWalletsList.data.user_wallet).length > 0 ? (
                <>
                  <Formik
                    initialValues={{
                      value: "",
                      to_wallet_id: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ setFieldValue, values }) => (
                      <FORM className=" swap-exchange mt-4">
                        <div className="swap-token-input">
                          <Form.Group
                            className="input-group-custome "
                            controlId="exampleForm.ControlInput1"
                          >
                            <Field
                              type="number"
                              name="value"
                              onChange={(e) => {
                                const newValue = e.target.value;
                                const remainingOriginal =
                                  walletData.remaining_original;

                                const percentage =
                                  (newValue / remainingOriginal) * 100;
                                setRangeValue(
                                  isNaN(percentage)
                                    ? 0
                                    : Math.min(percentage, 100).toFixed(2)
                                );

                                setFieldValue("value", newValue);
                              }}
                              placeholder={t("amount.placeholder")}
                              className="form-control"
                            />
                            <ErrorMessage
                              component={"div"}
                              name="value"
                              className="text-danger currency-text"
                            />
                          </Form.Group>
                          <div className="aed-currency">
                            <div className="aed-currency-svg">
                              <Image
                                className="swap-img"
                                src={
                                  walletData.currency_picture
                                    ? walletData.currency_picture
                                    : null
                                }
                              />
                              <p>{walletData.currency_code}</p>
                            </div>
                          </div>
                          <Form.Group
                            controlId="exampleForm.ControlInput1"
                            className="trade-percent-range mt-2 mb-2"
                          >
                            <div className="range-label">
                              <span>{rangeValue}%</span>
                              <span>100%</span>
                            </div>
                            <Form.Range
                              disabled={
                                !walletData.remaining_original ||
                                walletData.remaining_original <= 0
                              }
                              min="0"
                              max="100"
                              value={rangeValue}
                              onChange={(e) => {
                                const data =
                                  (parseFloat(e.target.value) / 100) *
                                  walletData.remaining_original;
                                setRangeValue(e.target.value);
                                setFieldValue("value", data.toFixed(8));
                              }}
                            />
                            <div className="trade-range-value">
                              {rangeValue} % / 100 %
                            </div>
                          </Form.Group>
                          <p className="balance">
                            {t("balance")}: <span>{walletData.remaining}</span>
                          </p>
                        </div>
                        <div className="swap-arrow">
                          <div className="up-down-arrow">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <g
                                fill="#000"
                                fillRule="evenodd"
                                clipRule="evenodd"
                              >
                                <path d="M12.595 4.507a.75.75 0 011.058-.072l5.84 5.09A.75.75 0 0119 10.842H5a.75.75 0 010-1.5h11.998l-4.33-3.776a.75.75 0 01-.073-1.058zM11.405 19.493a.75.75 0 01-1.058.072l-5.84-5.09A.75.75 0 015 13.158h14a.75.75 0 010 1.5H7.002l4.33 3.776a.75.75 0 01.073 1.058z"></path>
                              </g>
                            </svg>
                          </div>
                        </div>
                        <div className="swap-token-input mt-4">
                          <div className="recieve-amount">
                            {values.to_wallet_id ? (
                              <h3>
                                {Object.keys(filteredData).length &&
                                  Object.keys(getSwapWalletsList).length > 0
                                  ? getSwapWalletsList.data?.user_wallet
                                    .currency_type == "crypto"
                                    ? !isNaN(
                                      (values.value -
                                        (values.value *
                                          commission) /
                                        100) *
                                      (cryptoCurrency?.filter(
                                        (data) =>
                                          walletData.currency_code ==
                                          data.asset
                                      ))[0]?.value *
                                      (filteredData.currency_type == "crypto"
                                        ? 1 /
                                        (cryptoCurrency?.filter(
                                          (data) =>
                                            filteredData.currency_code ==
                                            data.asset
                                        ))[0]?.value
                                        : (filteredData.currency_code == "USD" ? filteredData.exchange_rate : forexExchangeRate.data?.fiat_exchange_rate?.exchange_rate))
                                    ) ? (
                                      (values.value -
                                        (values.value *
                                          commission) /
                                        100) *
                                      (cryptoCurrency?.filter(
                                        (data) =>
                                          walletData.currency_code ==
                                          data.asset
                                      ))[0]?.value *
                                      (filteredData.currency_type == "crypto"
                                        ? 1 /
                                        (cryptoCurrency?.filter(
                                          (data) =>
                                            filteredData.currency_code ==
                                            data.asset
                                        ))[0]?.value
                                        : (filteredData.currency_code == "USD" ? filteredData.exchange_rate : forexExchangeRate.data?.fiat_exchange_rate?.exchange_rate))
                                    ).toFixed(8) : 0.0000
                                    : !isNaN(
                                      (values.value -
                                        (values.value *
                                          commission) /
                                        100) *
                                      ((filteredData.currency_type == "crypto"
                                        ? 1 /
                                        (cryptoCurrency?.filter(
                                          (data) =>
                                            filteredData.currency_code ==
                                            data.asset
                                        ))[0]?.value
                                        : (filteredData.currency_code == "USD" ? filteredData.exchange_rate : forexExchangeRate.data?.fiat_exchange_rate?.exchange_rate)) /
                                        walletData.exchange_rate)
                                    ) ? (
                                      (values.value -
                                        (values.value *
                                          commission) /
                                        100) *
                                      ((filteredData.currency_type == "crypto"
                                        ? 1 /
                                        (cryptoCurrency?.filter(
                                          (data) =>
                                            filteredData.currency_code ==
                                            data.asset
                                        ))[0]?.value
                                        : (filteredData.currency_code == "USD" ? filteredData.exchange_rate : forexExchangeRate.data?.fiat_exchange_rate?.exchange_rate)) /
                                        walletData.exchange_rate)
                                    ).toFixed(8) : 0.0000
                                  : 0}
                              </h3>
                            ) : null}
                            <p>{t("receive")}</p>
                          </div>
                          <div className="aed-currency">
                            <Select
                              className="fillert-drop"
                              isDisabled={forexExchangeRate.buttonDisable}
                              name="to_wallet_id"
                              options={categoriesOptions}
                              styles={customStyles}
                              onChange={(selectedOption) => {
                                if (selectedOption) {
                                  setFieldValue(
                                    "to_wallet_id",
                                    selectedOption.value
                                  );
                                  setFilteredData(
                                    getSwapWalletsList.data.other_user_wallets.find(
                                      (data) => selectedOption.value == data.id
                                    )
                                  );
                                } else {
                                  setFilteredData({});
                                  setFieldValue("to_wallet_id", "");
                                }
                              }}
                              isClearable
                              placeholder={
                                <div className="placeholder-flex">
                                  {t("to_wallet.placeholder")}
                                </div>
                              }
                            />
                            <ErrorMessage
                              component={"div"}
                              name="to_wallet_id"
                              className="text-danger currency-text"
                            />
                            {/* {filteredData.swap_commission > 0 ? (
                          <p className="balance">
                            {t("service_fees")}:{" "}
                            <span>
                              {Object.keys(filteredData).length > 0 &&
                              values.value &&
                              values.to_wallet_id
                                ? (values.value *
                                    (1 / walletData.exchange_rate) *
                                    filteredData.exchange_rate *
                                    filteredData.swap_commission) /
                                  100
                                : 0}
                            </span>
                          </p>
                        ) : null} */}
                          </div>
                        </div>
                        {filteredData?.currency_code && (
                          <p className="exchange-rate exchange-rate-card">
                            Exchange rate:
                            <span className="text-black">
                              1 {walletData.currency_code} =
                              {forexExchangeRate.buttonDisable ? (<div className="preloading-sec"></div>) :
                                Object.keys(filteredData).length &&
                                  Object.keys(getSwapWalletsList).length > 0
                                  ? getSwapWalletsList.data?.user_wallet
                                    .currency_type == "crypto"
                                    ? !isNaN(
                                      (1 -
                                        (1 *
                                          currencies.data?.crypto_currencies?.find(
                                            (item) =>
                                              item.currency_code ==
                                              walletData.currency_code
                                          )?.swap_commission) /
                                        100) *
                                      (cryptoCurrency?.filter(
                                        (data) =>
                                          walletData.currency_code == data.asset
                                      ))[0]?.value *
                                      (filteredData.currency_type == "crypto"
                                        ? 1 /
                                        (cryptoCurrency?.filter(
                                          (data) =>
                                            filteredData.currency_code ==
                                            data.asset
                                        ))[0]?.value
                                        : (filteredData.currency_code == "USD" ? filteredData.exchange_rate : forexExchangeRate.data?.fiat_exchange_rate?.exchange_rate))
                                    ) ? (
                                      (1 -
                                        (1 *
                                          currencies.data?.crypto_currencies?.find(
                                            (item) =>
                                              item.currency_code ==
                                              walletData.currency_code
                                          )?.swap_commission) /
                                        100) *
                                      (cryptoCurrency?.filter(
                                        (data) =>
                                          walletData.currency_code == data.asset
                                      ))[0]?.value *
                                      (filteredData.currency_type == "crypto"
                                        ? 1 /
                                        (cryptoCurrency?.filter(
                                          (data) =>
                                            filteredData.currency_code ==
                                            data.asset
                                        ))[0]?.value
                                        : (filteredData.currency_code == "USD" ? filteredData.exchange_rate : forexExchangeRate.data?.fiat_exchange_rate?.exchange_rate))
                                    ).toFixed(8) : 0.0000
                                    : !isNaN(
                                      (1 -
                                        (1 *
                                          currencies.data?.forex_currencies?.find(
                                            (item) =>
                                              item.currency_code ==
                                              walletData.currency_code
                                          )?.swap_commission) /
                                        100) *
                                      ((filteredData.currency_type == "crypto"
                                        ? 1 /
                                        (cryptoCurrency?.filter(
                                          (data) =>
                                            filteredData.currency_code ==
                                            data.asset
                                        ))[0]?.value
                                        : (filteredData.currency_code == "USD" ? filteredData.exchange_rate : forexExchangeRate.data?.fiat_exchange_rate?.exchange_rate)) /
                                        walletData.exchange_rate)
                                    ) ? (
                                      (1 -
                                        (1 *
                                          currencies.data?.forex_currencies?.find(
                                            (item) =>
                                              item.currency_code ==
                                              walletData.currency_code
                                          )?.swap_commission) /
                                        100) *
                                      ((filteredData.currency_type == "crypto"
                                        ? 1 /
                                        (cryptoCurrency?.filter(
                                          (data) =>
                                            filteredData.currency_code ==
                                            data.asset
                                        ))[0]?.value
                                        : (filteredData.currency_code == "USD" ? filteredData.exchange_rate : forexExchangeRate.data?.fiat_exchange_rate?.exchange_rate)) /
                                        walletData.exchange_rate)
                                    ).toFixed(8) : 0.0000
                                  : 0}{" "}
                              {!forexExchangeRate.buttonDisable && filteredData?.currency_code}
                            </span>
                          </p>
                        )}
                        {/* commission calculation */}
                        <div className="commission-based-card">
                          <div className="commission-box">
                            {/* <div className="commission-card">
                              <h5>Commission:</h5>
                              <h5>0.015</h5>
                            </div> */}
                            <div className="commission-card">
                              <h5>Service Fee:</h5>
                              <h5>
                                {Object.keys(filteredData).length &&
                                  Object.keys(getSwapWalletsList).length > 0
                                  ? getSwapWalletsList.data?.user_wallet
                                    .currency_type == "crypto"
                                    ? !isNaN(
                                      (
                                        (values.value *
                                          commission) /
                                        100) *
                                      (cryptoCurrency?.filter(
                                        (data) =>
                                          walletData.currency_code ==
                                          data.asset
                                      ))[0]?.value *
                                      (filteredData.currency_type == "crypto"
                                        ? 1 /
                                        (cryptoCurrency?.filter(
                                          (data) =>
                                            filteredData.currency_code ==
                                            data.asset
                                        ))[0]?.value
                                        : (filteredData.currency_code == "USD" ? filteredData.exchange_rate : forexExchangeRate.data?.fiat_exchange_rate?.exchange_rate))
                                    ) ? (
                                      (
                                        (values.value *
                                          commission) /
                                        100) *
                                      (cryptoCurrency?.filter(
                                        (data) =>
                                          walletData.currency_code ==
                                          data.asset
                                      ))[0]?.value *
                                      (filteredData.currency_type == "crypto"
                                        ? 1 /
                                        (cryptoCurrency?.filter(
                                          (data) =>
                                            filteredData.currency_code ==
                                            data.asset
                                        ))[0]?.value
                                        : (filteredData.currency_code == "USD" ? filteredData.exchange_rate : forexExchangeRate.data?.fiat_exchange_rate?.exchange_rate))
                                    ).toFixed(8) : 0.0000
                                    : !isNaN(
                                      (
                                        (values.value *
                                          commission) /
                                        100) *
                                      ((filteredData.currency_type == "crypto"
                                        ? 1 /
                                        (cryptoCurrency?.filter(
                                          (data) =>
                                            filteredData.currency_code ==
                                            data.asset
                                        ))[0]?.value
                                        : (filteredData.currency_code == "USD" ? filteredData.exchange_rate : forexExchangeRate.data?.fiat_exchange_rate?.exchange_rate)) /
                                        walletData.exchange_rate)
                                    ) ? (
                                      (
                                        (values.value *
                                          commission) /
                                        100) *
                                      ((filteredData.currency_type == "crypto"
                                        ? 1 /
                                        (cryptoCurrency?.filter(
                                          (data) =>
                                            filteredData.currency_code ==
                                            data.asset
                                        ))[0]?.value
                                        : (filteredData.currency_code == "USD" ? filteredData.exchange_rate : forexExchangeRate.data?.fiat_exchange_rate?.exchange_rate)) /
                                        walletData.exchange_rate)
                                    ).toFixed(8) : 0.0000
                                  : 0} {filteredData?.currency_code}
                              </h5>
                            </div>
                          </div>
                        </div>
                        {tokenSwap.buttonDisable ||
                          (Object.keys(tokenSwap.data).length > 0 &&
                            buttonStepper) ? (
                          <div className="mt-3 dark-stepper">
                            {" "}
                            <ButtonStepper
                              props={tokenSwap}
                            />{" "}
                          </div>
                        ) : (
                          <div className="new-action-frame layout-element">

                            <Button
                              className="action-btn overlay w-100"
                              onClick={props.onHide}
                              disabled={tokenSwap.buttonDisable}
                            >
                              {t("cancel")}
                            </Button>
                            <Button
                              type="submit"
                              className="action-btn primary w-100"
                              disabled={
                                values.value <= 0 || tokenSwap.buttonDisable || forexExchangeRate.buttonDisable
                              }
                            >
                              {tokenSwap.buttonDisable ? t("loading") : t("swap")}
                            </Button>
                          </div>
                        )}
                      </FORM>
                    )}
                  </Formik>
                </>
              ) : null}
            </>
          ) : (
            <TransactionSuccess
              message="Swap Completed Successfully"
              onHide={onHide}
            />
          )}
        </Modal.Body>
      </Modal>
      {/* <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className='swap-modal-new'
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            
          </Modal.Title>
        </Modal.Header>
      
      </Modal> */}
    </>
  );
};

export default SwapModalNew;
