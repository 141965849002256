import React, { useEffect, useState } from "react";
import { Modal, Button, Table, Tab, Nav } from "react-bootstrap";
import { singleWithdrawTransactionStart } from "../../../store/actions/BuyAction";
import Skeleton from "react-loading-skeleton";
import CopyToClipboard from "react-copy-to-clipboard";
import "./transaction.css";
import { useTranslation } from "react-multi-lang";
import { useSelector, useDispatch } from "react-redux";
import { getSuccessNotificationMessage } from "../../helper/ToastNotification";
import NoDataFound from "../../helper/NoDataFound";

const WithdrawTransactionViewModal = (props) => {
  const { transactionId } = props;
  const dispatch = useDispatch();
  const t = useTranslation("transactions");
  const singleWithdrawTransaction = useSelector(
    (state) => state.buy.singleWithdrawTransaction
  );

  useEffect(() => {
    dispatch(
      singleWithdrawTransactionStart({
        user_withdrawal_id: transactionId,
      })
    );
  }, []);

  const formatNumber = (number) => {
    if (number) {
      return number
        .replace(/\s+/g, "")
        .split("")
        .reduce((seed, next, index) => {
          if (index !== 0 && index % 4 === 0) {
            seed += " ";
          }
          return seed + next;
        }, "");
    }
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className=" view-modal-transaction"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {" "}
            Withdraw Transaction Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {singleWithdrawTransaction.loading ? (
            <div className="transaction-view-body">
              <Skeleton height={210} borderRadius={15} />
              <div className="transaction-basic-tab-wrap out_space_mt_1">
                <Skeleton height={210} borderRadius={15} />
              </div>
            </div>
          ) : Object.keys(singleWithdrawTransaction.data).length > 0 ? (
            <>
              <div className="transaction-view-body">
                <>
                  <div className="transaction-basic-info-wrap">
                    <div className="transaction-basic-info-head">
                      <div className="transaction-basic-info-head-titles">
                        <h3>
                          {
                            singleWithdrawTransaction.data.user_withdrawal
                              .user_amount_formatted
                          }
                        </h3>
                        <p>Withdrawal Amount</p>
                      </div>
                    </div>
                    <div className="transaction-space"></div>
                    <div className="transaction-basic-info-content">
                      <div className="transaction-basic-content-card">
                        <p>Amount Requested</p>
                        <h5>
                          {
                            singleWithdrawTransaction.data.user_withdrawal
                              .requested_amount_formatted
                          }{" "}
                        </h5>
                      </div>
                      <div className="transaction-basic-content-card">
                        <p>Status</p>
                        <div
                          className={`table_badge  ${
                            (singleWithdrawTransaction.data.user_withdrawal
                              .status == 3 || singleWithdrawTransaction.data.user_withdrawal
                              .status == 5)
                              ? "fail"
                              : singleWithdrawTransaction.data.user_withdrawal
                                  .status == 4 ||
                                singleWithdrawTransaction.data.user_withdrawal
                                  .status == 2
                              ? "pending"
                              : "success"
                          }`}
                        >
                          {" "}
                          {
                            singleWithdrawTransaction.data.user_withdrawal
                              .status_formatted
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="transaction-basic-tab-wrap out_space_mt_1">
                    <Tab.Container
                      id="transaction-basic-tab"
                      defaultActiveKey="transaction"
                    >
                      <Nav variant="pills">
                        <Nav.Item>
                          <Nav.Link
                            eventKey="transaction"
                            className="view_tab_btn"
                          >
                            Transaction Details
                          </Nav.Link>
                        </Nav.Item>
                        {singleWithdrawTransaction.data.user_withdrawal.account_number ? (
                          <Nav.Item>
                            <Nav.Link eventKey="bank" className="view_tab_btn">
                              Bank Details
                            </Nav.Link>
                          </Nav.Item>
                        ) : null}
                      </Nav>
                      <Tab.Content>
                        <Tab.Pane eventKey="transaction">
                          <div className="transaction-tab-frame">
                            {/* <div className="transaction-tab-info-card">
                                  <p>Exchange Value</p>
                                  <h4>1.00 AED = 0.24523161 USDT</h4>
                              </div> */}
                            {singleWithdrawTransaction.data.user_withdrawal
                              .wallet_address ? (
                              <div className="transaction-tab-info-card">
                                <p>Receiver Wallet Address </p>
                                <h4>
                                  {
                                    singleWithdrawTransaction.data
                                      .user_withdrawal.wallet_address
                                  }
                                </h4>
                              </div>
                            ) : null}
                            <div className="transaction-tab-info-card">
                              <p>Transaction Date</p>
                              <h4>
                                {
                                  singleWithdrawTransaction.data.user_withdrawal
                                    .created_at
                                }{" "}
                              </h4>
                            </div>

                            <div className="transaction-tab-info-card">
                              <p>
                                {singleWithdrawTransaction.data.user_withdrawal
                                  .withdraw_type == "crypto"
                                  ? "Transaction Hash"
                                  : "Payment ID"}
                              </p>
                              {singleWithdrawTransaction.data?.user_withdrawal
                                ?.payment_id ? (
                                <>
                                  <h4 className="copy-view-card">
                                    {" "}
                                    <span>
                                      {" "}
                                      {singleWithdrawTransaction.data
                                        ?.user_withdrawal?.payment_id !== ""
                                        ? singleWithdrawTransaction.data
                                            ?.user_withdrawal?.payment_id
                                        : singleWithdrawTransaction.data
                                            .user_withdrawal
                                            .stylopay_withdrawal_transaction_id}
                                    </span>
                                    <CopyToClipboard
                                      text={
                                        singleWithdrawTransaction.data
                                          ?.user_withdrawal?.payment_id !== ""
                                          ? singleWithdrawTransaction.data
                                              ?.user_withdrawal?.payment_id
                                          : singleWithdrawTransaction.data
                                              .user_withdrawal
                                              .stylopay_withdrawal_transaction_id
                                      }
                                      onCopy={() =>
                                        getSuccessNotificationMessage(
                                          singleWithdrawTransaction.data
                                            .user_withdrawal.withdraw_type ==
                                            "crypto"
                                            ? t(
                                                "transaction_hash_was_copied_to_clipboard"
                                              )
                                            : t(
                                                "payment_id_was_copied_to_clipboard"
                                              )
                                        )
                                      }
                                    >
                                      <Button className="action-btn copy-btn item_center">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="#298BFF"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          class="icon icon-tabler icons-tabler-outline icon-tabler-copy"
                                        >
                                          <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                            fill="none"
                                          ></path>
                                          <path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z"></path>
                                          <path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1"></path>
                                        </svg>
                                      </Button>
                                    </CopyToClipboard>
                                  </h4>
                                </>
                              ) : singleWithdrawTransaction.data.user_withdrawal
                                  .withdraw_type == "crypto" ? (
                                "N/A"
                              ) : (
                                "N/A"
                              )}
                            </div>

                            <div className="transaction-tab-info-card">
                              <p>Payment Type</p>
                              <h4>
                                {
                                  singleWithdrawTransaction.data.user_withdrawal
                                    .withdraw_type.toUpperCase()
                                }
                              </h4>
                            </div>
                            {singleWithdrawTransaction.data
                                      .user_withdrawal.explorer_url ? <div className="transaction-tab-info-card">
                              <p>View on Explorer</p>
                              <Button
                                className=" action-btn link-btn"
                                onClick={() => {
                                  window.open(
                                    singleWithdrawTransaction.data
                                      .user_withdrawal.explorer_url,
                                    "_blank"
                                  );
                                }}
                              >
                                View on Explorer
                              </Button>
                            </div> : null}
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="bank">
                          <div className="bank-tab-info-wrap">
                            <div className="bank-tab-card-info out_space_mx_1">
                              <div className="bank-tab-card-wrap">
                                <div className="bank-tab-card-wrap-icons">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    enableBackground="new 0 0 512 512"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      d="M24 23a1 1 0 01-1 1H1a1 1 0 010-2h22a1 1 0 011 1zM.291 8.552a2.443 2.443 0 01.153-2.566 4.716 4.716 0 011.668-1.5L9.613.582a5.174 5.174 0 014.774 0l7.5 3.907a4.716 4.716 0 011.668 1.5 2.443 2.443 0 01.153 2.566A2.713 2.713 0 0121.292 10H21v8h1a1 1 0 010 2H2a1 1 0 010-2h1v-8h-.292A2.713 2.713 0 01.291 8.552zM5 18h3v-8H5zm5-8v8h4v-8zm9 0h-3v8h3zM2.063 7.625A.717.717 0 002.708 8h18.584a.717.717 0 00.645-.375.452.452 0 00-.024-.5 2.7 2.7 0 00-.949-.864l-7.5-3.907a3.176 3.176 0 00-2.926 0l-7.5 3.907a2.712 2.712 0 00-.949.865.452.452 0 00-.026.499z"
                                      data-original="#000000"
                                    ></path>
                                  </svg>
                                </div>
                                <div className="bank-tab-card-wrap-info">
                                  <h4>
                                    {
                                      singleWithdrawTransaction.data
                                        .user_withdrawal?.account_holder_name
                                    }
                                  </h4>
                                  <p>
                                    {" "}
                                    {
                                      singleWithdrawTransaction.data
                                        .user_withdrawal?.bank_name
                                    }
                                  </p>
                                </div>
                              </div>
                              <h5>
                                {singleWithdrawTransaction.data.user_withdrawal?.account_number}
                              </h5>
                            </div>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </>
              </div>
            </>
          ) : (
            <NoDataFound />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default WithdrawTransactionViewModal;
