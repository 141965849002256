import React, { useEffect, useState } from "react";
import { Modal, Button, Table, Tab, Nav } from "react-bootstrap";
import { depositTokensViewStart } from "../../../store/actions/BuyAction";
import Skeleton from "react-loading-skeleton";
import CopyToClipboard from "react-copy-to-clipboard";
import "./transaction.css";
import { useTranslation } from "react-multi-lang";
import { useSelector, useDispatch } from "react-redux";
import { getSuccessNotificationMessage } from "../../helper/ToastNotification";
import CustomLazyLoad from "../../helper/CustomLazyLoad";
import NoDataFound from "../../helper/NoDataFound";

const DepositTransactionViewModal = (props) => {
  const { transactionId } = props;
  const dispatch = useDispatch();
  const t = useTranslation("transactions");
  const [step, setStep] = useState(1);
  const depositTokensView = useSelector((state) => state.buy.depositTokensView);

  useEffect(() => {
    dispatch(
      depositTokensViewStart({
        deposit_transaction_id: transactionId,
      })
    );
  }, []);

  const formatNumber = (number) => {
    if (number) {
      return number
        .replace(/\s+/g, "")
        .split("")
        .reduce((seed, next, index) => {
          if (index !== 0 && index % 4 === 0) {
            seed += " ";
          }
          return seed + next;
        }, "");
    }
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className=" view-modal-transaction"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {" "}
            Deposit Transaction Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {depositTokensView.loading ? (
            <div className="transaction-view-body">
              <Skeleton height={210} borderRadius={15} />
              <div className="transaction-basic-tab-wrap out_space_mt_1">
                <Skeleton height={210} borderRadius={15} />
              </div>
            </div>
          ) : Object.keys(depositTokensView.data).length > 0 ? (
            <>
              <div className="transaction-view-body">
                {step == 1 ? (
                  <>
                    <div className="transaction-basic-info-wrap">
                      <div className="transaction-basic-info-head">
                        <div className="transaction-basic-info-head-titles">
                          <h3>
                            {
                              depositTokensView.data.deposit_transaction
                                .to_amount_formatted
                            }
                          </h3>
                          <p>Tokens Transferred</p>
                        </div>
                        {(depositTokensView.data.deposit_transaction
                          .payment_type == "bank" && depositTokensView.data.deposit_transaction.payment_file) ? (
                          <div className="transaction-basic-info-head-titles">
                            <Button
                              onClick={() => setStep(2)}
                              className="action-btn  overlay"
                            >
                              {" "}
                              View Payment Proof{" "}
                            </Button>
                          </div>
                        ): null}
                      </div>
                      <div className="transaction-space"></div>
                      <div className="transaction-basic-info-content">
                        <div className="transaction-basic-content-card">
                          <p>Amount Paid</p>
                          <h5>
                            {
                              depositTokensView.data.deposit_transaction
                                .from_amount_formatted
                            }{" "}
                          </h5>
                        </div>
                        <div className="transaction-basic-content-card">
                          <p>Status</p>
                          {/* <div className={`pay-badge ${depositTokensView.data.deposit_transaction.status ==
                              1
                              ? "succes"
                              : depositTokensView.data.deposit_transaction.status ==2?"denied":"pending"
                              }`}>
                              {
                                  depositTokensView.data.deposit_transaction
                                      .status_formatted
                              }
                          </div> */}
                          <div
                            className={`table_badge  ${
                              depositTokensView.data.deposit_transaction
                                .status == 1
                                ? "success"
                                : depositTokensView.data.deposit_transaction
                                    .status == 0
                                ? "pending"
                                : "fail"
                            }`}
                          >
                            {" "}
                            {
                              depositTokensView.data.deposit_transaction
                                .status_formatted
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="transaction-basic-tab-wrap out_space_mt_1">
                      <Tab.Container
                        id="transaction-basic-tab"
                        defaultActiveKey="transaction"
                      >
                        <Nav variant="pills">
                          <Nav.Item>
                            <Nav.Link
                              eventKey="transaction"
                              className="view_tab_btn"
                            >
                              Transaction Details
                            </Nav.Link>
                          </Nav.Item>
                          {depositTokensView.data.deposit_transaction
                            .payment_type == "bank" && (
                            <Nav.Item>
                              <Nav.Link
                                eventKey="bank"
                                className="view_tab_btn"
                              >
                                Bank Details
                              </Nav.Link>
                            </Nav.Item>
                          )}
                        </Nav>
                        <Tab.Content>
                          <Tab.Pane eventKey="transaction">
                            <div className="transaction-tab-frame">
                              {/* <div className="transaction-tab-info-card">
                                  <p>Exchange Value</p>
                                  <h4>1.00 AED = 0.24523161 USDT</h4>
                              </div> */}
                              {depositTokensView.data.deposit_transaction
                                .payment_type == "card" && (
                                <div className="transaction-tab-info-card">
                                  <p>Card details</p>
                                  <h4>
                                    {
                                      depositTokensView.data.deposit_transaction
                                        .user_card_number
                                    }
                                  </h4>
                                </div>
                              )}
                              {depositTokensView.data.deposit_transaction
                                ?.admin_crypto_account?.wallet_address ? (
                                <div className="transaction-tab-info-card">
                                  <p>Crypto Wallet address</p>
                                  <h4>
                                    {
                                      depositTokensView.data.deposit_transaction
                                        .admin_crypto_account?.wallet_address
                                    }
                                  </h4>
                                </div>
                              ): null}
                              <div className="transaction-tab-info-card">
                                <p>Transaction Date</p>
                                <h4>
                                  {
                                    depositTokensView.data.deposit_transaction
                                      .created_at_formatted
                                  }{" "}
                                </h4>
                              </div>
                              {/* <div className="transaction-tab-info-card">
                                <p>
                                  {depositTokensView.data.deposit_transaction
                                    ?.admin_crypto_account?.wallet_address !==
                                  null
                                    ? "Transaction hash"
                                    : "Transaction reference id"}
                                </p>
                                {depositTokensView.data.deposit_transaction
                                  .payment_id ? (
                                  <>
                                    <h4 className="copy-view-card">
                                      {" "}
                                      <span>
                                        {
                                          depositTokensView.data
                                            .deposit_transaction.payment_id
                                        }
                                      </span>{" "}
                                      <CopyToClipboard
                                        text={
                                          depositTokensView.data
                                            .deposit_transaction.payment_id
                                        }
                                        onCopy={() =>
                                          getSuccessNotificationMessage(
                                            t(
                                              "payment_id_was_copied_to_clipboard"
                                            )
                                          )
                                        }
                                      >
                                        <Button className="action-btn copy-btn item_center">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="#298BFF"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            class="icon icon-tabler icons-tabler-outline icon-tabler-copy"
                                          >
                                            <path
                                              stroke="none"
                                              d="M0 0h24v24H0z"
                                              fill="none"
                                            ></path>
                                            <path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z"></path>
                                            <path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1"></path>
                                          </svg>
                                        </Button>
                                      </CopyToClipboard>
                                    </h4>
                                  </>
                                ) : (
                                  "Transaction ref id not available"
                                )}
                              </div> */}
                              {depositTokensView.data.deposit_transaction
                                .commission ? (
                                <div className="transaction-tab-info-card">
                                  <p>Service Fee</p>
                                  <h4>
                                    {depositTokensView.data.deposit_transaction.commission}
                                  </h4>
                                </div>
                              ) : null}
                              <div className="transaction-tab-info-card">
                                <p>Type</p>
                                <h4>
                                  Deposit
                                </h4>
                              </div>
                              <div className="transaction-tab-info-card">
                                <p>Payment Type</p>
                                <h4>
                                  {depositTokensView.data.deposit_transaction
                                    .payment_type == "bank"
                                    ? "Bank"
                                    : depositTokensView.data.deposit_transaction
                                        .payment_type == "card"
                                    ? "Card"
                                    : depositTokensView.data.deposit_transaction
                                        .payment_type == "wallet"
                                    ? "Wallet"
                                    : depositTokensView.data.deposit_transaction
                                        .payment_type == "CRYPTO"
                                    ? "Crypto"
                                    : "Not available"}
                                </h4>
                              </div>
                              {/* {depositTokensView.data.deposit_transaction
                                .commission ? (
                                <div className="transaction-tab-info-card">
                                  <p>Commission Rate</p>
                                  <h4>
                                    {
                                      depositTokensView.data.deposit_transaction
                                        .commission
                                    }
                                  </h4>
                                </div>
                              ) : null} */}
                              {/* <div className="transaction-tab-info-card">
                                <p>View on Explorer</p>
                                <Button
                                  className=" action-btn link-btn"
                                  onClick={() => {
                                    window.open(
                                      depositTokensView.data.deposit_transaction
                                        ?.explorer_url,
                                      "_blank"
                                    );
                                  }}
                                >
                                  View on Explorer
                                </Button>
                              </div> */}
                            </div>
                          </Tab.Pane>
                          {/* <Tab.Pane eventKey="bank">
                            <div className="bank-tab-info-wrap">
                              <div className="bank-tab-card-info out_space_mx_1">
                                <div className="bank-tab-card-wrap">
                                  <div className="bank-tab-card-wrap-icons">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="30"
                                      height="30"
                                      enableBackground="new 0 0 512 512"
                                      viewBox="0 0 24 24"
                                    >
                                      <path
                                        d="M24 23a1 1 0 01-1 1H1a1 1 0 010-2h22a1 1 0 011 1zM.291 8.552a2.443 2.443 0 01.153-2.566 4.716 4.716 0 011.668-1.5L9.613.582a5.174 5.174 0 014.774 0l7.5 3.907a4.716 4.716 0 011.668 1.5 2.443 2.443 0 01.153 2.566A2.713 2.713 0 0121.292 10H21v8h1a1 1 0 010 2H2a1 1 0 010-2h1v-8h-.292A2.713 2.713 0 01.291 8.552zM5 18h3v-8H5zm5-8v8h4v-8zm9 0h-3v8h3zM2.063 7.625A.717.717 0 002.708 8h18.584a.717.717 0 00.645-.375.452.452 0 00-.024-.5 2.7 2.7 0 00-.949-.864l-7.5-3.907a3.176 3.176 0 00-2.926 0l-7.5 3.907a2.712 2.712 0 00-.949.865.452.452 0 00-.026.499z"
                                        data-original="#000000"
                                      ></path>
                                    </svg>
                                  </div>
                                  <div className="bank-tab-card-wrap-info">
                                    <h4>
                                      {
                                        depositTokensView.data
                                          .deposit_transaction
                                          ?.admin_bank_account
                                          ?.account_holder_name
                                      }
                                    </h4>
                                    <p>
                                      {" "}
                                      {
                                        depositTokensView.data
                                          .deposit_transaction
                                          ?.admin_bank_account?.bank_name
                                      }
                                    </p>
                                  </div>
                                </div>
                                <h5>
                                  {formatNumber(
                                    depositTokensView.data.deposit_transaction
                                      ?.admin_bank_account?.account_number
                                  )}
                                </h5>
                              </div>
                              {props.show.from_currency ? (
                                <>
                                  {(depositTokensView.data.deposit_transaction
                                    ?.admin_bank_account?.bank_code ||
                                    depositTokensView.data.deposit_transaction
                                      ?.admin_bank_account
                                      ?.swift_code_correspondent_bank) && (
                                    <div className="bank-tab-card-other-info out_space_mx_1">
                                      <div className="bank-tab-card-other-info-titles">
                                        <h3>Beneficiary</h3>
                                      </div>
                                      <div className="bank-tab-card-other-info-content">
                                        {depositTokensView.data
                                          .deposit_transaction
                                          ?.admin_bank_account?.bank_code && (
                                          <div className="bank-tab-card-other-wrap">
                                            <p>IBAN No</p>
                                            <h4>
                                              {
                                                depositTokensView.data
                                                  .deposit_transaction
                                                  ?.admin_bank_account
                                                  ?.bank_code
                                              }
                                            </h4>
                                          </div>
                                        )}
                                        {depositTokensView.data
                                          .deposit_transaction
                                          ?.admin_bank_account
                                          ?.swift_code_correspondent_bank && (
                                          <div className="bank-tab-card-other-wrap">
                                            <p>Bank swift code</p>
                                            <h4>
                                              {
                                                depositTokensView.data
                                                  .deposit_transaction
                                                  ?.admin_bank_account
                                                  ?.swift_code_correspondent_bank
                                              }
                                            </h4>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                  {depositTokensView.data.deposit_transaction
                                    ?.admin_bank_account
                                    ?.swift_code_beneficiary_bank && (
                                    <div className="bank-tab-card-other-info">
                                      <div className="bank-tab-card-other-info-titles">
                                        <h3>Correspondent</h3>
                                      </div>
                                      <div className="bank-tab-card-other-info-content">
                                        <div className="bank-tab-card-other-wrap">
                                          <p>Swift Code</p>
                                          <h4>
                                            {
                                              depositTokensView.data
                                                .deposit_transaction
                                                ?.admin_bank_account
                                                ?.swift_code_beneficiary_bank
                                            }
                                          </h4>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </>
                              ) : (
                                depositTokensView.data.deposit_transaction
                                  ?.admin_bank_account?.bank_code && (
                                  <div className="bank-tab-card-other-info out_space_mx_1">
                                    <div className="bank-tab-card-other-info-titles">
                                      <h3>Beneficiary</h3>
                                    </div>
                                    <div className="bank-tab-card-other-info-content">
                                      {depositTokensView.data
                                        .deposit_transaction?.admin_bank_account
                                        ?.bank_code && (
                                        <div className="bank-tab-card-other-wrap">
                                          <p>IBAN No</p>
                                          <h4>
                                            {
                                              depositTokensView.data
                                                .deposit_transaction
                                                ?.admin_bank_account?.bank_code
                                            }
                                          </h4>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          </Tab.Pane> */}
                           <Tab.Pane eventKey="bank">
                            <div className="bank-tab-info-wrap">
                              <div className="bank-tab-card-info out_space_mx_1">
                                <div className="bank-tab-card-wrap">
                                  <div className="bank-tab-card-wrap-icons">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="30"
                                      height="30"
                                      enableBackground="new 0 0 512 512"
                                      viewBox="0 0 24 24"
                                    >
                                      <path
                                        d="M24 23a1 1 0 01-1 1H1a1 1 0 010-2h22a1 1 0 011 1zM.291 8.552a2.443 2.443 0 01.153-2.566 4.716 4.716 0 011.668-1.5L9.613.582a5.174 5.174 0 014.774 0l7.5 3.907a4.716 4.716 0 011.668 1.5 2.443 2.443 0 01.153 2.566A2.713 2.713 0 0121.292 10H21v8h1a1 1 0 010 2H2a1 1 0 010-2h1v-8h-.292A2.713 2.713 0 01.291 8.552zM5 18h3v-8H5zm5-8v8h4v-8zm9 0h-3v8h3zM2.063 7.625A.717.717 0 002.708 8h18.584a.717.717 0 00.645-.375.452.452 0 00-.024-.5 2.7 2.7 0 00-.949-.864l-7.5-3.907a3.176 3.176 0 00-2.926 0l-7.5 3.907a2.712 2.712 0 00-.949.865.452.452 0 00-.026.499z"
                                        data-original="#000000"
                                      ></path>
                                    </svg>
                                  </div>
                                  <div className="bank-tab-card-wrap-info">
                                    <h4>{depositTokensView.data.deposit_transaction.admin_bank_account.account_holder_name}</h4>
                                    <p>{depositTokensView.data.deposit_transaction.admin_bank_account.bank_name}</p>
                                  </div>
                                </div>
                                <h5>{depositTokensView.data.deposit_transaction.admin_bank_account.account_number}</h5>
                              </div>
                              <div className="bank-tab-card-other-info out_space_mx_1">
                                <div className="bank-tab-card-other-info-titles">
                                  <h3>Bank Details</h3>
                                </div>
                                <div className="bank-tab-card-other-info-content">
                                  <div className="bank-tab-card-other-wrap">
                                    <p>Account Type</p>
                                    <h4>{depositTokensView.data.deposit_transaction.admin_bank_account.account_type}</h4>
                                  </div>
                                  <div className="bank-tab-card-other-wrap">
                                    <p>Currency Type</p>
                                    <h4>{depositTokensView.data.deposit_transaction.admin_bank_account.currency_type}</h4>
                                  </div>
                                  <div className="bank-tab-card-other-wrap">
                                    <p>Address</p>
                                    <h4>{depositTokensView.data.deposit_transaction.admin_bank_account.bank_address}</h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </div>
                  </>
                ) : (
                  <div className="transaction-proof-wrap">
                    <div className="transaction-proof-back">
                      <Button
                        className="action-btn overlay"
                        onClick={() => setStep(1)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          x="0"
                          y="0"
                          enableBackground="new 0 0 240.823 240.823"
                          version="1.1"
                          viewBox="0 0 240.823 240.823"
                          xmlSpace="preserve"
                          width="12"
                          height="12"
                        >
                          <path d="M57.633 129.007L165.93 237.268c4.752 4.74 12.451 4.74 17.215 0 4.752-4.74 4.752-12.439 0-17.179l-99.707-99.671 99.695-99.671c4.752-4.74 4.752-12.439 0-17.191-4.752-4.74-12.463-4.74-17.215 0L57.621 111.816c-4.679 4.691-4.679 12.511.012 17.191z"></path>
                        </svg>
                        Back
                      </Button>
                    </div>
                    <div className="transaction-proof-frame">
                      <CustomLazyLoad
                        className="transaction-proof-img"
                        src={
                          depositTokensView.data.deposit_transaction
                            .payment_file
                        }
                        alt="image-preview"
                      />
                    </div>
                  </div>
                )}
              </div>
            </>
          ) : (
            <NoDataFound />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DepositTransactionViewModal;
