import {
  Formik,
  Form as FORM,
  Field,
  ErrorMessage,
  isEmptyArray,
} from "formik";
import React, { useState, useEffect, useRef } from "react";
import { Tab, Tabs, Form, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import "toolcool-range-slider";
import { useDispatch, useSelector } from "react-redux";
import { ButtonLoader } from "../../helper/Loader";
import * as Yup from "yup";
import Skeleton from "react-loading-skeleton";
import { tokenTradeTransactionStart } from "../../../store/actions/BuyAction";
import { getErrorNotificationMessage } from "../../helper/ToastNotification";

const BuyStock = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tokenTradeTransaction = useSelector(
    (state) => state.buy.tokenTradeTransaction
  );
  const currencies = useSelector((state) => state.buy.currencies);
  const profile = useSelector((state) => state.user.profile);
  const singleWallet = useSelector((state) => state.wallet.singleWallet);
  const [skipRender, setSkipRender] = useState(true);
  const currencyPair = useSelector((state) => state.buy.currencyPair);
  const [rangeValue, setRangeValue] = useState(0);
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("token") || sessionStorage.getItem("token")
      ? true
      : false
  );
  const formRef = useRef();
  const sliderRef = useRef(null);
  const cryptoCurrency = useSelector((state) => state.buy.cryptoCurrency);

  useEffect(() => {
    const slider = sliderRef.current;

    return () => {
      if (slider) {
      }
    };
  }, []);

  const options = [
    { value: "option1", label: "Conditional" },
    { value: "option2", label: "OCO" },
  ];

  const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "12px",
      overflow: "hidden",
      minWidth: "100px",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #E7E7E7!important",
      borderRadius: "6px!important",
      boxShadow: "none!important",
      height: "35px",
      cursor: "pointer",
      minWidth: "auto !important",
      fontSize: "0.9em",
      color: "#111111",
      fontWeight: "400",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#111111",
      fontSize: ".9em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#111111",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "400",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      color: "#DDE1E6!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#DDE1E6!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#50506B80",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused || state.isSelected ? "#F9C201" : "#fff",
      color: state.isFocused || state.isSelected ? "#000" : "#000",
      ":hover": {
        backgroundColor: "#F9C201",
        color: "#000",
      },
    }),
  };

  const handleSubmit = (values) => {
    currencyPair !== null
      ? profile.data?.kyc_verified == 0
        ? getErrorNotificationMessage("Kyc is not verified")
        : dispatch(
            tokenTradeTransactionStart({ ...values, pair_id: currencyPair?.id })
          )
      : getErrorNotificationMessage("Select currency pair from left sidebar");
  };

  const buySchema = Yup.object().shape({
    value: Yup.string()
      .required("Required")
      .min(0.0000001, "Min 0.0000001 required")
      .max(100000, "Max 100000 allowed"),
  });

  useEffect(() => {
    if (
      !skipRender &&
      !tokenTradeTransaction.loading &&
      Object.keys(tokenTradeTransaction.data).length > 0
    ) {
      props.onRefresh();
      formRef?.current.resetForm();
      setRangeValue(0);
    }
    setSkipRender(false);
  }, [tokenTradeTransaction]);

  return (
    <>
      {/* <Tabs defaultActiveKey="market" transition={false} id="noanim-tab-example"> */}
      {/* <Tab eventKey="limit" title="Limit">
        <Form>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Price (USDT)</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Amount"
              value={usdValue}
              onChange={handleInputChange}
            />
            <Form.Text>
              {usdValue * conversionRate} ⟨₹⟩
            </Form.Text>
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Amount (BTC)</Form.Label>
            <Form.Control type="text" placeholder="Min 0.0000001 BCT" />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput1" className="trade-percent-range">
            <div className="range-label">
              <span>0%</span>
              <span>100%</span>
            </div>
            <Form.Range
              min="0"
              max="100"
              value={rangeValue}
              onChange={handleRangeChange}
            />
            <div className="trade-range-value">
              {rangeValue} % / 100 %
            </div>
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Total (USDT)</Form.Label>
            <Form.Control type="text" placeholder="" />
          </Form.Group>
          <div className="trade-stock-max">
            <div className="trade-stock-max-info-sec">
              <div className="trade-stock-max-info">Available<span>--BTC</span></div>
              <div className="trade-stock-max-info">Max sell<span>--USDT</span></div>
            </div>
            <div className="trade-stock-max-icon">
              <Link to="#">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  enableBackground="new 0 0 512 512"
                  viewBox="0 0 477.859 477.859"
                >
                  <path
                    fill="#25a750"
                    d="M472.863 175.662L353.396 56.195c-6.666-6.664-17.472-6.662-24.136.004a17.066 17.066 0 00-4.997 12.063v51.2H204.796c-9.426 0-17.067 7.641-17.067 17.067s7.641 17.067 17.067 17.067H341.33c9.426 0 17.067-7.641 17.067-17.067V109.46l78.268 78.268-78.268 78.268v-27.068c0-9.426-7.641-17.067-17.067-17.067H153.596v-51.2c-.002-9.426-7.645-17.065-17.07-17.063a17.068 17.068 0 00-12.063 4.997L4.997 278.062c-6.663 6.665-6.663 17.468 0 24.132l119.467 119.467c3.2 3.201 7.54 5 12.066 5.001a16.852 16.852 0 006.536-1.297 17.066 17.066 0 0010.53-15.77v-51.2h119.467c9.426 0 17.067-7.641 17.067-17.067s-7.641-17.067-17.067-17.067H136.53c-9.426 0-17.067 7.641-17.067 17.067v27.068l-78.268-78.268 78.268-78.268v27.068c0 9.426 7.641 17.067 17.067 17.067h187.733v51.2c.002 9.426 7.645 17.065 17.07 17.063a17.068 17.068 0 0012.063-4.997l119.467-119.467c6.662-6.665 6.662-17.468 0-24.132z"
                    data-original="#000000"
                  ></path>
                </svg>
              </Link>
            </div>
          </div>
          <Form.Group controlId="formBasicCheckbox">
            <Form.Check
              type="checkbox"
              label="TP/SL"
              onChange={handleCheckboxChange}
            />
            {showFields && (
              <>
                <Form.Group className="mb-2">
                  <Form.Label>TP trigger price (USDT)</Form.Label>
                  <Form.Control type="text" placeholder="Place order at market price" min={0} />
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Label>TP prder price (USDT)</Form.Label>
                  <Form.Control type="text" placeholder="Min 0.0000001" />
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Label>SL trigger price (USDT)</Form.Label>
                  <Form.Control type="text" placeholder="Min 0.0000001" min={0} />
                </Form.Group>
                <Form.Group >
                  <Form.Label>SL prder price (USDT)</Form.Label>
                  <Form.Control type="text" placeholder="Min 0.0000001" />
                </Form.Group>
              </>
            )}
          </Form.Group>
          <Button className="buy-form-btn" type="submit">
            Buy
          </Button>
          <div className="trade-price-range">
            <div className="trade-price-range-info">
              Max price <span>₮2,647.52</span>
            </div>
            <div className="trade-price-range-info">
              Min price <span> ₮2,596.05</span>
            </div>
          </div>
          <div className="trade-price-fee">
            % Fees
          </div>
        </Form>
      </Tab> */}
      {/* <Tab eventKey="market" title="Market"> */}
      {singleWallet.buttonDisable ? (
        <>
        <Form.Group controlId="formBasicEmail" className="mb-2">
          <Skeleton height={15} width={80} borderRadius={10} />
          <Skeleton height={45} borderRadius={10} />
          {/* <Form.Text>{usdValue * conversionRate} ⟨₹⟩</Form.Text> */}
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1" className="mb-2">
          <Skeleton height={15} width={80} borderRadius={10} />
          <Skeleton height={45} borderRadius={10} />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1" >
          <Skeleton height={15} width={80} borderRadius={10} />
          <Skeleton height={45} borderRadius={10} />
        </Form.Group>
        <Form.Group
          controlId="exampleForm.ControlInput1"
          className="trade-percent-range mb-3"
        >
          <div className="range-label">
            <Skeleton height={15} borderRadius={10} />
          </div>
          <Skeleton height={20} borderRadius={10} />
          <Skeleton height={20} borderRadius={10} />
          <div className="trade-range-value">
            <Skeleton height={15} width={80} borderRadius={10} />
          </div>
        </Form.Group>
        <Skeleton height={40} borderRadius={10} className="mb-2" />
        <Skeleton height={35} borderRadius={25} />
        </>
      ) : (
        <Formik
          initialValues={{
            value: "",
            type: "buy",
            pair_id: "",
          }}
          innerRef={formRef}
          validationSchema={isLoggedIn && buySchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ touched, errors, setFieldValue, values, resetForm }) => (
            <FORM>
              <Form.Group controlId="formBasicEmail" className="mb-2">
                <Form.Label>Price ({currencyPair?.currency2})</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Place order at market price"
                  disabled={true}
                />
                {/* <Form.Text>{usdValue * conversionRate} ⟨₹⟩</Form.Text> */}
              </Form.Group>
              <Form.Group controlId="exampleForm.ControlInput1" className="mb-2">
                <Form.Label>Quantity</Form.Label>
                <Field
                  type="text"
                  name="value"
                  disabled={
                    (!singleWallet.data?.user_wallet?.remaining_original &&
                      isLoggedIn) ||
                    (singleWallet.data?.user_wallet?.remaining_original <= 0 &&
                      isLoggedIn)
                  }
                  onKeyDown={(e) => {
                    if (
                      !/[0-9.]|Backspace|Tab/.test(e.key) &&
                      !(
                        (e.ctrlKey &&
                          (e.key === "v" ||
                            e.key === "c" ||
                            e.key === "x" ||
                            e.key === "a")) ||
                        e.code == "Space" ||
                        e.code == "ArrowRight" ||
                        e.code == "ArrowLeft"
                      )
                    ) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    const remainingOriginal =
                      singleWallet.data?.user_wallet?.remaining_original || 0;

                    if (
                      newValue <= parseFloat(remainingOriginal) ||
                      (!localStorage.getItem("token") &&
                        !sessionStorage.getItem("token"))
                    ) {
                      setFieldValue("value", newValue);
                      const percentage =
                        (parseFloat(newValue) / remainingOriginal) * 100;
                      isLoggedIn &&
                        setRangeValue(
                          isNaN(percentage)
                            ? 0
                            : Math.min(percentage, 100).toFixed(2)
                        );
                    } else {
                      setFieldValue("value", values.value);
                    }
                  }}
                  className="form-control"
                  placeholder="Enter Quantity"
                />
                <ErrorMessage
                  component={"div"}
                  name="value"
                  className="text-danger text-right"
                />
              </Form.Group>
              <Form.Group controlId="exampleForm.ControlInput1" className="mb-3">
                <Form.Label>Amount ({currencyPair?.currency1})</Form.Label>
                <Form.Control
                  type="text"
                  value={
                    values.value > 0
                      ? !isNaN(
                          ((values.value * 1) /
                            (cryptoCurrency?.filter(
                              (data) => currencyPair?.currency1 == data.asset
                            ))[0]?.value) *
                            (currencyPair?.currency_type2 == "fiat"
                              ? currencies.data?.forex_currencies?.filter(
                                  (data) =>
                                    data.currency_code ==
                                    currencyPair?.currency2
                                )[0]?.exchange_rate
                              : (cryptoCurrency?.filter(
                                  (data) =>
                                    currencyPair?.currency2 == data.asset
                                ))[0]?.value)
                        )
                        ? (
                            ((values.value * 1) /
                              (cryptoCurrency?.filter(
                                (data) => currencyPair?.currency1 == data.asset
                              ))[0]?.value) *
                            (currencyPair?.currency_type2 == "fiat"
                              ? currencies.data?.forex_currencies?.filter(
                                  (data) =>
                                    data.currency_code ==
                                    currencyPair?.currency2
                                )[0]?.exchange_rate
                              : (cryptoCurrency?.filter(
                                  (data) =>
                                    currencyPair?.currency2 == data.asset
                                ))[0]?.value)
                          ).toFixed(8)
                        : "0.0000"
                      : "0.0000"
                  }
                  disabled={true}
                  placeholder="Min 0.0000001 BCT"
                />
              </Form.Group>
              <Form.Group
                controlId="exampleForm.ControlInput1"
                className="trade-percent-range mb-3"
              >
                <div className="range-label">
                  <span>{rangeValue}%</span>
                  <span>100%</span>
                </div>
                <Form.Range
                  disabled={
                    !singleWallet.data?.user_wallet?.remaining_original ||
                    singleWallet.data?.user_wallet?.remaining_original <= 0
                  }
                  min="0"
                  max="100"
                  value={rangeValue}
                  onChange={(e) => {
                    const data =
                      (parseFloat(e.target.value) / 100) *
                      (singleWallet.data?.user_wallet
                        ? parseFloat(
                            singleWallet.data?.user_wallet?.remaining_original
                          )
                        : 0);
                    setRangeValue(e.target.value);
                    setFieldValue("value", data.toFixed(8));
                  }}
                />
                <div className="trade-range-value">{rangeValue} % / 100 %</div>
              </Form.Group>
              <div className="trade-stock-max mb-3">
                <div className="trade-stock-max-info-sec">
                  <div className="trade-stock-max-info">
                    Available:
                    <span>
                      {singleWallet.data &&
                      singleWallet.data?.user_wallet?.remaining
                        ? singleWallet.data &&
                          singleWallet.data?.user_wallet?.remaining
                        : "0.00"}
                    </span>
                  </div>
                  {/* <div className="trade-stock-max-info">
                    Max sell<span>--USDT</span>
                  </div> */}
                </div>
                {/* <div className="trade-stock-max-icon">
                  <Link to="#">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      enableBackground="new 0 0 512 512"
                      viewBox="0 0 477.859 477.859"
                    >
                      <path
                        fill="#25a750"
                        d="M472.863 175.662L353.396 56.195c-6.666-6.664-17.472-6.662-24.136.004a17.066 17.066 0 00-4.997 12.063v51.2H204.796c-9.426 0-17.067 7.641-17.067 17.067s7.641 17.067 17.067 17.067H341.33c9.426 0 17.067-7.641 17.067-17.067V109.46l78.268 78.268-78.268 78.268v-27.068c0-9.426-7.641-17.067-17.067-17.067H153.596v-51.2c-.002-9.426-7.645-17.065-17.07-17.063a17.068 17.068 0 00-12.063 4.997L4.997 278.062c-6.663 6.665-6.663 17.468 0 24.132l119.467 119.467c3.2 3.201 7.54 5 12.066 5.001a16.852 16.852 0 006.536-1.297 17.066 17.066 0 0010.53-15.77v-51.2h119.467c9.426 0 17.067-7.641 17.067-17.067s-7.641-17.067-17.067-17.067H136.53c-9.426 0-17.067 7.641-17.067 17.067v27.068l-78.268-78.268 78.268-78.268v27.068c0 9.426 7.641 17.067 17.067 17.067h187.733v51.2c.002 9.426 7.645 17.065 17.07 17.063a17.068 17.068 0 0012.063-4.997l119.467-119.467c6.662-6.665 6.662-17.468 0-24.132z"
                        data-original="#000000"
                      ></path>
                    </svg>
                  </Link>
                </div> */}
              </div>
              {/* <Form.Group controlId="formBasicCheckbox">
            <Form.Check
              type="checkbox"
              label="TP/SL"
              onChange={handleCheckboxChange}
            />
            {showFields && (
              <>
                <Form.Group className="mb-2">
                  <Form.Label>TP trigger price (USDT)</Form.Label>
                  <Form.Control type="number" placeholder="Place order at market price" min={0} />
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Label>TP prder price (USDT)</Form.Label>
                  <Form.Control type="number" placeholder="Min 0.0000001" />
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Label>SL trigger price (USDT)</Form.Label>
                  <Form.Control type="number" placeholder="Place order at market price" min={0} />
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Label>SL prder price (USDT)</Form.Label>
                  <Form.Control type="number" placeholder="Min 0.0000001" />
                </Form.Group>
              </>
            )}
          </Form.Group> */}

              <>
                {singleWallet.data?.user_wallet?.remaining_original > 0 &&
                  isLoggedIn && (
                    <Button
                      className="buy-form-btn"
                      type="submit"
                      disabled={tokenTradeTransaction.buttonDisable}
                    >
                      {tokenTradeTransaction.buttonDisable ? (
                        <ButtonLoader />
                      ) : (
                        "Buy"
                      )}
                    </Button>
                  )}

                {Object.keys(singleWallet.data).length <= 0 && isLoggedIn && (
                  <>
                    <Button
                      className="action-btn primary w-100"
                      onClick={() => {
                        props.onRefresh();
                      }}
                    >
                      Refresh
                    </Button>
                    <p className="text-danger text-right">
                      There was an issue fetching the wallet details. Please try
                      refreshing the page. If the problem persists, contact
                      support for assistance.
                    </p>
                  </>
                )}

                {(!sessionStorage.getItem("userId") ||
                  !sessionStorage.getItem("token")) &&
                (!localStorage.getItem("userId") ||
                  !localStorage.getItem("token")) ? (
                  <>
                    <Button
                      onClick={() => {
                        navigate("/login");
                      }}
                      className="buy-form-btn"
                    >
                      Login
                    </Button>
                  </>
                ) : (
                  singleWallet?.data.user_wallet?.remaining_original <= 0 && (
                    <>
                    <div className="trade-note">
                      <p> Insufficient Wallet balance</p>
                      </div>
                      <Button
                        onClick={() => {
                          navigate(
                            `/wallet/${singleWallet.data?.user_wallet.id}`
                          );
                        }}
                        className="buy-form-btn"
                        disabled={singleWallet.buttonDisable}
                      >
                        Add token
                      </Button>
                    </>
                  )
                )}
              </>

              {/* <div className="trade-price-range">
                <div className="trade-price-range-info">
                  Max price{" "}
                  <span>
                   
                  </span>
                </div>
                <div className="trade-price-range-info">
                Min price <span> ₮2,596.05</span>
              </div>
              </div>
              <div className="trade-price-fee">% Fees</div> */}
            </FORM>
          )}
        </Formik>
      )}
      {/* </Tab> */}
      {/* <Tab eventKey="tpsl" title="TP/SL">
        <Form>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Conditional/OCO</Form.Label>
            <Select
              defaultValue={selectedOption}
              onChange={setSelectedOption}
              options={options}
              styles={customStyles}
            />
          </Form.Group>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Price (USDT)</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Amount"
              value={usdValue}
              onChange={handleInputChange}
            />
            <Form.Text>
              {usdValue * conversionRate} ⟨₹⟩
            </Form.Text>
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Market Order (USDT)</Form.Label>
            <Form.Control type="text" placeholder="Min 0.0000001 BCT" />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Total (USDT)</Form.Label>
            <Form.Control type="text" placeholder="Min 0.0000001 BCT" />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput1" className="trade-percent-range">
            <div className="range-label">
              <span>0%</span>
              <span>100%</span>
            </div>
            <Form.Range
              min="0"
              max="100"
              value={rangeValue}
              onChange={handleRangeChange}
            />
            <div className="trade-range-value">
              {rangeValue} % / 100 %
            </div>
          </Form.Group>
          <div className="trade-stock-max">
            <div className="trade-stock-max-info-sec">
              <div className="trade-stock-max-info">Available<span>--BTC</span></div>
              <div className="trade-stock-max-info">Max sell<span>--USDT</span></div>
            </div>
            <div className="trade-stock-max-icon">
              <Link to="#">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  enableBackground="new 0 0 512 512"
                  viewBox="0 0 477.859 477.859"
                >
                  <path
                    fill="#25a750"
                    d="M472.863 175.662L353.396 56.195c-6.666-6.664-17.472-6.662-24.136.004a17.066 17.066 0 00-4.997 12.063v51.2H204.796c-9.426 0-17.067 7.641-17.067 17.067s7.641 17.067 17.067 17.067H341.33c9.426 0 17.067-7.641 17.067-17.067V109.46l78.268 78.268-78.268 78.268v-27.068c0-9.426-7.641-17.067-17.067-17.067H153.596v-51.2c-.002-9.426-7.645-17.065-17.07-17.063a17.068 17.068 0 00-12.063 4.997L4.997 278.062c-6.663 6.665-6.663 17.468 0 24.132l119.467 119.467c3.2 3.201 7.54 5 12.066 5.001a16.852 16.852 0 006.536-1.297 17.066 17.066 0 0010.53-15.77v-51.2h119.467c9.426 0 17.067-7.641 17.067-17.067s-7.641-17.067-17.067-17.067H136.53c-9.426 0-17.067 7.641-17.067 17.067v27.068l-78.268-78.268 78.268-78.268v27.068c0 9.426 7.641 17.067 17.067 17.067h187.733v51.2c.002 9.426 7.645 17.065 17.07 17.063a17.068 17.068 0 0012.063-4.997l119.467-119.467c6.662-6.665 6.662-17.468 0-24.132z"
                    data-original="#000000"
                  ></path>
                </svg>
              </Link>
            </div>
          </div>
          <Form.Group controlId="formBasicCheckbox">
            <Form.Check
              type="checkbox"
              label="TP/SL"
              onChange={handleCheckboxChange}
            />
            {showFields && (
              <>
                <Form.Group className="mb-2">
                  <Form.Label>TP trigger price (USDT)</Form.Label>
                  <Form.Control type="number" placeholder="Place order at market price" min={0} />
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Label>TP prder price (USDT)</Form.Label>
                  <Form.Control type="number" placeholder="Min 0.0000001 BCT" />
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Label>SL trigger price (USDT)</Form.Label>
                  <Form.Control type="number" placeholder="Place order at market price" min={0} />
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Label>SL prder price (USDT)</Form.Label>
                  <Form.Control type="number" placeholder="Min 0.0000001 BCT" />
                </Form.Group>
              </>
            )}
          </Form.Group>
          <Button className="buy-form-btn" type="submit">
            Buy
          </Button>
          <div className="trade-price-range">
            <div className="trade-price-range-info">
              Max price <span>₮2,647.52</span>
            </div>
            <div className="trade-price-range-info">
              Min price <span> ₮2,596.05</span>
            </div>
          </div>
          <div className="trade-price-fee">
            % Fees
          </div>
        </Form>
      </Tab> */}
      {/* </Tabs> */}
    </>
  );
};

export default BuyStock;
