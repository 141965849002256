import React, { useState } from "react";
import { Navbar, Container, Image, Nav, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
import configuration from "react-global-configuration";
import CustomLazyLoad from "../helper/CustomLazyLoad";

const DemoOfProduct = (props) => {

  const t = useTranslation("demoof_product");

  return (
    <>
      <div className="demo-of-product-sec sm-padding">
        <Container fluid>
          <Row>
            <Col md={12}>
              <div className="demo-of-product-box">
                <div className="demo-of-product-card" data-aos="fade-up">
                  <div className="demo-of-product-icon-sec">
                  <CustomLazyLoad
                      className="demo-of-product-icon"
                      src={
                        window.location.origin + "/img/landing/demo/demo-icon-1.svg"
                      }
                      type="image/png"
                    />
                  </div>
                  <div className="demo-of-product-info">
                    <h4>{t("heading")}</h4>
                    <p>{t("trade_with_tranquility_para")} {configuration.get("configData.site_name")} {t("your_fortress_in_the_crypto_para")}</p>
                    <div className="demo-product-btn-sec">
                      <Link to="/exchange" className="new-default-primary-btn" data-aos="fade-up">
                       {t("exchange")}
                       <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 24 24"><path stroke="#171717" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5" d="M14.43 5.93L20.5 12l-6.07 6.07M11.01 12h9.32M3.5 12h3.47"></path></svg>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="demo-of-product-card" data-aos="fade-up">
                  <div className="demo-of-product-icon-sec">
                  <CustomLazyLoad
                      className="demo-of-product-icon"
                      src={
                        window.location.origin + "/img/landing/demo/demo-icon-2.svg"
                      }
                      type="image/png"
                    />
                  </div>
                  <div className="demo-of-product-info">
                    <h4>{t("join_the_best")} <span>{t("crypto")}</span>{t("exchange_platform")}</h4>
                    <p>{t("step_into")} {configuration.get("configData.site_name")} (HeraldEX), {t("where_premium_crypto_para")}</p>
                    <div className="demo-product-btn-sec-1">
                      <Link to="/register" className="new-default-primary-btn" data-aos="fade-up">
                       {t("sign_up")}
                      </Link>
                      <Link to="/login" className="new-default-primary-grey-btn" data-aos="fade-up">
                        {t("sign_in")}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="demo-of-product-priority-card" data-aos="fade-up">
                  <div className="demo-of-product-priority-info">
                    <h4>{t("join_the_best")} <span>{t("crypto")}</span> {t("exchange_platform")}</h4>
                    <p>{t("step_into")} {configuration.get("configData.site_name")},
                     {t("where_premium_para")}</p>
                  </div>
                  <div className="demo-of-product-priority-icon-sec">
                    <Image
                      className="demo-of-product-priority-icon"
                      src={
                        window.location.origin + "/img/gif/best-crypto-exchange.gif"
                      }
                      type="image/png"
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default DemoOfProduct;
