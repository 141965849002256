import React, { useState } from "react";
import { Navbar, Container, Image, Nav, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
import configuration from "react-global-configuration";
import CustomLazyLoad from "../helper/CustomLazyLoad";

const HowItsWork = (props) => {

  const t = useTranslation("how_its_work")

  return (
    <>
      <div className="how-its-work-sec sm-padding">
        <Container fluid>
        <Row className="justify-content-md-center">
                <Col md={8} lg={9} className="text-center">
                    <div className="section-title">
                        <h2 data-aos="fade-up">What We Provide</h2>
                        <p data-aos="fade-up">At HERALDEX, we deliver a comprehensive range of services designed to meet your crypto Exchange needs, empowering you with the tools to succeed in the digital finance space.</p>
                    </div>
                </Col>
            </Row>
          <div className="how-its-work-box" data-aos="fade-up">
            <div className="how-its-work-card" data-aos="fade-up">
              <div className="how-its-work-icon-sec">
                <CustomLazyLoad
                  className="how-its-work-icon"
                  src={
                    window.location.origin + "/img/currency/buy-crypto.svg"
                  }
                  type="image/png"
                />
              </div>
              <div className="how-its-work-info">
                <h4>Buy Crypto Instantly</h4>
                <p>Easily convert your fiat money into various cryptocurrencies. With a few simple steps, you can purchase crypto directly using supported payment methods, all within a secure environment.
                </p>
                <div className="learn-more-btn-sec">
                  <Link to="/buy" className="learn-more-btn">
                    {t("learn_more")}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="none"
                      viewBox="0 0 23 22"
                    >
                      <path
                        stroke="#E89900"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M4.701 11.246h12.951m-4.55 4.701l4.692-4.701-4.693-4.701"
                      ></path>
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
            <div className="how-its-work-card" data-aos="fade-up">
              <div className="how-its-work-icon-sec">
                <CustomLazyLoad
                  className="how-its-work-icon"
                  src={
                    window.location.origin + "/img/currency/sell-crypto.svg"
                  }
                  type="image/png"
                />
              </div>
              <div className="how-its-work-info">
                <h4>Sell Crypto Effortlessly</h4>
                <p>Need to cash out? Convert your cryptocurrency into real money quickly and safely. We support multiple withdrawal options, so you can choose the one that’s most convenient for you.</p>
                <div className="learn-more-btn-sec">
                  <Link to="/sell" className="learn-more-btn">
                    {t("learn_more")}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="none"
                      viewBox="0 0 23 22"
                    >
                      <path
                        stroke="#E89900"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M4.701 11.246h12.951m-4.55 4.701l4.692-4.701-4.693-4.701"
                      ></path>
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
            <div className="how-its-work-card" data-aos="fade-up">
              <div className="how-its-work-icon-sec">
                <CustomLazyLoad
                  className="how-its-work-icon"
                  src={
                    window.location.origin + "/img/currency/swap-crypto.svg"
                  }
                  type="image/png"
                />
              </div>
              <div className="how-its-work-info">
                <h4>Swap Crypto Seamlessly</h4>
                <p>Seamlessly swap between different cryptocurrencies without leaving the platform. Whether you’re diversifying your portfolio or adjusting your holdings, our swap feature makes it simple and instant.</p>
                <div className="learn-more-btn-sec">
                  <Link to="/exchange" className="learn-more-btn">
                    {t("learn_more")}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="none"
                      viewBox="0 0 23 22"
                    >
                      <path
                        stroke="#E89900"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M4.701 11.246h12.951m-4.55 4.701l4.692-4.701-4.693-4.701"
                      ></path>
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
            <div className="how-its-work-card" data-aos="fade-up">
              <div className="how-its-work-icon-sec">
                <CustomLazyLoad
                  className="how-its-work-icon"
                  src={
                    window.location.origin + "/img/currency/trade-crypto.svg"
                  }
                  type="image/png"
                />
              </div>
              <div className="how-its-work-info">
                <h4>Trade Crypto Professionally</h4>
                <p>Take your crypto journey to the next level with our advanced trading platform. Benefit from real-time market data, competitive fees, and powerful trading tools to manage your crypto assets effectively.</p>
                <div className="learn-more-btn-sec">
                  <Link to="/trading" className="learn-more-btn">
                    {t("learn_more")}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="none"
                      viewBox="0 0 23 22"
                    >
                      <path
                        stroke="#E89900"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M4.701 11.246h12.951m-4.55 4.701l4.692-4.701-4.693-4.701"
                      ></path>
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default HowItsWork;
