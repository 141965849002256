import {
  getCommonInitialState,
  getCommonStart,
  getCommonSuccess,
  getCommonFailure,
} from "../utils";
import {
  BUY_TOKEN_FAILURE,
  BUY_TOKEN_START,
  BUY_TOKEN_SUCCESS,
  CREATE_KYC_APPLICANT_FAILURE,
  CREATE_KYC_APPLICANT_START,
  CREATE_KYC_APPLICANT_SUCCESS,
  GENERATE_STRIPE_PAYMENT_FAILURE,
  GENERATE_STRIPE_PAYMENT_START,
  GENERATE_STRIPE_PAYMENT_SUCCESS,
  SWAP_TOKEN_START,
  SWAP_TOKEN_SUCCESS,
  SWAP_TOKEN_FAILURE,
  SET_SWAP_DATA,
  SET_BUY_DATA,
  RESET_BUY_DATA,
  RESET_SWAP_DATA,
  REGISTER_START,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  PROFILE_START,
  PROFILE_FAILURE,
  PROFILE_SUCCESS,
  UPDATE_KYC_START,
  UPDATE_KYC_SUCCESS,
  UPDATE_KYC_FAILURE,
  UPDATE_PROFILE_START,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  STYLOPAY_PAYMENT_FAILURE,
  STYLOPAY_PAYMENT_START,
  STYLOPAY_PAYMENT_SUCCESS,
  TRANSATIONS_LIST_FAILURE,
  TRANSATIONS_LIST_START,
  TRANSATIONS_LIST_SUCCESS,
  FETCH_MORE_TRANSATIONS,
  CHANGE_PASSWORD_START,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  DELETE_ACCOUNT_START,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_FAILURE,
  ONBOARDING_START,
  ONBOARDING_SUCCESS,
  ONBOARDING_FAILURE,
  EMAIL_VERIFICATION_START,
  EMAIL_VERIFICATION_SUCCESS,
  EMAIL_VERIFICATION_FAILURE,
  EMAIL_VERIFICATION_CODE_START,
  EMAIL_VERIFICATION_CODE_SUCCESS,
  EMAIL_VERIFICATION_CODE_FAILURE,
  TWO_STEP_VERIFICATION_START,
  TWO_STEP_VERIFICATION_SUCCESS,
  TWO_STEP_VERIFICATION_FAILURE,
  FORGOT_PASSWORD_START,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  VERIFY_FORGOT_PASSWORD_START,
  VERIFY_FORGOT_PASSWORD_SUCCESS,
  VERIFY_FORGOT_PASSWORD_FAILURE,
  RESEND_OTP_START,
  RESEND_OTP_SUCCESS,
  RESEND_OTP_FAILURE,
  ENABLE_DISABLE_TWOSTEP_AUTH_START,
  ENABLE_DISABLE_TWOSTEP_AUTH_SUCCESS,
  ENABLE_DISABLE_TWOSTEP_AUTH_FAILURE,
  LOGOUT_START,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  BUSINESS_ONBOARDING_START,
  BUSINESS_ONBOARDING_SUCCESS,
  BUSINESS_ONBOARDING_FAILURE,
  BUSINESS_DOCUMENTS_INFO_START,
  BUSINESS_DOCUMENTS_INFO_SUCCESS,
  BUSINESS_DOCUMENTS_INFO_FAILURE,
  UPDATE_BUSINESS_DOCUMENT_START,
  UPDATE_BUSINESS_DOCUMENT_SUCCESS,
  UPDATE_BUSINESS_DOCUMENT_FAILURE,
  BUSINESS_DOCUMENTS_STATUS_START,
  BUSINESS_DOCUMENTS_STATUS_SUCCESS,
  BUSINESS_DOCUMENTS_STATUS_FAILURE,
} from "../actions/ActionConstant";

export const initialBuyData = {
  step: 1,
  token_type: null,
  tokens: 1,
  receiver_wallet_address: "",
  buy_via_currency: "",
  accessToken: "",
  kyc_authCode: "",
  payment_id: "",
  exchangeValue: 0,
  amountConversion: 0,
};

export const initialSwapData = {
  step: 1,
  tokens: 1,
  admin_crypto_account_id: "",
  from_currency: "",
  to_currency: "",
  file: null,
  transaction_hash: "",
};

const initialState = {
  buyData: initialBuyData,
  swapData: initialSwapData,
  generateStripe: getCommonInitialState(),
  buyToken: getCommonInitialState(),
  kycApplicant: getCommonInitialState(),
  swapToken: getCommonInitialState(),
  login: getCommonInitialState(),
  register: getCommonInitialState(),
  profile: getCommonInitialState(),
  updateProfile: getCommonInitialState(),
  updateKYC: getCommonInitialState(),
  stylopayPayment: getCommonInitialState(),
  transactions: getCommonInitialState(),
  changePassword: getCommonInitialState(),
  deleteAccount: getCommonInitialState(),
  onboarding: getCommonInitialState(),
  businessOnboarding: getCommonInitialState(),
  emailVerify: getCommonInitialState(),
  emailVerifyCode: getCommonInitialState(),
  twoStepVerification: getCommonInitialState(),
  forgotPassword: getCommonInitialState(),
  verifyForgotPassword: getCommonInitialState(),
  resendOtp: getCommonInitialState(),
  enableDisableTwoStepAuth: getCommonInitialState(),
  logout: getCommonInitialState(),
  businessDocumentsInfo: getCommonInitialState(),
  updateBusinessDocument: getCommonInitialState(),
  businessDocumentsStatus: getCommonInitialState(),
};

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case GENERATE_STRIPE_PAYMENT_START:
      return { ...state, generateStripe: getCommonStart() };
    case GENERATE_STRIPE_PAYMENT_SUCCESS:
      return { ...state, generateStripe: getCommonSuccess(action.data) };
    case GENERATE_STRIPE_PAYMENT_FAILURE:
      return { ...state, generateStripe: getCommonFailure(action.error) };

    case BUY_TOKEN_START:
      return { ...state, buyToken: getCommonStart() };
    case BUY_TOKEN_SUCCESS:
      return { ...state, buyToken: getCommonSuccess(action.data) };
    case BUY_TOKEN_FAILURE:
      return { ...state, buyToken: getCommonFailure(action.error) };

    case CREATE_KYC_APPLICANT_START:
      return { ...state, kycApplicant: getCommonStart() };
    case CREATE_KYC_APPLICANT_SUCCESS:
      return { ...state, kycApplicant: getCommonSuccess(action.data) };
    case CREATE_KYC_APPLICANT_FAILURE:
      return { ...state, kycApplicant: getCommonFailure(action.error) };

    case SWAP_TOKEN_START:
      return { ...state, swapToken: getCommonStart() };
    case SWAP_TOKEN_SUCCESS:
      return { ...state, swapToken: getCommonSuccess(action.data) };
    case SWAP_TOKEN_FAILURE:
      return { ...state, swapToken: getCommonFailure(action.error) };

    case SET_BUY_DATA:
      return { ...state, buyData: action.data };
    case RESET_BUY_DATA:
      return {
        ...state,
        buyData: {
          ...initialBuyData,
          token_type: state.buyData.token_type,
          token: state.buyData.token,
          exchangeValue: state.buyData.exchangeValue,
          amountConversion: state.buyData.amountConversion,
        },
      };
    case SET_SWAP_DATA:
      return { ...state, swapData: action.data };
    case RESET_SWAP_DATA:
      return {
        ...state,
        swapData: {
          ...initialSwapData,
          token_type: state.swapData.token_type,
          swap_token_type: state.swapData.swap_token_type,
          swap_token: state.swapData.swap_token,
          exchangeValue: state.swapData.exchangeValue,
          amountConversion: state.swapData.amountConversion,
        },
      };

    case TRANSATIONS_LIST_START:
      return {
        ...state,
        transactions: getCommonStart({
          token_transactions: [],
          total_token_transactions: 0,
        }),
      };
    case FETCH_MORE_TRANSATIONS:
      return { ...state };
    case TRANSATIONS_LIST_SUCCESS:
      return { ...state, transactions: getCommonSuccess(action.data) };
    case TRANSATIONS_LIST_FAILURE:
      return { ...state, transactions: getCommonFailure(action.error) };

    case LOGIN_START:
      return { ...state, login: getCommonStart() };
    case LOGIN_SUCCESS:
      return { ...state, login: getCommonSuccess(action.data) };
    case LOGIN_FAILURE:
      return { ...state, login: getCommonFailure(action.error) };

    case REGISTER_START:
      return { ...state, register: getCommonStart() };
    case REGISTER_SUCCESS:
      return { ...state, register: getCommonSuccess(action.data) };
    case REGISTER_FAILURE:
      return { ...state, register: getCommonFailure(action.error) };

    case PROFILE_START:
      return { ...state, profile: getCommonStart() };
    case PROFILE_SUCCESS:
      return { ...state, profile: getCommonSuccess(action.data) };
    case PROFILE_FAILURE:
      return { ...state, profile: getCommonFailure(action.error) };

    case UPDATE_KYC_START:
      return { ...state, updateKYC: getCommonStart() };
    case UPDATE_KYC_SUCCESS:
      return { ...state, updateKYC: getCommonSuccess(action.data) };
    case UPDATE_KYC_FAILURE:
      return { ...state, updateKYC: getCommonFailure(action.error) };

    case UPDATE_PROFILE_START:
      return { ...state, updateProfile: getCommonStart() };
    case UPDATE_PROFILE_SUCCESS:
      return { ...state, updateProfile: getCommonSuccess(action.data) };
    case UPDATE_PROFILE_FAILURE:
      return { ...state, updateProfile: getCommonFailure(action.error) };

    case STYLOPAY_PAYMENT_START:
      return { ...state, stylopayPayment: getCommonStart() };
    case STYLOPAY_PAYMENT_SUCCESS:
      return { ...state, stylopayPayment: getCommonSuccess(action.data) };
    case STYLOPAY_PAYMENT_FAILURE:
      return { ...state, stylopayPayment: getCommonFailure(action.error) };

    case CHANGE_PASSWORD_START:
      return { ...state, changePassword: getCommonStart() };
    case CHANGE_PASSWORD_SUCCESS:
      return { ...state, changePassword: getCommonSuccess(action.data) };
    case CHANGE_PASSWORD_FAILURE:
      return { ...state, changePassword: getCommonFailure(action.error) };

    case DELETE_ACCOUNT_START:
      return { ...state, deleteAccount: getCommonStart() };
    case DELETE_ACCOUNT_SUCCESS:
      return { ...state, deleteAccount: getCommonSuccess(action.data) };
    case DELETE_ACCOUNT_FAILURE:
      return { ...state, deleteAccount: getCommonFailure(action.error) };

    case ONBOARDING_START:
      return { ...state, onboarding: getCommonStart() };
    case ONBOARDING_SUCCESS:
      return { ...state, onboarding: getCommonSuccess(action.data) };
    case ONBOARDING_FAILURE:
      return { ...state, onboarding: getCommonFailure(action.error) };

    case BUSINESS_ONBOARDING_START:
      return { ...state, businessOnboarding: getCommonStart() };
    case BUSINESS_ONBOARDING_SUCCESS:
      return { ...state, businessOnboarding: getCommonSuccess(action.data) };
    case BUSINESS_ONBOARDING_FAILURE:
      return { ...state, businessOnboarding: getCommonFailure(action.error) };

    // BUSINESS_DOCUMENTS_INFO cases
    case BUSINESS_DOCUMENTS_INFO_START:
      return { ...state, businessDocumentsInfo: getCommonStart() };
    case BUSINESS_DOCUMENTS_INFO_SUCCESS:
      return { ...state, businessDocumentsInfo: getCommonSuccess(action.data) };
    case BUSINESS_DOCUMENTS_INFO_FAILURE:
      return { ...state, businessDocumentsInfo: getCommonFailure(action.error) };

    // UPDATE_BUSINESS_DOCUMENT cases
    case UPDATE_BUSINESS_DOCUMENT_START:
      return { ...state, updateBusinessDocument: getCommonStart() };
    case UPDATE_BUSINESS_DOCUMENT_SUCCESS:
      return { ...state, updateBusinessDocument: getCommonSuccess(action.data) };
    case UPDATE_BUSINESS_DOCUMENT_FAILURE:
      return { ...state, updateBusinessDocument: getCommonFailure(action.error) };

    // BUSINESS_DOCUMENTS_STATUS cases
    case BUSINESS_DOCUMENTS_STATUS_START:
      return { ...state, businessDocumentsStatus: getCommonStart() };
    case BUSINESS_DOCUMENTS_STATUS_SUCCESS:
      return { ...state, businessDocumentsStatus: getCommonSuccess(action.data) };
    case BUSINESS_DOCUMENTS_STATUS_FAILURE:
      return { ...state, businessDocumentsStatus: getCommonFailure(action.error) };


    case EMAIL_VERIFICATION_START:
      return { ...state, emailVerify: getCommonStart() };
    case EMAIL_VERIFICATION_SUCCESS:
      return { ...state, emailVerify: getCommonSuccess(action.data) };
    case EMAIL_VERIFICATION_FAILURE:
      return { ...state, emailVerify: getCommonFailure(action.error) };

    case EMAIL_VERIFICATION_CODE_START:
      return { ...state, emailVerifyCode: getCommonStart() };
    case EMAIL_VERIFICATION_CODE_SUCCESS:
      return {
        ...state,
        emailVerifyCode: getCommonSuccess(action.data),
      };
    case EMAIL_VERIFICATION_CODE_FAILURE:
      return {
        ...state,
        emailVerifyCode: getCommonFailure(action.error),
      };

    case TWO_STEP_VERIFICATION_START:
      return { ...state, twoStepVerification: getCommonStart() };
    case TWO_STEP_VERIFICATION_SUCCESS:
      return { ...state, twoStepVerification: getCommonSuccess(action.data) };
    case TWO_STEP_VERIFICATION_FAILURE:
      return { ...state, twoStepVerification: getCommonFailure(action.error) };

    case FORGOT_PASSWORD_START:
      return { ...state, forgotPassword: getCommonStart() };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPassword: getCommonSuccess(action.data),
      };
    case FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        forgotPassword: getCommonFailure(action.error),
      };

    case VERIFY_FORGOT_PASSWORD_START:
      return { ...state, verifyForgotPassword: getCommonStart() };
    case VERIFY_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        verifyForgotPassword: getCommonSuccess(action.data),
      };
    case VERIFY_FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        verifyForgotPassword: getCommonFailure(action.error),
      };

    case RESEND_OTP_START:
      return { ...state, resendOtp: getCommonStart() };
    case RESEND_OTP_SUCCESS:
      return {
        ...state,
        resendOtp: getCommonSuccess(action.data),
      };
    case RESEND_OTP_FAILURE:
      return {
        ...state,
        resendOtp: getCommonFailure(action.error),
      };

    case ENABLE_DISABLE_TWOSTEP_AUTH_START:
      return { ...state, enableDisableTwoStepAuth: getCommonStart() };
    case ENABLE_DISABLE_TWOSTEP_AUTH_SUCCESS:
      return {
        ...state,
        enableDisableTwoStepAuth: getCommonSuccess(action.data),
      };
    case ENABLE_DISABLE_TWOSTEP_AUTH_FAILURE:
      return {
        ...state,
        enableDisableTwoStepAuth: getCommonFailure(action.error),
      };

    case LOGOUT_START:
      return { ...state, logout: getCommonStart() };
    case LOGOUT_SUCCESS:
      return { ...state, logout: getCommonSuccess(action.data) };
    case LOGOUT_FAILURE:
      return { ...state, logout: getCommonFailure(action.error) };

    default:
      return state;
  }
};

export default UserReducer;
