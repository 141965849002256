import React, { useCallback, useEffect, useState, useRef } from "react";
import {
  Modal,
  Button,
  Form,
  Image,
  Row,
  Col,
  Nav,
  Tab,
} from "react-bootstrap";
import "../Wallet/Wallet.css";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import CommonCenterLoader from "../helper/CommonCenterLoader";
import { useDropzone } from "react-dropzone";
import {
  depositTokenStart,
  depositTokensViaBankStart,
  getAdminBankAccountStart,
  getAdminCryptoAccountStart,
  getCurrenciesListStart,
} from "../../store/actions/BuyAction";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  getErrorNotificationMessage,
  getSuccessNotificationMessage,
} from "../helper/ToastNotification";
import { useTranslation } from "react-multi-lang";
import CustomLazyLoad from "../helper/CustomLazyLoad";
import {
  fetchAllTransactionStart,
  fetchSingleWalletViewStart,
} from "../../store/actions/WalletAction";
import { Link, useParams } from "react-router-dom";
import NoDataFound from "../helper/NoDataFound";
import TransactionSuccess from "../helper/TransactionSuccess";
import { transactionsSuccess } from "../../store/actions/UserAction";
import Select from "react-select";
import { networkOption } from "../helper/NetworkOption";
import QRCode from "react-qr-code";
import ButtonStepper from "../Buy/ButtonStepper";
import Upcomingevent from "../Buy/Upcomingevent";

const DepositModal = (props) => {
  const params = useParams();
  const t = useTranslation("deposit_modal");
  const formRef = useRef(null);
  const formCardRef = useRef(null);
  const formBankRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState(false);
  const [step, setStep] = useState(1);
  const [bankstep, setBankStep] = useState(1);
  const [url, setUrl] = useState(null);
  const [fiatBankstep, setFiatBankStep] = useState(1);
  // const [demostep, setDemoStep] = useState(1);
  const wallet = useSelector((state) => state.wallet.walletData);
  const profile = useSelector((state) => state.user.profile);
  const currencies = useSelector((state) => state.buy.currencies);
  const [minAmount, setMinAmount] = useState(0.0000001);
  const [maxAmount, setMaxAmount] = useState(0);
  const [networkType, setNetworkType] = useState(null);
  const [cryptoDepositStep, setCryptoDepositStep] = useState(1);
  const [retry, setRetry] = useState(true);
  
  const [paymentType, setPaymentType] = useState(
    props.show.currency_type == "crypto" ? "card" : "bank"
  );
  const [cardNumber, setCardNumber] = useState("");
  const adminBankAccount = useSelector((state) => state.buy.adminBankAccount);
  const depositTokensViaBank = useSelector(
    (state) => state.buy.depositTokensViaBank
  );
  const [buttonStepper, setButtonStepper] = useState(false);

  const validationSchema = Yup.object().shape({
    tokens: Yup.number()
      .required(t("required"))
      .min(
        !isNaN(minAmount) && minAmount,
        t("tokens.invalid", {
          value: !isNaN(minAmount) && minAmount.toFixed(8),
        })
      )
      .max(maxAmount, t("tokens.max_invalid", { value: maxAmount })),
    transaction_hash: Yup.string().when("is_crypto", {
      is: "1",
      then: Yup.string()
        .required(t("required"))
        .min(3, t("transaction_id.invalid"))
        // .max(66, t("transaction_id.max_invalid", { value: 66 })),
    }),
    // payment_file: Yup.string().required(t("please_upload_payment_screenshot")),
  });

  const dispatch = useDispatch();
  const adminCryptoAccount = useSelector(
    (state) => state.buy.adminCryptoAccount
  );
  const [skipRender, setSkipRender] = useState(true);
  const [transactionSuccess, setTransactionSuccess] = useState(false);
  const depositToken = useSelector((state) => state.buy.depositToken);

  const [files, setFiles] = useState([]);

  const onDropRejected = useCallback((rejectedFiles) => {
    if (rejectedFiles.length > 1) {
      setErrorMessage(t("please_upload_only_one_file_at_a_time"));
    } else {
      const invalidFiles = rejectedFiles.filter(
        (file) => !["image/jpeg", "image/png", "image/jpg"].includes(file.type)
      );
      if (invalidFiles.length > 0) {
        setErrorMessage(t("invalid_file_format"));
      }
    }
  }, []);

  const onDropAccepted = () => {
    setErrorMessage("");
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDropRejected,
    onDropAccepted,
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/jpg": [],
    },
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      props.show.currency_type !== "crypto" &&
        formBankRef.current.setFieldValue("payment_file", acceptedFiles[0]);
    },
  });

  const images = files.map((file, key) => (
    <div className="preview-added-image" key={key}>
      <Button
        variant="link"
        className="preview-cancel"
        onClick={(e) => {
          e.stopPropagation();
          formBankRef.current.setFieldValue("payment_file", "");
          setFiles([]);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="#fff"
          viewBox="0 0 24 24"
        >
          <path d="M18 6a1 1 0 00-1.414 0L12 10.586 7.414 6A1 1 0 006 6a1 1 0 000 1.414L10.586 12 6 16.586A1 1 0 006 18a1 1 0 001.414 0L12 13.414 16.586 18A1 1 0 0018 18a1 1 0 000-1.414L13.414 12 18 7.414A1 1 0 0018 6z"></path>
        </svg>
      </Button>
      <CustomLazyLoad
        className="product-preview"
        key={file.name}
        src={file.preview}
        alt="image-preview"
      />
    </div>
  ));

  const handleSubmit = (values) => {
    dispatch(
      depositTokenStart({
        ...values,
        token_type: props.tokenType,
        network_type: networkType?.value ? networkType?.value : "",
      })
    );
  };

  const handleCardSubmit = (values) => {
    step == 1 && setStep(2);
    step == 2 &&
      dispatch(
        depositTokenStart({
          ...values,
          card_number: values.card_number.replace(/\s+/g, ""),
          token_type: props.tokenType,
        })
      );
  };

  useEffect(() => {
    if (
      (networkType != null && ["USDT", "USDC"].includes(props.token)) ||
      (!["USDT", "USDC"].includes(props.token) &&
        props.show.currency_type == "crypto")
    ) {
      dispatch(
        getAdminCryptoAccountStart({
          network_type: !["USDT", "USDC"].includes(props.token)
            ? props.token
            : networkType?.value, user_id: profile.data?.user_id ? profile.data?.user_id : ""
        })
      );
      // setCryptoDepositStep(2);
    }
  }, [networkType,retry]);

  useEffect(() => {
    if (
      !skipRender &&
      !adminCryptoAccount.loading &&
      Object.keys(adminCryptoAccount.data).length > 0
    ) {
      // setCryptoDepositStep(2);
    }
    setSkipRender(false);
  }, [adminCryptoAccount]);

  useEffect(() => {
    if (
      !skipRender &&
      !depositToken.loading &&
      Object.keys(depositToken.data).length > 0
    ) {
      //  props.onHide();
      setButtonStepper(true)
      setTimeout(() => {
        setUrl(depositToken.data?.deposit_transaction?.explorer_url);
        setTransactionSuccess(true);
      }, 3000);

    }
    setSkipRender(false);
  }, [depositToken]);

  function handleKeyPress(event) {
    if (!/^[0-9.]$/.test(event.key) && event.key == "e") {
      event.preventDefault();
    }
  }

  const bankSchema = Yup.object().shape({
    payment_id: Yup.string()
      .required(t("payment_id.required"))
      .matches(/^(?=.*[a-zA-Z-0-9])/, t("invalid"))
      .min(3, t("payment_id.invalid"))
      .max(66, t("transaction_id.max_invalid", { value: 66 })),
    payment_file: Yup.string().required(t("please_upload_payment_screenshot")),
    tokens: Yup.number()
      .required(t("payment_id.required"))
      .min(
        !isNaN(minAmount) && minAmount,
        t("tokens.invalid", {
          value: !isNaN(minAmount) && minAmount.toFixed(8),
        })
      ).max(maxAmount, t("tokens.max_invalid", { value: maxAmount })),
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    props.show.currency_type !== "crypto" &&
      dispatch(
        getAdminBankAccountStart({
          currency: props.token,
        })
      );
  }, []);

  // useEffect(() => {
  //   if (
  //     !skipRender &&
  //     !adminCryptoAccount.loading &&
  //     adminCryptoAccount.data &&
  //     Object.keys(adminCryptoAccount.data).length > 0
  //   ) {
  //     formRef?.current?.setFieldValue(
  //       "admin_crypto_account_id",
  //       adminCryptoAccount.data.admin_crypto_account.admin_crypto_account_id
  //     );
  //   }
  // }, [adminCryptoAccount, paymentType]);

  const onSubmit = (values) => {
    // if (files.length > 0) {

    const { card_number, cvv, expiry_month, expiry_year, ...rest } = values;
    dispatch(
      depositTokensViaBankStart({
        ...rest,
        token_type: props.tokenType,
      })
    );

    // } else {
    //   getErrorNotificationMessage(t("please_upload_payment_screenshot"));
    // }
  };

  useEffect(() => {
    if (
      !skipRender &&
      !depositTokensViaBank.loading &&
      Object.keys(depositTokensViaBank.data).length > 0
    ) {
      // props.onHide();
      setButtonStepper(true)
      setTimeout(() => {
        setUrl(depositTokensViaBank.data?.deposit_transaction?.explorer_url);
        setTransactionSuccess(true);
      }, 3000);

    }
    setSkipRender(false);
  }, [depositTokensViaBank]);

  const onHide = () => {
    setTransactionSuccess(false);
    props.onHide();
    dispatch(
      fetchSingleWalletViewStart({
        user_wallet_id: props.user_wallet_id,
      })
    );
    dispatch(
      fetchAllTransactionStart({
        user_wallet_id: props.user_wallet_id,
        skip: 0,
        take: 12,
      })
    );
  };

  const handleChange = (e) => {
    const { value } = e.target;
    const unformattedValue = value.replace(/\s+/g, "");
    if (unformattedValue.length > 16) {
      return;
    }
    const formattedValue = formatNumber(value);
    setCardNumber(formattedValue);
    if (formCardRef.current) {
      formCardRef.current.setFieldValue("card_number", formattedValue);
    }
  };

  const formatNumber = (number) => {
    return number
      .replace(/\s+/g, "")
      .split("")
      .reduce((seed, next, index) => {
        if (index !== 0 && index % 4 === 0) {
          seed += " ";
        }
        return seed + next;
      }, "");
  };

  useEffect(() => {
    if (
      !skipRender &&
      !currencies.loading &&
      Object.keys(currencies.data).length > 0
    ) {
      setMinAmount(
        parseInt(
          currencies.data?.user_commissions?.min_deposit_amount
        ) <= 0
          ? 0.00000001
          : parseInt(
            currencies.data?.user_commissions?.min_deposit_amount
          ).toFixed(8)
      );

      setMaxAmount(
        parseInt(
          currencies.data?.user_commissions?.max_deposit_amount
        ) <= 0
          ? 100000
          : parseInt(
            currencies.data?.user_commissions?.max_deposit_amount
          ).toFixed(8)
      );

    }
    setSkipRender(false);
  }, [currencies]);

  const customStylesone = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "10px",
      overflow: "hidden",
      minWidth: "100px",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "#fff!important",
      border: "1px solid #E5EAF4!important",
      borderRadius: "10px!important",
      boxShadow: "none!important",
      height: "42px",
      cursor: "pointer",
      minWidth: "auto",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#7d7d7d",
      fontSize: "0.8em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      color: "#DDE1E6!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#DDE1E6!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#50506B80",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused || state.isSelected ? "#F9C201" : "#fff",
      color: state.isFocused || state.isSelected ? "#000" : "#000",
      ":hover": {
        backgroundColor: "#F9C201",
        color: "#000",
      },
    }),
  };
  useEffect(() => {
    dispatch(getCurrenciesListStart({ user_id: profile.data?.user_id ? profile.data?.user_id : "" }));
  }, []);

  useEffect(()=> {
    if(cryptoDepositStep == 1){
      !networkType && setNetworkType(networkOption[0])
    }
  }, [cryptoDepositStep])

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="auth_deposit_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!transactionSuccess ? (
            <div className="deposit_modal_content">
              {props.show.currency_type !== "crypto" ? (
                <>
                  <div className="deposit_fiat_titles out_space_mx_1_5">
                    <h2>Deposit Tokens</h2>
                  </div>
                  <div className="deposit_fiat_bank_content ">
                    <Tab.Container id="deposit-tabs" defaultActiveKey="bank">
                      <div className="deposit_fiat_nav_list out_space_mx_1_5">
                        <Nav
                          variant="pills"
                          className=""
                          onSelect={(selectedKey) => {
                            setPaymentType(selectedKey);
                          }}
                        >
                          <Nav.Item>
                            <Nav.Link
                              eventKey="bank"
                              className="wallet_tab_btn"
                            >
                              Deposit by bank{" "}
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="card"
                              className="wallet_tab_btn"
                            >
                              Deposit by card
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </div>
                      <div className="deposit_fiat_main">
                        <Tab.Content>
                          <Tab.Pane eventKey="bank">
                            {adminBankAccount.loading ? (
                              <CommonCenterLoader />
                            ) : Object.keys(adminBankAccount.data).length >
                              0 ? (
                              <React.Fragment>
                                {fiatBankstep == 1 ? (
                                  <>
                                    {props?.token == "INR" ? (
                                      <>
                                        <div className="deposit_foreign_bank_details out_space_mx_1_5">
                                          <div className="deposit_bank_account_details over_space_2">
                                            <div className="deposit_bank_head item_line_between out_space_mx_1_5">
                                              <div className="deposit_bank_name">
                                                <div className="deposit_bank_icons">
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="22"
                                                    height="22"
                                                    enableBackground="new 0 0 512 512"
                                                    viewBox="0 0 24 24"
                                                  >
                                                    <path
                                                      d="M24 23a1 1 0 01-1 1H1a1 1 0 010-2h22a1 1 0 011 1zM.291 8.552a2.443 2.443 0 01.153-2.566 4.716 4.716 0 011.668-1.5L9.613.582a5.174 5.174 0 014.774 0l7.5 3.907a4.716 4.716 0 011.668 1.5 2.443 2.443 0 01.153 2.566A2.713 2.713 0 0121.292 10H21v8h1a1 1 0 010 2H2a1 1 0 010-2h1v-8h-.292A2.713 2.713 0 01.291 8.552zM5 18h3v-8H5zm5-8v8h4v-8zm9 0h-3v8h3zM2.063 7.625A.717.717 0 002.708 8h18.584a.717.717 0 00.645-.375.452.452 0 00-.024-.5 2.7 2.7 0 00-.949-.864l-7.5-3.907a3.176 3.176 0 00-2.926 0l-7.5 3.907a2.712 2.712 0 00-.949.865.452.452 0 00-.026.499z"
                                                      data-original="#000000"
                                                    ></path>
                                                  </svg>
                                                </div>
                                                <div className="deposit_bank_info">
                                                  <h3>
                                                    {
                                                      adminBankAccount.data
                                                        ?.admin_bank_account
                                                        ?.bank_name
                                                    }
                                                  </h3>
                                                  <h5>
                                                    {" "}
                                                    {
                                                      adminBankAccount.data
                                                        ?.admin_bank_account
                                                        ?.account_holder_name
                                                    }
                                                  </h5>
                                                </div>
                                              </div>
                                              <div className="deposit_bank_type item_flex_x_5">
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="20"
                                                  height="20"
                                                  fill="none"
                                                  viewBox="0 0 16 17"
                                                >
                                                  <path
                                                    stroke="#252525"
                                                    strokeLinecap="round"
                                                    strokeWidth="2"
                                                    d="M1.333 15.813V15a4.875 4.875 0 014.875-4.875h3.25A4.875 4.875 0 0114.333 15v.813M7.833 7.688a3.25 3.25 0 110-6.5 3.25 3.25 0 010 6.5z"
                                                  ></path>
                                                </svg>
                                                <span>
                                                  {adminBankAccount.data.admin_bank_account?.account_type
                                                    ?.charAt(0)
                                                    .toUpperCase() +
                                                    adminBankAccount.data.admin_bank_account?.account_type?.slice(
                                                      1
                                                    )}{" "}
                                                  account
                                                </span>
                                              </div>
                                            </div>
                                            <div className="deposit_bank_accout_number ">
                                              <h3>
                                                {formatNumber(
                                                  adminBankAccount.data
                                                    ?.admin_bank_account
                                                    ?.account_number
                                                )}
                                              </h3>
                                            </div>
                                          </div>
                                          <div className="deposit_bank_other_details over_space_2">
                                            <div>
                                              <div className="deposit_other_titles out_space_mx_1_5">
                                                <h5>Beneficiary</h5>
                                              </div>
                                              <div className="deposit_other_card">
                                                <div className="deposit_other_info_card">
                                                  <h4>
                                                    {" "}
                                                    {t("account_holder_name")}
                                                  </h4>
                                                  <p>
                                                    {
                                                      adminBankAccount.data
                                                        ?.admin_bank_account
                                                        ?.account_holder_name
                                                    }
                                                  </p>
                                                </div>
                                                <div className="deposit_other_info_card">
                                                  <h4> {t("ifsc_code")}</h4>
                                                  <p>
                                                    {
                                                      adminBankAccount.data
                                                        ?.admin_bank_account
                                                        ?.bank_code
                                                    }
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="deposit_bank_action">
                                          <Button
                                            className="action-btn primary w-100"
                                            onClick={() => setFiatBankStep(2)}
                                          >
                                            Continue
                                          </Button>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        {/* <div className="deposit_foreign_bank_details out_space_mx_1_5">
                                          <div className="deposit_bank_account_details over_space_2">
                                            <div className="deposit_bank_head item_line_between out_space_mx_1_5">
                                              <div className="deposit_bank_name">
                                                <div className="deposit_bank_icons">
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="22"
                                                    height="22"
                                                    enableBackground="new 0 0 512 512"
                                                    viewBox="0 0 24 24"
                                                  >
                                                    <path
                                                      d="M24 23a1 1 0 01-1 1H1a1 1 0 010-2h22a1 1 0 011 1zM.291 8.552a2.443 2.443 0 01.153-2.566 4.716 4.716 0 011.668-1.5L9.613.582a5.174 5.174 0 014.774 0l7.5 3.907a4.716 4.716 0 011.668 1.5 2.443 2.443 0 01.153 2.566A2.713 2.713 0 0121.292 10H21v8h1a1 1 0 010 2H2a1 1 0 010-2h1v-8h-.292A2.713 2.713 0 01.291 8.552zM5 18h3v-8H5zm5-8v8h4v-8zm9 0h-3v8h3zM2.063 7.625A.717.717 0 002.708 8h18.584a.717.717 0 00.645-.375.452.452 0 00-.024-.5 2.7 2.7 0 00-.949-.864l-7.5-3.907a3.176 3.176 0 00-2.926 0l-7.5 3.907a2.712 2.712 0 00-.949.865.452.452 0 00-.026.499z"
                                                      data-original="#000000"
                                                    ></path>
                                                  </svg>
                                                </div>
                                                <div className="deposit_bank_info">
                                                  {adminBankAccount.data
                                                    ?.admin_bank_account
                                                    ?.bank_name && (
                                                      <h3>
                                                        {
                                                          adminBankAccount.data
                                                            ?.admin_bank_account
                                                            ?.bank_name
                                                        }
                                                      </h3>
                                                    )}
                                                </div>
                                              </div>
                                              <div className="deposit_bank_type item_flex_x_5">
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="20"
                                                  height="20"
                                                  fill="none"
                                                  viewBox="0 0 16 17"
                                                >
                                                  <path
                                                    stroke="#252525"
                                                    strokeLinecap="round"
                                                    strokeWidth="2"
                                                    d="M1.333 15.813V15a4.875 4.875 0 014.875-4.875h3.25A4.875 4.875 0 0114.333 15v.813M7.833 7.688a3.25 3.25 0 110-6.5 3.25 3.25 0 010 6.5z"
                                                  ></path>
                                                </svg>
                                                <span>
                                                  {adminBankAccount.data
                                                    ?.admin_bank_account
                                                    ?.account_holder_name &&
                                                    adminBankAccount.data
                                                      ?.admin_bank_account
                                                      ?.account_holder_name}
                                                </span>
                                              </div>
                                            </div>
                                            <div className="deposit_bank_accout_number ">
                                              {adminBankAccount.data
                                                ?.admin_bank_account
                                                ?.account_number && (
                                                  <h3>
                                                    {formatNumber(
                                                      adminBankAccount.data
                                                        .admin_bank_account
                                                        .account_number
                                                    )}
                                                  </h3>
                                                )}
                                            </div>
                                          </div>
                                          <div className="deposit_bank_other_details over_space_2">
                                            {(adminBankAccount.data
                                              ?.admin_bank_account
                                              ?.swift_code_beneficiary_bank ||
                                              adminBankAccount.data
                                                ?.admin_bank_account
                                                ?.bank_code) && (
                                                <>
                                                  <div>
                                                    <div className="deposit_other_titles out_space_mx_1_5">
                                                      <h5>Beneficiary</h5>
                                                    </div>
                                                    <div className="deposit_other_card">
                                                      <div className="deposit_other_info_card">
                                                        {adminBankAccount.data
                                                          ?.admin_bank_accoun
                                                          ?.bank_code && (
                                                            <>
                                                              <h4>
                                                                {t("iban_no")}
                                                              </h4>
                                                              <p>
                                                                {
                                                                  adminBankAccount
                                                                    ?.data
                                                                    ?.admin_bank_account
                                                                    ?.bank_code
                                                                }
                                                              </p>
                                                            </>
                                                          )}
                                                      </div>
                                                      <div className="deposit_other_info_card">
                                                        {adminBankAccount.data
                                                          ?.admin_bank_account
                                                          ?.swift_code_beneficiary_bank && (
                                                            <>
                                                              <h4>
                                                                {t(
                                                                  "swift_code_beneficiary_bank"
                                                                )}
                                                              </h4>

                                                              <p>
                                                                {
                                                                  adminBankAccount
                                                                    ?.data
                                                                    ?.admin_bank_account
                                                                    ?.swift_code_beneficiary_bank
                                                                }
                                                              </p>
                                                            </>
                                                          )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="deposit_space_bar"></div>{" "}
                                                </>
                                              )}
                                            {(adminBankAccount.data
                                              ?.admin_bank_account
                                              ?.bank_address ||
                                              adminBankAccount.data
                                                ?.admin_bank_account
                                                ?.swift_code_correspondent_bank ||
                                              adminBankAccount.data
                                                ?.admin_bank_account
                                                ?.correspondent_account_no) && (
                                                <>
                                                  <div>
                                                    <div className="deposit_other_titles out_space_mx_1_5">
                                                      <h5>Correspondent</h5>
                                                    </div>
                                                    <div className="deposit_other_card">
                                                      {adminBankAccount.data
                                                        ?.admin_bank_account
                                                        ?.swift_code_correspondent_bank && (
                                                          <div className="deposit_other_info_card">
                                                            <h4>
                                                              {t(
                                                                "swift_code_correspondent_bank"
                                                              )}
                                                            </h4>
                                                            <p>
                                                              {
                                                                adminBankAccount
                                                                  .data
                                                                  ?.admin_bank_account
                                                                  ?.swift_code_correspondent_bank
                                                              }
                                                            </p>
                                                          </div>
                                                        )}
                                                      {adminBankAccount.data
                                                        ?.admin_bank_account
                                                        ?.correspondent_account_no && (
                                                          <div className="deposit_other_info_card">
                                                            <h4>
                                                              {t(
                                                                "correspondent_account_no"
                                                              )}
                                                            </h4>
                                                            <p>
                                                              {
                                                                adminBankAccount
                                                                  .data
                                                                  ?.admin_bank_account
                                                                  ?.correspondent_account_no
                                                              }
                                                            </p>
                                                          </div>
                                                        )}
                                                    </div>
                                                  </div>
                                                  {adminBankAccount.data
                                                    ?.admin_bank_account
                                                    ?.bank_address && (
                                                      <>
                                                        <div className="deposit_space_bar"></div>
                                                        <div className="deposit_other_info_card">
                                                          <h4>
                                                            {" "}
                                                            {t("bank_address")}
                                                          </h4>
                                                          <p>
                                                            {
                                                              adminBankAccount.data
                                                                .admin_bank_account
                                                                .bank_address
                                                            }
                                                          </p>
                                                        </div>
                                                      </>
                                                    )}
                                                </>
                                              )}
                                          </div>
                                        </div> */}
                                        <div className="bank-tab-info-wrap">
                                          <div className="bank-tab-card-info out_space_mx_1">
                                            <div className="bank-tab-card-wrap">
                                              <div className="bank-tab-card-wrap-icons">
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="30"
                                                  height="30"
                                                  enableBackground="new 0 0 512 512"
                                                  viewBox="0 0 24 24"
                                                >
                                                  <path
                                                    d="M24 23a1 1 0 01-1 1H1a1 1 0 010-2h22a1 1 0 011 1zM.291 8.552a2.443 2.443 0 01.153-2.566 4.716 4.716 0 011.668-1.5L9.613.582a5.174 5.174 0 014.774 0l7.5 3.907a4.716 4.716 0 011.668 1.5 2.443 2.443 0 01.153 2.566A2.713 2.713 0 0121.292 10H21v8h1a1 1 0 010 2H2a1 1 0 010-2h1v-8h-.292A2.713 2.713 0 01.291 8.552zM5 18h3v-8H5zm5-8v8h4v-8zm9 0h-3v8h3zM2.063 7.625A.717.717 0 002.708 8h18.584a.717.717 0 00.645-.375.452.452 0 00-.024-.5 2.7 2.7 0 00-.949-.864l-7.5-3.907a3.176 3.176 0 00-2.926 0l-7.5 3.907a2.712 2.712 0 00-.949.865.452.452 0 00-.026.499z"
                                                    data-original="#000000"
                                                  ></path>
                                                </svg>
                                              </div>
                                              <div className="bank-tab-card-wrap-info">
                                                <h4>{adminBankAccount.data.admin_bank_account.account_holder_name}</h4>
                                                <p>{adminBankAccount.data.admin_bank_account.bank_name}</p>
                                              </div>
                                            </div>
                                            <h5>{adminBankAccount.data.admin_bank_account.account_number}</h5>
                                          </div>
                                          <div className="bank-tab-card-other-info out_space_mx_1">
                                            <div className="bank-tab-card-other-info-titles">
                                              <h3>Bank Details</h3>
                                            </div>
                                            <div className="bank-tab-card-other-info-content">
                                              <div className="bank-tab-card-other-wrap">
                                                <p>Account Type</p>
                                                <h4>{adminBankAccount.data.admin_bank_account.account_type}</h4>
                                              </div>
                                              <div className="bank-tab-card-other-wrap">
                                                <p>Currency Type</p>
                                                <h4>{adminBankAccount.data.admin_bank_account.currency_type}</h4>
                                              </div>
                                              <div className="bank-tab-card-other-wrap">
                                                <p>Address</p>
                                                <h4>{adminBankAccount.data.admin_bank_account.bank_address}</h4>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="deposit_bank_action">
                                          <Button
                                            className="action-btn primary w-100"
                                            onClick={() => setFiatBankStep(2)}
                                          >
                                            Continue
                                          </Button>
                                        </div>
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <Formik
                                    initialValues={{
                                      payment_id: "",
                                      payment_file: "",
                                      is_crypto:
                                        props.show.currency_type == "crypto"
                                          ? "1"
                                          : "0",
                                      admin_bank_account_id:
                                        adminBankAccount.data.admin_bank_account
                                          .admin_bank_account_id,
                                      tokens: "",
                                    }}
                                    validationSchema={bankSchema}
                                    innerRef={formBankRef}
                                    onSubmit={onSubmit}
                                  >
                                    {({
                                      values,
                                      touched,
                                      errors,
                                      setFieldValue,
                                    }) => (
                                      <FORM>
                                        <div className="deposit_modal_form">
                                          <div className="deposit_amount_input out_space_mx_1_5">
                                            <div className="deposit_amount_field">
                                              <Field
                                                className="form-control"
                                                type="number"
                                                placeholder={`Enter amount in ${props?.token}`}
                                                name="tokens"
                                              />
                                              <ErrorMessage
                                                component={"div"}
                                                name="tokens"
                                                className="text-danger text-right"
                                              />
                                            </div>
                                          </div>
                                          <div className="deposit_address_card out_space_mx_1_5">
                                            <div className="deposit_address_field">
                                              <Form.Group className="mb-3">
                                                <Form.Label>
                                                  {t("payment_id.label")}{" "}
                                                  <span>*</span>
                                                </Form.Label>
                                                <Field
                                                  className="form-control"
                                                  type="text"
                                                  placeholder={t(
                                                    "payment_id.placeholder"
                                                  )}
                                                  name="payment_id"
                                                />
                                                <ErrorMessage
                                                  component={"div"}
                                                  name="payment_id"
                                                  className="text-danger text-right"
                                                />
                                              </Form.Group>
                                            </div>
                                            <div className="crypto_address_field">
                                              <Form.Group
                                                className="mb-3"
                                                controlId="formBasicEmail"
                                              >
                                                <Form.Label>
                                                  {t("payment_picture")}{" "}
                                                  <span>*</span>
                                                </Form.Label>
                                                <div {...getRootProps()}>
                                                  <input
                                                    name="payment_file"
                                                    {...getInputProps()}
                                                  />
                                                  {files.length <= 0 && (
                                                    <div className="add-product-upload-box">
                                                      <CustomLazyLoad
                                                        src={
                                                          window.location
                                                            .origin +
                                                          "/img/upload.png"
                                                        }
                                                        className="upload-icon"
                                                      />
                                                      <p>
                                                        {t(
                                                          "upload_your_payment_screenshot_here"
                                                        )}
                                                        <br />{" "}
                                                        <span>
                                                          {" "}
                                                          {t(
                                                            "accept_only_jpeg_jpg_png"
                                                          )}
                                                        </span>
                                                      </p>
                                                    </div>
                                                  )}
                                                  <div>{images}</div>
                                                </div>
                                                <ErrorMessage
                                                  component={"div"}
                                                  name="payment_file"
                                                  className="text-danger text-right"
                                                />
                                              </Form.Group>
                                              {errorMessage && (
                                                <p className="error-msg text-danger select-date">
                                                  {errorMessage}
                                                </p>
                                              )}
                                            </div>
                                          </div>
                                          <div className="commission-sec mb-3">
                                          <div className="commission-box">
                                            <div className="commission-card">
                                              <h5>
                                                {t("service_fees", {
                                                  value: props?.token,
                                                })}
                                                :
                                              </h5>
                                              <h5>
                                                {!isNaN(values.tokens * (currencies.data?.user_commissions?.deposit_fiat_commission / 100))
                                                  ? (values.tokens * (currencies.data?.user_commissions?.deposit_fiat_commission / 100)).toFixed(8)
                                                  : "0.00"}
                                              </h5>
                                            </div>
                                            <div className="commission-card">
                                              <h5>
                                                {t("user_receive_tokens", {
                                                  value: props?.token,
                                                })}:
                                              </h5>
                                              <h5>
                                                  {!isNaN(
                                                    values.tokens -
                                                      values.tokens *
                                                        (currencies.data?.user_commissions?.deposit_fiat_commission/
                                                          100)
                                                  )
                                                    ? (
                                                        values.tokens -
                                                        values.tokens *
                                                          (currencies.data?.user_commissions?.deposit_fiat_commission/
                                                            100)
                                                      ).toFixed(8)
                                                    : "0.00"}
                                              </h5>
                                            </div>
                                            </div>
                                          </div>
                                          {depositTokensViaBank.buttonDisable || (Object.keys(depositTokensViaBank.data).length > 0 &&
                                            buttonStepper) ? (
                                            <div className="mt-3 dark-stepper">
                                              <ButtonStepper
                                                props={depositTokensViaBank}
                                              />
                                            </div>
                                          ) : (
                                            <div className="deposit_amount_action item_flex_x_5">

                                              <Button
                                                className="action-btn secondary w-100"
                                                onClick={() => {
                                                  setFiatBankStep(
                                                    fiatBankstep - 1
                                                  );
                                                }}
                                                disabled={
                                                  depositTokensViaBank.buttonDisable
                                                }
                                              >
                                                Back
                                              </Button>
                                              <Button
                                                className="action-btn primary w-100"
                                                type="submit"
                                                disabled={
                                                  depositTokensViaBank.buttonDisable
                                                }
                                              >
                                                {t("deposit")}
                                              </Button>

                                            </div>
                                          )}
                                          <div className="deposit_bank_action">
                                            <p className="out_space_mt_1 other_links">
                                              {t(
                                                "by_continuing_you_agree_to_our"
                                              )}{" "}
                                              <Link
                                                className="cookie-txt"
                                                to="/page/privacy-agreement"
                                                target="_blank"
                                              >
                                                {t("cookie_policy")}.
                                              </Link>
                                            </p>
                                          </div>
                                        </div>
                                      </FORM>
                                    )}
                                  </Formik>
                                )}
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <div className="no-bank-details-sec exchange-btn-sec">
                                  <CustomLazyLoad
                                    src={
                                      window.location.origin +
                                      "/img/no-bank-found.png"
                                    }
                                    className="no-data-found-img"
                                  />
                                  <p>{t("no_bank_details_found")}</p>
                                  <Button
                                    className="action-btn primary w-100"
                                    onClick={() =>
                                      dispatch(
                                        getAdminBankAccountStart({
                                          currency: props.token,
                                        })
                                      )
                                    }
                                  >
                                    {t("retry")}
                                  </Button>
                                </div>
                              </React.Fragment>
                            )}
                          </Tab.Pane>
                          <Tab.Pane eventKey="card">
                            <>
                              {/* <Formik
                                initialValues={{
                                  tokens: "",
                                  is_crypto:
                                    props.show.currency_type == "crypto"
                                      ? "1"
                                      : "0",
                                  card_number: "",
                                  cvv: "",
                                  expiry_month: "",
                                  expiry_year: "",
                                }}
                                validationSchema={validationCardSchema()}
                                innerRef={formCardRef}
                                onSubmit={(values) => handleCardSubmit(values)}
                              >
                                {({
                                  touched,
                                  errors,
                                  setFieldValue,
                                  values,
                                }) => (
                                  <FORM>
                                    {step == 1 ? (
                                      <>
                                        <div className="deposit_amount_field out_space_my_3">
                                          <Form.Group
                                            controlId="formBasicEmail"
                                            className="mb-3"
                                          >
                                            <Field
                                              type="number"
                                              name="tokens"
                                              placeholder={`Enter amount in ${props?.token}`}
                                              className="form-control"
                                              onChange={(e) => {
                                                setFieldValue(
                                                  "tokens",
                                                  e.target.value
                                                );
                                              }}
                                              onKeyPress={handleKeyPress}
                                              onPaste={(event) => {
                                                event.preventDefault();
                                              }}
                                            />
                                            <ErrorMessage
                                              component={"div"}
                                              name="tokens"
                                              className="text-danger text-right"
                                            />
                                          </Form.Group>
                                        </div>
                                        <div className="deposit_amount_action ">
                                          <Button
                                            className="action-btn primary w-100"
                                            type="submit"
                                          >
                                            Continue
                                          </Button>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div className="deposit_amount_form out_space_mx_1_5">
                                          <Form.Group
                                            className="mb-3"
                                            controlId="formBasicEmail"
                                          >
                                            <Form.Label>Card Number</Form.Label>
                                            <Field
                                              className="form-control"
                                              type="text"
                                              placeholder={"Enter Card number"}
                                              name="card_number"
                                              onChange={(e) =>
                                                e.target.value.replace(
                                                  /\s+/g,
                                                  ""
                                                ).length <= 16 &&
                                                setFieldValue(
                                                  "card_number",
                                                  formatNumber(e.target.value)
                                                )
                                              }
                                            />
                                            <ErrorMessage
                                              component={"div"}
                                              name="card_number"
                                              onChange={handleChange}
                                              className="text-danger text-right"
                                            />
                                          </Form.Group>
                                          <Form.Group
                                            className="mb-3"
                                            controlId="formBasicCVV"
                                          >
                                            <Form.Label>CVV</Form.Label>
                                            <Field
                                              className="form-control"
                                              type="text"
                                              placeholder="Enter CVV"
                                              name="cvv"
                                            />
                                            <ErrorMessage
                                              component={"div"}
                                              name="cvv"
                                              className="text-danger text-right"
                                            />
                                          </Form.Group>
                                          <Row>
                                            <Col>
                                              <Form.Group controlId="formBasicExpiryMonth">
                                                <Form.Label>
                                                  Expiry Month
                                                </Form.Label>
                                                <Field
                                                  className="form-control"
                                                  type="text"
                                                  placeholder="Enter expiry month"
                                                  name="expiry_month"
                                                />
                                                <ErrorMessage
                                                  component={"div"}
                                                  name="expiry_month"
                                                  className="text-danger text-right"
                                                />
                                              </Form.Group>
                                            </Col>
                                            <Col>
                                              <Form.Group controlId="formBasicExpiryYear">
                                                <Form.Label>
                                                  Expiry Year
                                                </Form.Label>
                                                <Field
                                                  className="form-control"
                                                  type="text"
                                                  placeholder="Enter expiry year"
                                                  name="expiry_year"
                                                />
                                                <ErrorMessage
                                                  component={"div"}
                                                  name="expiry_year"
                                                  className="text-danger text-right"
                                                />
                                              </Form.Group>
                                            </Col>
                                          </Row>
                                        </div>
                                        {depositToken.buttonDisable || (Object.keys(depositToken.data).length > 0 &&
                                          buttonStepper) ? (
                                          <div className="mt-3 dark-stepper">
                                            <ButtonStepper
                                              props={depositToken}
                                            />
                                          </div>
                                        ) : (
                                          <div className="deposit_amount_action item_flex_x_5">

                                            <Button
                                              className="action-btn secondary w-100"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                setStep(step - 1);
                                              }}
                                              disabled={
                                                depositToken.buttonDisable
                                              }
                                            >
                                              Back
                                            </Button>
                                            <Button
                                              type="submit"
                                              className="action-btn primary w-100"
                                              disabled={
                                                depositToken.buttonDisable
                                              }
                                            >
                                              {t("deposit")}
                                            </Button>

                                          </div>
                                        )}
                                      </>
                                    )}
                                  </FORM>
                                )}
                              </Formik> */}
                              <Upcomingevent />
                            </>
                          </Tab.Pane>
                        </Tab.Content>
                      </div>
                    </Tab.Container>
                  </div>
                </>
              ) : cryptoDepositStep == 1 ? (
                <div className="auth-usdt-box">
                  <div className="auth_deposit_content">
                    <div className="auth-deposit-img-sec">
                      <Image
                        className="auth_2fa-sec"
                        src={
                          window.location.origin +
                          "/img/phase_1/auth/deposit-icon.svg"
                        }
                        alt="onboard"
                      />
                    </div>
                    <div className="auth_deposit_form">
                      <Form.Group className="mb-3">
                        <Form.Label>Select Network Type <span>*</span></Form.Label>
                        <Select
                          className="fillert-drop"
                          options={networkOption}
                          onChange={(select) => setNetworkType(select)}
                          value={networkType}
                          styles={customStylesone}
                          placeholder="Select Network Type"
                        />
                      </Form.Group>
                    </div>
                    <div className="auth_deposit_action item_flex_x_5">
                      <Button
                        type="button"
                        className="action-btn primary"
                        disabled={!networkType}
                        onClick={()=> setCryptoDepositStep(2)}
                      >
                        Continue
                      </Button> 
                    </div>
                  </div>
                </div>
              ) : adminCryptoAccount.loading ? (
                <CommonCenterLoader />
              ) : Object.keys(adminCryptoAccount.data).length > 0 ? (
                <>
                  <Formik
                    initialValues={{
                      tokens: "",
                      transaction_hash: "",
                      is_crypto:
                        props.show.currency_type == "crypto" ? "1" : "0",
                    }}
                    validationSchema={validationSchema}
                    innerRef={formRef}
                    onSubmit={handleSubmit}
                  >
                    {({ touched, errors, setFieldValue, values }) => (
                      <FORM>
                        <>
                          <div className="auth_deposit_content">
                            <div className="auth_deposit_avater out_space_mx_1_5">
                              <Image
                                className="auth_deposit-sec"
                                src={
                                  window.location.origin +
                                  "/img/phase_1/auth/deposit-icon.svg"
                                }
                                alt="onboard"
                              />
                            </div>
                            <div className="auth_deposit_titles out_space_mx_1_5">
                              <h2> {t("heading")}</h2>
                              <p>Please deposit to the QR code shown below</p>
                            </div>
                            <>
                              {values.is_crypto == 1 && (
                                <>
                                  <div className="auth_deposit_selected out_space_mx_1_5 item_center">
                                    <p className="auth_deposit_type">
                                      {" "}
                                      {t("selected_token")} :{" "}
                                      <span> {props.tokenType} </span>
                                    </p>
                                  </div>

                                  <div className="auth_deposit_qr_scan out_space_mx_1_5">
                                    <div className="admin-wallet-qr-frame">
                                      {adminCryptoAccount.data.admin_crypto_account
                                        .qr_code ? (
                                        <CustomLazyLoad
                                          src={
                                            adminCryptoAccount.data
                                              .admin_crypto_account.qr_code
                                          }
                                          className="auth_deposit_qr"
                                        />
                                      ) :
                                        <QRCode
                                          size={135}
                                          value={adminCryptoAccount.data?.admin_crypto_account.wallet_address}
                                          viewBox={`0 0 256 256`}
                                        />

                                      }
                                    </div>
                                  </div>

                                  {/* <div className="auth_deposit_action out_space_mx_1_5">
                                      <Button
                                        className="action-btn primary"
                                        onClick={() => setBankStep(2)}
                                      >
                                        Continue
                                      </Button>
                                    </div> */}
                                  <div className="auth_deposit_space item_center out_space_mx_1_5">
                                    <p>Or Enter the Address Manually</p>
                                  </div>
                                  <div className="auth_deposit_code">
                                    <div className="auth_deposit_code_card">
                                      <span>
                                        {" "}
                                        {
                                          adminCryptoAccount.data
                                            .admin_crypto_account.wallet_address
                                        }
                                      </span>{" "}
                                    </div>

                                    <CopyToClipboard
                                      text={
                                        adminCryptoAccount.data
                                          .admin_crypto_account.wallet_address
                                      }
                                      onCopy={() =>
                                        getSuccessNotificationMessage(
                                          t("wallet_address_copied")
                                        )
                                      }
                                    >
                                      <Button className=" action-btn overlay item_center_column p-0">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="#298BFF"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          class="icon icon-tabler icons-tabler-outline icon-tabler-copy"
                                        >
                                          <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                            fill="none"
                                          />
                                          <path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z" />
                                          <path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1" />
                                        </svg>
                                      </Button>
                                    </CopyToClipboard>
                                  </div>
                                </>
                              )}
                            </>

                            <>
                              <div className="auth_deposit_form out_space_mx_1_5">
                                <Form.Group
                                  controlId="formBasicEmail"
                                  className="mb-3"
                                >
                                  <Form.Label>
                                    Amount in {props?.token}
                                    <span>*</span>
                                  </Form.Label>
                                  <Field
                                    type="number"
                                    name="tokens"
                                    placeholder={t("tokens.placeholder")}
                                    className="form-control"
                                    onKeyPress={handleKeyPress}
                                    onPaste={(event) => {
                                      event.preventDefault();
                                    }}
                                  />
                                  <ErrorMessage
                                    component={"div"}
                                    name="tokens"
                                    className="text-danger text-right"
                                  />
                                </Form.Group>
                                <Form.Group controlId="formBasicEmail">
                                  <Form.Label>
                                    {t("transaction_id.label")}
                                    <span>*</span>
                                  </Form.Label>
                                  <Field
                                    type="text"
                                    name="transaction_hash"
                                    placeholder={t(
                                      "transaction_id.placeholder"
                                    )}
                                    className="form-control"
                                  />
                                  <ErrorMessage
                                    component={"div"}
                                    name="transaction_hash"
                                    className="text-danger text-right"
                                  />
                                </Form.Group>

                                {/* {values?.is_crypto == 1 && (
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>
                                {t("payment_picture")}
                                <span>*</span>
                              </Form.Label>
                              <div {...getRootProps()}>
                                <input
                                  name="payment_file"
                                  {...getInputProps()}
                                />
                                {files.length <= 0 && (
                                  <div className="add-product-upload-box">
                                    <CustomLazyLoad
                                      src={
                                        window.location.origin +
                                        "/img/upload.svg"
                                      }
                                      className="upload-icon"
                                    />
                                    <p>
                                      {t("upload_your_payment_screenshot_here")}
                                      <br />{" "}
                                      <span>
                                        {" "}
                                        {t("accepted_types_jpg_png")}
                                      </span>
                                    </p>
                                  </div>
                                )}
                                <div>{images}</div>
                              </div>
                              <ErrorMessage
                                component={"div"}
                                name="payment_file"
                                className="text-danger text-right"
                              />
                            </Form.Group>
                          )} */}
                                {errorMessage && (
                                  <p className="error-msg text-danger select-date">
                                    {errorMessage}
                                  </p>
                                )}
                              </div>
                              <div className="commission-sec mb-3">
                                <div className="commission-box">
                                  <div className="commission-card">
                                    <h5>
                                      {t("service_fees", {
                                        value: props?.token,
                                      })}
                                      :
                                    </h5>
                                    <h5>
                                        {!isNaN(
                                          values.tokens *
                                            (currencies.data?.user_commissions?.deposit_commission /
                                              100)
                                        )
                                          ? (
                                              values.tokens *
                                              (currencies.data?.user_commissions?.deposit_commission /
                                                100)
                                            ).toFixed(8)
                                          : "0.00"}
                                    </h5>
                                  </div>
                                  <div className="commission-card">
                                    <h5>
                                      {t("user_receive_tokens", {
                                        value: props?.token,
                                      })}:
                                    </h5>
                                    <h5>
                                        {!isNaN(
                                          values.tokens -
                                            values.tokens *
                                              (currencies.data?.user_commissions?.deposit_commission/
                                                100)
                                        )
                                          ? (
                                              values.tokens -
                                              values.tokens *
                                                (currencies.data?.user_commissions?.deposit_commission/
                                                  100)
                                            ).toFixed(8)
                                          : "0.00"}
                                    </h5>
                                  </div>
                                </div>
                              </div>
                              {depositToken.buttonDisable || (Object.keys(depositToken.data).length > 0 &&
                                buttonStepper) ? (
                                // <div className="withdraw-stepper">
                                <div className="mt-3 dark-stepper">
                                  <ButtonStepper
                                    props={depositToken}
                                  />
                                </div>
                              ) : (
                                <div className="auth_deposit_action item_flex_x_5">

                                  {props.show.currency_type == "crypto" && (
                                    <Button
                                      className="action-btn secondary"
                                      onClick={() => {
                                        ["USDT", "USDC"].includes(props.token)
                                          ? setCryptoDepositStep(1)
                                          : props.onHide();
                                      }}
                                      disabled={depositToken.buttonDisable}
                                    >
                                      {["USDT", "USDC"].includes(props.token)
                                        ? "Back"
                                        : "Cancel"}
                                    </Button>
                                  )}
                                  {props.show.currency_type !== "crypto" && (
                                    <Button
                                      className="action-btn secondary"
                                      onClick={() => setStep(step + 1)}
                                    >
                                      Continue
                                    </Button>
                                  )}
                                  {props.show.currency_type == "crypto" && (
                                    <Button
                                      type="submit"
                                      className="action-btn primary"
                                      disabled={depositToken.buttonDisable}
                                    >
                                      {t("deposit")}
                                    </Button>
                                  )}

                                </div>
                              )}
                            </>
                          </div>
                        </>
                      </FORM>
                    )}
                  </Formik>
                  
                </>
              ) : (
                <>
                  <NoDataFound message="No, Admin Account Not Found" />
                </>
              )}
            </div>
          ) : (
            <TransactionSuccess
              message={`Amount Deposit ${paymentType == "bank" ? "Initiated" : "Deposited"} Successfully`}
              onHide={onHide}
              url={url}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DepositModal;
