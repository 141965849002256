import React, { useState } from "react";
import { Container, Image, Nav, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-multi-lang";
import CustomLazyLoad from "../helper/CustomLazyLoad";

const BestCryptoExchange = (props) => {

  const t = useTranslation("best_crypto");

  return (
    <>
      <div className="best-crypto-wrapped sm-padding">
        <Container fluid>
          <Row>
          <Col md={6} lg={6} xl={5}>
              <div className="best-crypto-step-title ">
              <h2 data-aos="fade-zoom-in">How Does <span>HeraldEX Work?</span></h2>
              <p>Using Herald Exchange is simple and straightforward. Follow these easy steps to start buying, selling, swapping, and trading cryptocurrencies</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6} lg={6} xl={4} className="resp-marg-btm-xs mb-lg">
              <div className="best-card-full-frame outer-border" data-aos="fade-right">
              <div className="best-crypto-step-card fade-color-one" >
                <div className="num-bold" ><span>01</span></div>
                <div className="best-card-head">
                <CustomLazyLoad
                    className="best-crypto-exchange-icon"
                    src={
                      window.location.origin + "/img/how-its-work/signup-icon.svg"
                    }
                    type="image/png"
                  />
                <h4>Sign Up</h4>
                </div>
                <p>Create your free account in just a few minutes. Simply click the “Sign Up” button, provide your details, and verify your email. Once registered, you’re ready to explore all the features of Herald Exchange.</p>
              </div>
              </div>
            </Col>
            <Col md={6} lg={6} xl={4} className="resp-marg-btm-xs mb-lg">

            <div className="best-card-full-frame outer-border" data-aos="fade-right">
              <div className="best-crypto-step-card fade-color-two">
                <div className="num-bold"> <span> 02 </span></div>
                <div className="best-card-head">
                <CustomLazyLoad
                    className="best-crypto-exchange-icon"
                    src={
                      window.location.origin + "/img/how-its-work/deposit-icon.svg"
                    }
                    type="image/png"
                  />
                <h4>Deposit Funds</h4>
                </div>
                <p>After signing up, deposit real money into your account using one of our supported payment methods. We offer multiple options to make sure funding your account is fast and easy.</p>
              </div>
              </div>
            </Col>
            <Col md={6} lg={6} xl={4} className="resp-marg-btm-xs mb-lg">
            <div className="best-card-full-frame outer-border" data-aos="fade-right">
              <div className="best-crypto-step-card fade-color-three">
                <div className="num-bold"><span> 03 </span></div>
                <div className="best-card-head">
                <CustomLazyLoad
                    className="best-crypto-exchange-icon"
                    src={
                      window.location.origin + "/img/how-its-work/buy-icon.svg"
                    }
                    type="image/png"
                  />
                <h4>Buy Crypto</h4>
                </div>
                <p>With your account funded, you can instantly purchase a variety of cryptocurrencies. Choose the currency you want to buy, enter the amount, and confirm the transaction. Your crypto will be available in your wallet immediately.</p>
              </div>
              </div>
            </Col>
            <Col md={6} lg={6} xl={4} className="resp-marg-btm-xs">
            <div className="best-card-full-frame outer-border" data-aos="fade-right">
              <div className="best-crypto-step-card fade-color-four">
                <div className="num-bold"><span> 04 </span></div>
                <div className="best-card-head">
                <CustomLazyLoad
                    className="best-crypto-exchange-icon"
                    src={
                      window.location.origin + "/img/how-its-work/sell-icon.svg"
                    }
                    type="image/png"
                  />
                <h4>Sell Crypto for Real Money</h4>
                </div>
                <p>Want to cash out? It’s just as easy. Select the cryptocurrency you want to sell, choose your preferred payout method, and convert your crypto into real money. Your funds will be transferred to your linked account quickly and securely.</p>
              </div>
              </div>
            </Col>
            <Col md={6} lg={6} xl={4} className="resp-marg-btm-xs">
            <div className="best-card-full-frame outer-border" data-aos="fade-right">
              <div className="best-crypto-step-card fade-color-five">
                <div className="num-bold"><span> 05 </span></div>
                <div className="best-card-head">
                <CustomLazyLoad
                    className="best-crypto-exchange-icon"
                    src={
                      window.location.origin + "/img/how-its-work/swap-icon.svg"
                    }
                    type="image/png"
                  />
                <h4>Swap Cryptocurrencies</h4>
                </div>
                <p>Looking to switch between different cryptocurrencies? Use our swap feature to instantly exchange one crypto for another. Just pick the currencies you want to swap, confirm the amount, and you’re good to go.</p>
              </div>
              </div>
            </Col>
            <Col md={6} lg={6} xl={4} className="resp-marg-btm-xs">
            <div className="best-card-full-frame outer-border" data-aos="fade-right">
              <div className="best-crypto-step-card fade-color-six">
                <div className="num-bold"><span> 06 </span></div>
                <div className="best-card-head">
                <CustomLazyLoad
                    className="best-crypto-exchange-icon"
                    src={
                      window.location.origin + "/img/how-its-work/trade-icon.svg"
                    }
                    type="image/png"
                  />
                <h4>Trade Crypto</h4>
                </div>
                <p>For those who want to take their crypto journey to the next level, explore our trading platform. Buy, sell, and manage your crypto assets in real time with advanced trading tools and competitive fees.</p>
              </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default BestCryptoExchange;
