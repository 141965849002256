import { all, call, put, takeLatest, select } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from "../index";
import {
  BUY_TOKEN_START,
  CREATE_KYC_APPLICANT_START,
  GENERATE_STRIPE_PAYMENT_START,
  LOGIN_START,
  PROFILE_START,
  REGISTER_START,
  SWAP_TOKEN_START,
  UPDATE_KYC_START,
  UPDATE_PROFILE_START,
  STYLOPAY_PAYMENT_START,
  TRANSATIONS_LIST_START,
  FETCH_MORE_TRANSATIONS,
  CHANGE_PASSWORD_START,
  DELETE_ACCOUNT_START,
  ONBOARDING_START,
  EMAIL_VERIFICATION_START,
  EMAIL_VERIFICATION_CODE_START,
  TWO_STEP_VERIFICATION_START,
  FORGOT_PASSWORD_START,
  VERIFY_FORGOT_PASSWORD_START,
  RESEND_OTP_START,
  ENABLE_DISABLE_TWOSTEP_AUTH_START,
  LOGOUT_START,
  BUSINESS_ONBOARDING_START,
  BUSINESS_DOCUMENTS_INFO_START,
  UPDATE_BUSINESS_DOCUMENT_START,
  BUSINESS_DOCUMENTS_STATUS_START,
} from "../actions/ActionConstant";
import {
  generateStripePaymentSuccess,
  generateStripePaymentFailure,
  buyTokenSuccess,
  buyTokenFailure,
  createKycApplicantSuccess,
  createKycApplicantFailure,
  swapTokenSuccess,
  swapTokenFailure,
  updateBuyData,
  updateSwapData,
  registerSuccess,
  registerFailure,
  loginSuccess,
  loginFailure,
  profileSuccess,
  profileFailure,
  updateKYCSuccess,
  updateKYCFailure,
  profileUpdateSuccess,
  profileUpdateFailure,
  stylopayPaymentSuccess,
  stylopayPaymentFailure,
  transactionsSuccess,
  transactionsFailure,
  changePasswordSuccess,
  changePasswordFailure,
  deleteAccountSuccess,
  deleteAccountFailure,
  onboardingSuccess,
  onboardingFailure,
  emailVerifySuccess,
  emailVerifyFailure,
  emailVerifyCodeSuccess,
  emailVerifyCodeFailure,
  twoStepVerificationSuccess,
  twoStepVerificationFailure,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  verifyForgotPasswordSuccess,
  verifyForgotPasswordFailure,
  resendOtpSuccess,
  resendOtpFailure,
  enableDisableTwoStepAuthSuccess,
  enableDisableTwoStepAuthFailure,
  logoutSuccess,
  logoutFailure,
  businessOnboardingSuccess,
  businessOnboardingFailure,
  businessDocumentsInfoSuccess,
  businessDocumentsInfoFailure,
  updateBusinessDocumentSuccess,
  updateBusinessDocumentFailure,
  businessDocumentsStatusSuccess,
  businessDocumentsStatusFailure,
} from "../actions/UserAction";

function* generateStripePaymentAPI(action) {
  yield getCommonSaga({
    apiUrl: "generate_stripe_payment",
    payload: action.data,
    success: generateStripePaymentSuccess,
    failure: generateStripePaymentFailure,
  });
}

function* buyTokenAPI(action) {
  const buyData = yield select((state) => state.user.buyData);
  yield getCommonSaga({
    apiUrl: "user_buy_tokens",
    payload: action.data,
    successCallback: true,
    failureCallback: true,
    success: (data) => {
      store.dispatch(buyTokenSuccess(data));
      store.dispatch(
        updateBuyData({ ...buyData, token: 0.1, token_type: null, step: 6 })
      );
    },
    failure: (error) => {
      store.dispatch(buyTokenFailure(error));
      store.dispatch(
        updateBuyData({ ...buyData, token: 0.1, token_type: null, step: 6 })
      );
    },
  });
}

function* createKycApplicantAPI(action) {
  yield getCommonSaga({
    apiUrl: "update_kyc",
    payload: action.data,
    success: createKycApplicantSuccess,
    failure: createKycApplicantFailure,
  });
}

function* swapTokenAPI(action) {
  const swapData = yield select((state) => state.user.swapData);
  yield getCommonSaga({
    apiUrl: "user_swap_tokens",
    payload: action.data,
    successCallback: true,
    failureCallback: true,
    success: (data) => {
      store.dispatch(swapTokenSuccess(data));
      store.dispatch(
        updateSwapData({
          ...swapData,
          swap_token: 0.1,
          swap_token_type: null,
          token_type: null,
          step: 6,
        })
      );
    },
    failure: (error) => {
      store.dispatch(swapTokenFailure(error));
      store.dispatch(
        updateSwapData({
          ...swapData,
          swap_token: 0.1,
          swap_token_type: null,
          token_type: null,
          step: 6,
        })
      );
    },
  });
}

// Auth
function* userRegisterAPI(action) {
  yield getCommonSaga({
    apiUrl: "register",
    payload: {
      ...action.data,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    success: registerSuccess,
    failure: registerFailure,
  });
}

function* userLoginAPI(action) {
  yield getCommonSaga({
    apiUrl: "login",
    payload: {
      ...action.data,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    successCallback: true,
    success: (data) => {
      store.dispatch(loginSuccess(data));
      if (
        data.user.is_2fa_enabled == 0 &&
        data.user["2fa_setup"] == 1 &&
        data.user.email_status == 1
      ) {
        store.dispatch(profileSuccess(data.user));
        if (action.payload?.remember) {
          localStorage.setItem("token", data.access_token);
          localStorage.setItem("api_key", data.api_key);
          localStorage.setItem("salt_key", data.salt_key);
          localStorage.setItem("userId", data.user.user_id);
        } else {
          sessionStorage.setItem("token", data.access_token);
          sessionStorage.setItem("api_key", data.api_key);
          sessionStorage.setItem("salt_key", data.salt_key);
          sessionStorage.setItem("userId", data.user.user_id);
        }
      }
    },
    failure: loginFailure,
  });
}

function* profileAPI(action) {
  yield getCommonSaga({
    apiUrl: "profile",
    payload: action.data,
    successCallback: true,
    success: (data) => {
      store.dispatch(profileSuccess(data.user));
      localStorage.setItem("api_key", data.user.api_key);
      localStorage.setItem("salt_key", data.user.salt_key);
    },
    failure: profileFailure,
  });
}

function* updateProfileAPI(action) {
  yield getCommonSaga({
    apiUrl: "update_profile",
    payload: action.data,
    successCallback: true,
    success: (data) => {
      store.dispatch(profileUpdateSuccess(data.user));
      store.dispatch(profileSuccess(data.user));
    },
    failure: profileUpdateFailure,
  });
}

function* updateKYCAPI(action) {
  yield getCommonSaga({
    apiUrl: "update_kyc",
    payload: action.data,
    successNotify: false,
    successCallback: true,
    success: (data) => {
      store.dispatch(updateKYCSuccess(data));
      store.dispatch(profileSuccess(data.user));
    },
    failure: updateKYCFailure,
  });
}

function* stylopayPaymentAPI(action) {
  yield getCommonSaga({
    apiUrl: "token_payment_by_stylopay",
    payload: action.data,
    success: stylopayPaymentSuccess,
    failure: stylopayPaymentFailure,
  });
}

function* transactionsAPI(action) {
  const transactionsList = yield select(
    (state) => state.user.transactions.data
  );
  yield getCommonSaga({
    apiUrl: "token_transactions_list",
    payload: action.data,
    successNotify: false,
    successCallback: true,
    success: (data) => {
      store.dispatch(
        transactionsSuccess({
          token_transactions: [
            ...transactionsList.token_transactions,
            ...data.token_transactions,
          ],
          total_token_transactions: data.total_token_transactions,
        })
      );
    },
    failure: transactionsFailure,
  });
}

function* changePasswordAPI(action) {
  yield getCommonSaga({
    apiUrl: "change_password",
    payload: action.data,
    successCallback: true,
    success: (data) => store.dispatch(changePasswordSuccess({ success: true })),
    failure: changePasswordFailure,
  });
}

function* deleteAccountAPI(action) {
  yield getCommonSaga({
    apiUrl: "delete_account",
    payload: action.data,
    success: (data) => store.dispatch(deleteAccountSuccess({ data: data })),
    failure: deleteAccountFailure,
  });
}

function* onboardingAPI(action) {
  yield getCommonSaga({
    apiUrl: "onboarding",
    payload: action.data,
    successCallback: true,
    success: (data) => {
      store.dispatch(onboardingSuccess({ data: data }))
      store.dispatch(profileSuccess(data.user));
    },
    failure: onboardingFailure,
  });
}

function* businessOnboardingAPI(action) {
  yield getCommonSaga({
    apiUrl: "business_onboarding",
    payload: action.data,
    successCallback: true,
    success: (data) => {
      store.dispatch(businessOnboardingSuccess({ data: data }))
      store.dispatch(profileSuccess(data.user));
    },
    failure: businessOnboardingFailure,
  });
}

function* businessDocumentsInfoAPI(action) {
  yield getCommonSaga({
    apiUrl: "business_documents",
    payload: action.data,
    success: businessDocumentsInfoSuccess,
    failure: businessDocumentsInfoFailure,
  });
}

function* updateBusinessDocumentAPI(action) {
  yield getCommonSaga({
    apiUrl: "update_business_document",
    payload: action.data,
    successCallback: true,
    success: (data) => {
      store.dispatch(updateBusinessDocumentSuccess(data));
      store.dispatch(businessDocumentsInfoSuccess(data));
    },
    failure: updateBusinessDocumentFailure,
  });
}

function* businessDocumentsStatusAPI(action) {
  yield getCommonSaga({
    apiUrl: "business_documents_status",
    payload: action.data,
    successNotify: false,
    successCallback: true,
    errorNotify: false,
    success: (data) => {
      store.dispatch(businessDocumentsStatusSuccess(data));
      store.dispatch(profileSuccess(data.user));
    },
    failure: businessDocumentsStatusFailure,
  });
}

function* emailVerifyAPI(action) {
  yield getCommonSaga({
    apiUrl: "verify_email",
    payload: action.data,
    success: emailVerifySuccess,
    failure: emailVerifyFailure,
  });
}

function* emailVerifyCodeAPI(action) {
  yield getCommonSaga({
    apiUrl: "resend_email_verification_code",
    payload: action.data,
    success: emailVerifyCodeSuccess,
    failure: emailVerifyCodeFailure,
  });
}

function* twoStepVerificationAPI(action) {
  yield getCommonSaga({
    apiUrl: "verify_2fa_code",
    payload: action.data,
    successCallback: true,
    success: (data) => {
      store.dispatch(twoStepVerificationSuccess(data));
      store.dispatch(profileSuccess(data.user));
      if (action.payload?.remember) {
        localStorage.setItem("token", data.access_token);
        localStorage.setItem("api_key", data.api_key);
        localStorage.setItem("salt_key", data.salt_key);
        localStorage.setItem("userId", data.user.user_id);
      } else {
        sessionStorage.setItem("token", data.access_token);
        sessionStorage.setItem("api_key", data.api_key);
        sessionStorage.setItem("salt_key", data.salt_key);
        sessionStorage.setItem("userId", data.user.user_id);
      }
    },
    failure: twoStepVerificationFailure,
  });
}

function* forgotPasswordAPI(action) {
  yield getCommonSaga({
    apiUrl: "forgot_password",
    payload: action.data,
    successNotify: true,
    successCallback: true,
    success: (data) => {
      store.dispatch(forgotPasswordSuccess({ success: true }));
    },
    failure: forgotPasswordFailure,
  });
}

function* verifyForgotPasswordAPI(action) {
  yield getCommonSaga({
    apiUrl: "reset_password",
    payload: action.data,
    successNotify: true,
    successCallback: true,
    success: (data) => {
      store.dispatch(verifyForgotPasswordSuccess({ success: true }));
    },
    failure: verifyForgotPasswordFailure,
  });
}

function* resendOtpAPI(action) {
  yield getCommonSaga({
    apiUrl: "resend_email_verification_code", // dummy end point
    payload: action.data,
    success: resendOtpSuccess,
    failure: resendOtpFailure,
  });
}

function* enableDisableTwoStepAuthAPI(action) {
  yield getCommonSaga({
    apiUrl: "enable_disable_2fa",
    payload: action.data,
    successCallback: true,
    success: (data) => {
      store.dispatch(enableDisableTwoStepAuthSuccess(data));
      store.dispatch(profileSuccess(data.user));
    },
    failure: enableDisableTwoStepAuthFailure,
  });
}

function* logoutAPI(action) {
  yield getCommonSaga({
    apiUrl: "logout",
    successCallback: true,
    successNotify: true,
    payload: action.data,
    success: (data) => {
      store.dispatch(logoutSuccess({ success: true }));
    },
    failure: logoutFailure,
  });
}

export default function* UserSaga() {
  yield all([
    yield takeLatest(GENERATE_STRIPE_PAYMENT_START, generateStripePaymentAPI),
    yield takeLatest(BUY_TOKEN_START, buyTokenAPI),
    yield takeLatest(CREATE_KYC_APPLICANT_START, createKycApplicantAPI),
    yield takeLatest(SWAP_TOKEN_START, swapTokenAPI),
    yield takeLatest(REGISTER_START, userRegisterAPI),
    yield takeLatest(LOGIN_START, userLoginAPI),
    yield takeLatest(PROFILE_START, profileAPI),
    yield takeLatest(UPDATE_PROFILE_START, updateProfileAPI),
    yield takeLatest(UPDATE_KYC_START, updateKYCAPI),
    yield takeLatest(STYLOPAY_PAYMENT_START, stylopayPaymentAPI),
    yield takeLatest(TRANSATIONS_LIST_START, transactionsAPI),
    yield takeLatest(FETCH_MORE_TRANSATIONS, transactionsAPI),
    yield takeLatest(CHANGE_PASSWORD_START, changePasswordAPI),
    yield takeLatest(DELETE_ACCOUNT_START, deleteAccountAPI),
    yield takeLatest(ONBOARDING_START, onboardingAPI),
    yield takeLatest(BUSINESS_ONBOARDING_START, businessOnboardingAPI),
    yield takeLatest(BUSINESS_DOCUMENTS_INFO_START, businessDocumentsInfoAPI),
    yield takeLatest(UPDATE_BUSINESS_DOCUMENT_START, updateBusinessDocumentAPI),
    yield takeLatest(BUSINESS_DOCUMENTS_STATUS_START, businessDocumentsStatusAPI),
    yield takeLatest(EMAIL_VERIFICATION_START, emailVerifyAPI),
    yield takeLatest(EMAIL_VERIFICATION_CODE_START, emailVerifyCodeAPI),
    yield takeLatest(TWO_STEP_VERIFICATION_START, twoStepVerificationAPI),
    yield takeLatest(FORGOT_PASSWORD_START, forgotPasswordAPI),
    yield takeLatest(VERIFY_FORGOT_PASSWORD_START, verifyForgotPasswordAPI),
    yield takeLatest(RESEND_OTP_START, resendOtpAPI),
    yield takeLatest(ENABLE_DISABLE_TWOSTEP_AUTH_START, enableDisableTwoStepAuthAPI),
    yield takeLatest(LOGOUT_START, logoutAPI),
  ]);
}
