import {
  getCommonInitialState,
  getCommonStart,
  getCommonSuccess,
  getCommonFailure,
} from "../utils";
import {
  GET_ADMIN_BANK_ACCOUNT_FAILURE,
  GET_ADMIN_BANK_ACCOUNT_START,
  GET_ADMIN_BANK_ACCOUNT_SUCCESS,
  SET_TOKEN_BUY_DATA,
  TOKEN_BUY_FAILURE,
  TOKEN_BUY_START,
  TOKEN_BUY_SUCCESS,
  RESET_TOKEN_BUY_DATA,
  TOKEN_BUY_TRANSACTIONS_LIST_START,
  TOKEN_BUY_TRANSACTIONS_LIST_SUCCESS,
  TOKEN_BUY_TRANSACTIONS_LIST_FAILURE,
  MORE_TOKEN_BUY_TRANSACTIONS_LIST_START,
  WITHDRAWAL_REQUEST_FAILURE,
  WITHDRAWAL_REQUEST_START,
  WITHDRAWAL_REQUEST_SUCCESS,
  GET_ADMIN_CRYPTO_ACCOUNT_FAILURE,
  GET_ADMIN_CRYPTO_ACCOUNT_START,
  GET_ADMIN_CRYPTO_ACCOUNT_SUCCESS,
  DEPOSIT_TOKEN_FAILURE,
  DEPOSIT_TOKEN_START,
  DEPOSIT_TOKEN_SUCCESS,
  TOKEN_SWAP_FAILURE,
  TOKEN_SWAP_START,
  TOKEN_SWAP_SUCCESS,
  GET_CURRENCIES_LIST_START,
  GET_CURRENCIES_LIST_SUCCESS,
  GET_CURRENCIES_LIST_FAILURE,
  TOKEN_DEPOSIT_TRANSACTIONS_LIST_START,
  TOKEN_DEPOSIT_TRANSACTIONS_LIST_SUCCESS,
  TOKEN_DEPOSIT_TRANSACTIONS_LIST_FAILURE,
  MORE_TOKEN_DEPOSIT_TRANSACTIONS_LIST_START,
  TOKEN_SWAP_TRANSACTIONS_LIST_START,
  TOKEN_SWAP_TRANSACTIONS_LIST_SUCCESS,
  TOKEN_SWAP_TRANSACTIONS_LIST_FAILURE,
  MORE_TOKEN_SWAP_TRANSACTIONS_LIST_START,
  GET_ADMIN_WALLET_START,
  GET_ADMIN_WALLET_SUCCESS,
  GET_ADMIN_WALLET_FAILURE,
  SELL_TOKEN_START,
  SELL_TOKEN_SUCCESS,
  SELL_TOKEN_FAILURE,
  SET_TOKEN_SELL_DATA,
  RESET_TOKEN_SELL_DATA,
  SELL_TOKEN_TRANSACTIONS_LIST_START,
  SELL_TOKEN_TRANSACTIONS_LIST_SUCCESS,
  SELL_TOKEN_TRANSACTIONS_LIST_FAILURE,
  SELL_TOKEN_MORE_TRANSACTIONS_LIST_START,
  BUY_TOKENS_EXPORT_START,
  BUY_TOKENS_EXPORT_SUCCESS,
  BUY_TOKENS_EXPORT_FAILURE,
  SELL_TOKENS_EXPORT_START,
  SELL_TOKENS_EXPORT_SUCCESS,
  SELL_TOKENS_EXPORT_FAILURE,
  DEPOSIT_TOKENS_EXPORT_START,
  DEPOSIT_TOKENS_EXPORT_SUCCESS,
  DEPOSIT_TOKENS_EXPORT_FAILURE,
  SWAP_TOKENS_EXPORT_START,
  SWAP_TOKENS_EXPORT_SUCCESS,
  SWAP_TOKENS_EXPORT_FAILURE,
  BUY_TOKENS_VIEW_START,
  BUY_TOKENS_VIEW_SUCCESS,
  BUY_TOKENS_VIEW_FAILURE,
  SELL_TOKENS_VIEW_START,
  SELL_TOKENS_VIEW_SUCCESS,
  SELL_TOKENS_VIEW_FAILURE,
  DEPOSIT_TOKENS_VIEW_START,
  DEPOSIT_TOKENS_VIEW_SUCCESS,
  DEPOSIT_TOKENS_VIEW_FAILURE,
  SWAP_TOKENS_VIEW_START,
  SWAP_TOKENS_VIEW_SUCCESS,
  SWAP_TOKENS_VIEW_FAILURE,
  CARD_TRANSACTIONS_START,
  CARD_TRANSACTIONS_SUCCESS,
  CARD_TRANSACTIONS_FAILURE,
  WALLET_PAYMENTS_EXPORT_START,
  WALLET_PAYMENTS_EXPORT_SUCCESS,
  WALLET_PAYMENTS_EXPORT_FAILURE,
  USER_WITHDRAWALS_EXPORT_START,
  USER_WITHDRAWALS_EXPORT_SUCCESS,
  USER_WITHDRAWALS_EXPORT_FAILURE,
  GENERATE_QUOTE_START,
  GENERATE_QUOTE_SUCCESS,
  GENERATE_QUOTE_FAILURE,
  WITHDRAWAL_STATUS_START,
  WITHDRAWAL_STATUS_SUCCESS,
  WITHDRAWAL_STATUS_FAILURE,
  BUY_TOKENS_VIA_WALLET_START,
  BUY_TOKENS_VIA_WALLET_SUCCESS,
  BUY_TOKENS_VIA_WALLET_FAILURE,
  DEPOSIT_TOKENS_VIA_BANK_START,
  DEPOSIT_TOKENS_VIA_BANK_SUCCESS,
  DEPOSIT_TOKENS_VIA_BANK_FAILURE,
  CRYPTO_WITHDRAWAL_REQUEST_START,
  CRYPTO_WITHDRAWAL_REQUEST_SUCCESS,
  CRYPTO_WITHDRAWAL_REQUEST_FAILURE,
  GET_SWAP_WALLETS_LIST_START,
  GET_SWAP_WALLETS_LIST_SUCCESS,
  GET_SWAP_WALLETS_LIST_FAILURE,
  CRYPTO_CURRENCY,
  GET_CURRENCY_PAIR_LIST_START,
  GET_CURRENCY_PAIR_LIST_SUCCESS,
  GET_CURRENCY_PAIR_LIST_FAILURE,
  TOKEN_TRADE_TRANSACTION_START,
  TOKEN_TRADE_TRANSACTION_SUCCESS,
  TOKEN_TRADE_TRANSACTION_FAILURE,
  TOKEN_TRADE_TRANSACTION_LIST_START,
  TOKEN_TRADE_TRANSACTION_LIST_SUCCESS,
  TOKEN_TRADE_TRANSACTION_LIST_FAILURE,
  CURRENCY_PAIR_START,
  EXPORT_TRADE_TRANSACTION_START,
  EXPORT_TRADE_TRANSACTION_SUCCESS,
  EXPORT_TRADE_TRANSACTION_FAILURE,
  SINGLE_TRADE_TRANSACTION_START,
  SINGLE_TRADE_TRANSACTION_SUCCESS,
  SINGLE_TRADE_TRANSACTION_FAILURE,
  EVENT_KEY_START,
  SINGLE_WITHDRAW_TRANSACTION_START,
  SINGLE_WITHDRAW_TRANSACTION_SUCCESS,
  SINGLE_WITHDRAW_TRANSACTION_FAILURE,
  GET_SWAP_CURRENCIES_START,
  GET_SWAP_CURRENCIES_SUCCESS,
  GET_SWAP_CURRENCIES_FAILURE,
  SET_TOKEN_EXCHANGE_DATA,
  RESET_TOKEN_EXCHANGE_DATA,
  EXCHANGE_CRYPTO_START,
  EXCHANGE_CRYPTO_SUCCESS,
  EXCHANGE_CRYPTO_FAILURE,
  GET_FOREX_EXCHANGE_RATE_START,
  GET_FOREX_EXCHANGE_RATE_SUCCESS,
  GET_FOREX_EXCHANGE_RATE_FAILURE,
  GET_FOREX_SUPPORTED_CURRENCIES_START,
  GET_FOREX_SUPPORTED_CURRENCIES_SUCCESS,
  GET_FOREX_SUPPORTED_CURRENCIES_FAILURE,
  EXCHANGE_TOKEN_TRANSACTIONS_LIST_START,
  EXCHANGE_TOKEN_TRANSACTIONS_LIST_SUCCESS,
  EXCHANGE_TOKEN_TRANSACTIONS_LIST_FAILURE,
  EXCHANGE_TOKEN_MORE_TRANSACTIONS_LIST_START,
  EXCHANGE_TOKENS_VIEW_START,
  EXCHANGE_TOKENS_VIEW_SUCCESS,
  EXCHANGE_TOKENS_VIEW_FAILURE,
  EXCHANGE_TOKENS_EXPORT_START,
  EXCHANGE_TOKENS_EXPORT_SUCCESS,
  EXCHANGE_TOKENS_EXPORT_FAILURE,
  GET_SUPPORTED_COUNTRIES_START,
  GET_SUPPORTED_COUNTRIES_FAILURE,
  GET_SUPPORTED_COUNTRIES_SUCCESS,
  PURPOSE_OF_PAYMENT_START,
  PURPOSE_OF_PAYMENT_FAILURE,
  PURPOSE_OF_PAYMENT_SUCCESS
} from "../actions/ActionConstant";

export const initialBuyData = {
  step: 1,
  token_type: "",
  from_amount: 1,
  minAmount: 0.0000001,
  buy_via_currency: "",
  payment_id: "",
  maxAmount:1,
  amountConversion: 0,
  payment_file: null,
  forex_exchange_rate: "",
  forex_source_currency: "",
  crypto_exchange_rate: "",
  crypto_source_currency: "",
  to_currency_conversion_value:0
};

export const initialExchangeData = {
  step: 1,
  from_amount: 1,
  to_amount: 0,
  receiver_wallet_address:"",
  admin_crypto_account_id: "",
  minAmount: 0.0000001,
  maxAmount:1,
  from_currency: "",
  to_currency: "",
  transaction_hash: "",
  from_exchange_rate: "",
  from_source_currency: "",
  to_exchange_rate: "",
  to_source_currency: "",
  from_network_type:"",
  to_network_type: "",
  to_currency_conversion_value:0
};

export const initialSwapData = {
  step: 1,
  from_amount: 1,
  admin_crypto_account_id: "",
  minAmount: 0.0000001,
  from_currency: "",
  to_currency: "",
  maxAmount:1,
  // file: null,
  transaction_hash: "",
  from_exchange_rate: "",
  from_source_currency: "",
  to_exchange_rate: "",
  to_source_currency: "",
  sell_type: 1,
  network_type: "",
  to_currency_conversion_value:0
};

const initialState = {
  tokenExchangeData: initialExchangeData,
  tokenBuyData: initialBuyData,
  adminBankAccount: getCommonInitialState(),
  tokenBuy: getCommonInitialState(),
  tokenBuyTransactionsList: getCommonInitialState(),
  tokenDepositTransactionsList: getCommonInitialState(),
  tokenSwapTransactionsList: getCommonInitialState(),
  withdrawalRequest: getCommonInitialState(),
  adminCryptoAccount: getCommonInitialState(),
  depositToken: getCommonInitialState(),
  tokenSwap: getCommonInitialState(),
  currencies: getCommonInitialState(),
  tokenSellData: initialSwapData,
  adminWallet: getCommonInitialState(),
  tokenSell: getCommonInitialState(),
  sellTokenTransactionsList: getCommonInitialState(),
  exchangeTokenTransactionsList: getCommonInitialState(),
  buyTokensExport: getCommonInitialState(),
  sellTokensExport: getCommonInitialState(),
  depsoitTokensExport: getCommonInitialState(),
  swapTokensExport: getCommonInitialState(),
  buyTokensView: getCommonInitialState(),
  sellTokensView: getCommonInitialState(),
  exchangeTokensView: getCommonInitialState(),
  exchangeTokensExport: getCommonInitialState(),
  depositTokensView: getCommonInitialState(),
  swapTokensView: getCommonInitialState(),
  cardTransactions: getCommonInitialState(),
  walletPaymentsExport: getCommonInitialState(),
  userWithdrawalsExport: getCommonInitialState(),
  generateQuote: getCommonInitialState(),
  withdrawalStatus: getCommonInitialState(),
  buyTokensViaWallet: getCommonInitialState(),
  depositTokensViaBank: getCommonInitialState(),
  cryptoWithdrawalRequest: getCommonInitialState(),
  getSwapWalletsList: getCommonInitialState(),
  cryptoCurrency: [],
  currencyPairList: getCommonInitialState(),
  tokenTradeTransaction: getCommonInitialState(),
  tokenTradeTransactionList: getCommonInitialState(),
  exportTradeTransaction: getCommonInitialState(),
  singleTradeTransaction: getCommonInitialState(),
  singleWithdrawTransaction: getCommonInitialState(),
  getSwapCurrencies: getCommonInitialState(),
  exchangeCrypto: getCommonInitialState(),
  currencyPair: null,
  eventKey: "first",
  forexExchangeRate: getCommonInitialState(),
  forexSupportedCurrencies: getCommonInitialState(),
  supportedCountries: getCommonInitialState(),
  purposeOfPayment: getCommonInitialState(),
};

const BuyReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ADMIN_BANK_ACCOUNT_START:
      return { ...state, adminBankAccount: getCommonStart() };
    case GET_ADMIN_BANK_ACCOUNT_SUCCESS:
      return { ...state, adminBankAccount: getCommonSuccess(action.data) };
    case GET_ADMIN_BANK_ACCOUNT_FAILURE:
      return { ...state, adminBankAccount: getCommonFailure(action.error) };

    case TOKEN_BUY_START:
      return { ...state, tokenBuy: getCommonStart() };
    case TOKEN_BUY_SUCCESS:
      return { ...state, tokenBuy: getCommonSuccess(action.data) };
    case TOKEN_BUY_FAILURE:
      return { ...state, tokenBuy: getCommonFailure(action.error) };

    case SET_TOKEN_BUY_DATA:
      return { ...state, tokenBuyData: action.data };
    case RESET_TOKEN_BUY_DATA:
      return { ...state, tokenBuyData: initialBuyData };

    case TOKEN_BUY_TRANSACTIONS_LIST_START:
      return { ...state, tokenBuyTransactionsList: getCommonStart() };
    case TOKEN_BUY_TRANSACTIONS_LIST_SUCCESS:
      return {
        ...state,
        tokenBuyTransactionsList: getCommonSuccess(action.data),
      };
    case TOKEN_BUY_TRANSACTIONS_LIST_FAILURE:
      return {
        ...state,
        tokenBuyTransactionsList: getCommonFailure(action.error),
      };
    case MORE_TOKEN_BUY_TRANSACTIONS_LIST_START:
      return { ...state };

    case WITHDRAWAL_REQUEST_START:
      return { ...state, withdrawalRequest: getCommonStart() };
    case WITHDRAWAL_REQUEST_SUCCESS:
      return { ...state, withdrawalRequest: getCommonSuccess(action.data) };
    case WITHDRAWAL_REQUEST_FAILURE:
      return { ...state, withdrawalRequest: getCommonFailure(action.error) };

    case GET_ADMIN_CRYPTO_ACCOUNT_START:
      return { ...state, adminCryptoAccount: getCommonStart() };
    case GET_ADMIN_CRYPTO_ACCOUNT_SUCCESS:
      return { ...state, adminCryptoAccount: getCommonSuccess(action.data) };
    case GET_ADMIN_CRYPTO_ACCOUNT_FAILURE:
      return { ...state, adminCryptoAccount: getCommonFailure(action.error) };

    case DEPOSIT_TOKEN_START:
      return { ...state, depositToken: getCommonStart() };
    case DEPOSIT_TOKEN_SUCCESS:
      return { ...state, depositToken: getCommonSuccess(action.data) };
    case DEPOSIT_TOKEN_FAILURE:
      return { ...state, depositToken: getCommonFailure(action.error) };

    case TOKEN_DEPOSIT_TRANSACTIONS_LIST_START:
      return { ...state, tokenDepositTransactionsList: getCommonStart() };
    case TOKEN_DEPOSIT_TRANSACTIONS_LIST_SUCCESS:
      return {
        ...state,
        tokenDepositTransactionsList: getCommonSuccess(action.data),
      };
    case TOKEN_DEPOSIT_TRANSACTIONS_LIST_FAILURE:
      return {
        ...state,
        tokenDepositTransactionsList: getCommonFailure(action.error),
      };
    case MORE_TOKEN_DEPOSIT_TRANSACTIONS_LIST_START:
      return { ...state };

    case TOKEN_SWAP_START:
      return { ...state, tokenSwap: getCommonStart() };
    case TOKEN_SWAP_SUCCESS:
      return { ...state, tokenSwap: getCommonSuccess(action.data) };
    case TOKEN_SWAP_FAILURE:
      return { ...state, tokenSwap: getCommonFailure(action.error) };

    case GET_CURRENCIES_LIST_START:
      return { ...state, currencies: getCommonStart() };
    case GET_CURRENCIES_LIST_SUCCESS:
      return { ...state, currencies: getCommonSuccess(action.data) };
    case GET_CURRENCIES_LIST_FAILURE:
      return { ...state, currencies: getCommonFailure(action.error) };

    case TOKEN_SWAP_TRANSACTIONS_LIST_START:
      return { ...state, tokenSwapTransactionsList: getCommonStart() };
    case TOKEN_SWAP_TRANSACTIONS_LIST_SUCCESS:
      return {
        ...state,
        tokenSwapTransactionsList: getCommonSuccess(action.data),
      };
    case TOKEN_SWAP_TRANSACTIONS_LIST_FAILURE:
      return {
        ...state,
        tokenSwapTransactionsList: getCommonFailure(action.error),
      };
    case MORE_TOKEN_SWAP_TRANSACTIONS_LIST_START:
      return { ...state };

    case SET_TOKEN_SELL_DATA:
      return { ...state, tokenSellData: action.data };
    case RESET_TOKEN_SELL_DATA:
      return { ...state, tokenSellData: initialSwapData };

    case GET_ADMIN_WALLET_START:
      return { ...state, adminWallet: getCommonStart() };
    case GET_ADMIN_WALLET_SUCCESS:
      return { ...state, adminWallet: getCommonSuccess(action.data) };
    case GET_ADMIN_WALLET_FAILURE:
      return { ...state, adminWallet: getCommonFailure(action.error) };

    case SELL_TOKEN_START:
      return { ...state, tokenSell: getCommonStart() };
    case SELL_TOKEN_SUCCESS:
      return { ...state, tokenSell: getCommonSuccess(action.data) };
    case SELL_TOKEN_FAILURE:
      return { ...state, tokenSell: getCommonFailure(action.error) };

    case SELL_TOKEN_TRANSACTIONS_LIST_START:
      return { ...state, sellTokenTransactionsList: getCommonStart() };
    case SELL_TOKEN_TRANSACTIONS_LIST_SUCCESS:
      return {
        ...state,
        sellTokenTransactionsList: getCommonSuccess(action.data),
      };
    case SELL_TOKEN_TRANSACTIONS_LIST_FAILURE:
      return {
        ...state,
        sellTokenTransactionsList: getCommonFailure(action.error),
      };
    case SELL_TOKEN_MORE_TRANSACTIONS_LIST_START:
      return { ...state };

    case EXCHANGE_TOKEN_TRANSACTIONS_LIST_START:
      return { ...state, exchangeTokenTransactionsList: getCommonStart() };
    case EXCHANGE_TOKEN_TRANSACTIONS_LIST_SUCCESS:
      return {
        ...state,
        exchangeTokenTransactionsList: getCommonSuccess(action.data),
      };
    case EXCHANGE_TOKEN_TRANSACTIONS_LIST_FAILURE:
      return {
        ...state,
        exchangeTokenTransactionsList: getCommonFailure(action.error),
      };
    case EXCHANGE_TOKEN_MORE_TRANSACTIONS_LIST_START:
      return { ...state };

    case BUY_TOKENS_EXPORT_START:
      return { ...state, buyTokensExport: getCommonStart() };
    case BUY_TOKENS_EXPORT_SUCCESS:
      return { ...state, buyTokensExport: getCommonSuccess(action.data) };
    case BUY_TOKENS_EXPORT_FAILURE:
      return { ...state, buyTokensExport: getCommonFailure(action.error) };

    case SELL_TOKENS_EXPORT_START:
      return { ...state, sellTokensExport: getCommonStart() };
    case SELL_TOKENS_EXPORT_SUCCESS:
      return { ...state, sellTokensExport: getCommonSuccess(action.data) };
    case SELL_TOKENS_EXPORT_FAILURE:
      return { ...state, sellTokensExport: getCommonFailure(action.error) };

    case DEPOSIT_TOKENS_EXPORT_START:
      return { ...state, depositTokensExport: getCommonStart() };
    case DEPOSIT_TOKENS_EXPORT_SUCCESS:
      return { ...state, depsoitTokensExport: getCommonSuccess(action.data) };
    case DEPOSIT_TOKENS_EXPORT_FAILURE:
      return { ...state, depsoitTokensExport: getCommonFailure(action.error) };

    case SWAP_TOKENS_EXPORT_START:
      return { ...state, swapTokensExport: getCommonStart() };
    case SWAP_TOKENS_EXPORT_SUCCESS:
      return { ...state, swapTokensExport: getCommonSuccess(action.data) };
    case SWAP_TOKENS_EXPORT_FAILURE:
      return { ...state, swapTokensExport: getCommonFailure(action.error) };

    case BUY_TOKENS_VIEW_START:
      return { ...state, buyTokensView: getCommonStart() };
    case BUY_TOKENS_VIEW_SUCCESS:
      return { ...state, buyTokensView: getCommonSuccess(action.data) };
    case BUY_TOKENS_VIEW_FAILURE:
      return { ...state, buyTokensView: getCommonFailure(action.error) };

    case SELL_TOKENS_VIEW_START:
      return { ...state, sellTokensView: getCommonStart() };
    case SELL_TOKENS_VIEW_SUCCESS:
      return { ...state, sellTokensView: getCommonSuccess(action.data) };
    case SELL_TOKENS_VIEW_FAILURE:
      return { ...state, sellTokensView: getCommonFailure(action.error) };

    case EXCHANGE_TOKENS_VIEW_START:
      return { ...state, exchangeTokensView: getCommonStart() };
    case EXCHANGE_TOKENS_VIEW_SUCCESS:
      return { ...state, exchangeTokensView: getCommonSuccess(action.data) };
    case EXCHANGE_TOKENS_VIEW_FAILURE:
      return { ...state, exchangeTokensView: getCommonFailure(action.error) };

    case EXCHANGE_TOKENS_EXPORT_START:
      return { ...state, exchangeTokensExport: getCommonStart() };
    case EXCHANGE_TOKENS_EXPORT_SUCCESS:
      return { ...state, exchangeTokensExport: getCommonSuccess(action.data) };
    case EXCHANGE_TOKENS_EXPORT_FAILURE:
      return { ...state, exchangeTokensExport: getCommonFailure(action.error) };
  

    case DEPOSIT_TOKENS_VIEW_START:
      return { ...state, depositTokensView: getCommonStart() };
    case DEPOSIT_TOKENS_VIEW_SUCCESS:
      return { ...state, depositTokensView: getCommonSuccess(action.data) };
    case DEPOSIT_TOKENS_VIEW_FAILURE:
      return { ...state, depositTokensView: getCommonFailure(action.error) };

    case SWAP_TOKENS_VIEW_START:
      return { ...state, swapTokensView: getCommonStart() };
    case SWAP_TOKENS_VIEW_SUCCESS:
      return { ...state, swapTokensView: getCommonSuccess(action.data) };
    case SWAP_TOKENS_VIEW_FAILURE:
      return { ...state, swapTokensView: getCommonFailure(action.error) };

    case CARD_TRANSACTIONS_START:
      return { ...state, cardTransactions: getCommonStart() };
    case CARD_TRANSACTIONS_SUCCESS:
      return { ...state, cardTransactions: getCommonSuccess(action.data) };
    case CARD_TRANSACTIONS_FAILURE:
      return { ...state, cardTransactions: getCommonFailure(action.error) };

    case WALLET_PAYMENTS_EXPORT_START:
      return { ...state, walletPaymentsExport: getCommonStart() };
    case WALLET_PAYMENTS_EXPORT_SUCCESS:
      return { ...state, walletPaymentsExport: getCommonSuccess(action.data) };
    case WALLET_PAYMENTS_EXPORT_FAILURE:
      return { ...state, walletPaymentsExport: getCommonFailure(action.error) };

    case USER_WITHDRAWALS_EXPORT_START:
      return { ...state, userWithdrawalsExport: getCommonStart() };
    case USER_WITHDRAWALS_EXPORT_SUCCESS:
      return { ...state, userWithdrawalsExport: getCommonSuccess(action.data) };
    case USER_WITHDRAWALS_EXPORT_FAILURE:
      return {
        ...state,
        userWithdrawalsExport: getCommonFailure(action.error),
      };

    case GENERATE_QUOTE_START:
      return { ...state, generateQuote: getCommonStart() };
    case GENERATE_QUOTE_SUCCESS:
      return { ...state, generateQuote: getCommonSuccess(action.data) };
    case GENERATE_QUOTE_FAILURE:
      return { ...state, generateQuote: getCommonFailure(action.error) };

    case WITHDRAWAL_STATUS_START:
      return { ...state, withdrawalStatus: getCommonStart() };
    case WITHDRAWAL_STATUS_SUCCESS:
      return { ...state, withdrawalStatus: getCommonSuccess(action.data) };
    case WITHDRAWAL_STATUS_FAILURE:
      return { ...state, withdrawalStatus: getCommonFailure(action.error) };

    case BUY_TOKENS_VIA_WALLET_START:
      return { ...state, buyTokensViaWallet: getCommonStart() };
    case BUY_TOKENS_VIA_WALLET_SUCCESS:
      return { ...state, buyTokensViaWallet: getCommonSuccess(action.data) };
    case BUY_TOKENS_VIA_WALLET_FAILURE:
      return { ...state, buyTokensViaWallet: getCommonFailure(action.error) };

    case DEPOSIT_TOKENS_VIA_BANK_START:
      return { ...state, depositTokensViaBank: getCommonStart() };
    case DEPOSIT_TOKENS_VIA_BANK_SUCCESS:
      return { ...state, depositTokensViaBank: getCommonSuccess(action.data) };
    case DEPOSIT_TOKENS_VIA_BANK_FAILURE:
      return { ...state, depositTokensViaBank: getCommonFailure(action.error) };

    case CRYPTO_WITHDRAWAL_REQUEST_START:
      return { ...state, cryptoWithdrawalRequest: getCommonStart() };
    case CRYPTO_WITHDRAWAL_REQUEST_SUCCESS:
      return {
        ...state,
        cryptoWithdrawalRequest: getCommonSuccess(action.data),
      };
    case CRYPTO_WITHDRAWAL_REQUEST_FAILURE:
      return {
        ...state,
        cryptoWithdrawalRequest: getCommonFailure(action.error),
      };

    case GET_SWAP_WALLETS_LIST_START:
      return { ...state, getSwapWalletsList: getCommonStart() };
    case GET_SWAP_WALLETS_LIST_SUCCESS:
      return {
        ...state,
        getSwapWalletsList: getCommonSuccess(action.data),
      };
    case GET_SWAP_WALLETS_LIST_FAILURE:
      return {
        ...state,
        getSwapWalletsList: getCommonFailure(action.error),
      };

    case GET_CURRENCY_PAIR_LIST_START:
      return { ...state, currencyPairList: getCommonStart() };
    case GET_CURRENCY_PAIR_LIST_SUCCESS:
      return {
        ...state,
        currencyPairList: getCommonSuccess(action.data),
      };
    case GET_CURRENCY_PAIR_LIST_FAILURE:
      return {
        ...state,
        currencyPairList: getCommonFailure(action.error),
      };

    case TOKEN_TRADE_TRANSACTION_START:
      return { ...state, tokenTradeTransaction: getCommonStart() };
    case TOKEN_TRADE_TRANSACTION_SUCCESS:
      return {
        ...state,
        tokenTradeTransaction: getCommonSuccess(action.data),
      };
    case TOKEN_TRADE_TRANSACTION_FAILURE:
      return {
        ...state,
        tokenTradeTransaction: getCommonFailure(action.error),
      };

    case TOKEN_TRADE_TRANSACTION_LIST_START:
      return { ...state, tokenTradeTransactionList: getCommonStart() };
    case TOKEN_TRADE_TRANSACTION_LIST_SUCCESS:
      return {
        ...state,
        tokenTradeTransactionList: getCommonSuccess(action.data),
      };
    case TOKEN_TRADE_TRANSACTION_LIST_FAILURE:
      return {
        ...state,
        tokenTradeTransactionList: getCommonFailure(action.error),
      };
    case CRYPTO_CURRENCY:
      return { ...state, cryptoCurrency: action.data };

    case CURRENCY_PAIR_START:
      return {
        ...state,
        currencyPair: action.data,
      };

    case EXPORT_TRADE_TRANSACTION_START:
      return { ...state, exportTradeTransaction: getCommonStart() };
    case EXPORT_TRADE_TRANSACTION_SUCCESS:
      return {
        ...state,
        exportTradeTransaction: getCommonSuccess(action.data),
      };
    case EXPORT_TRADE_TRANSACTION_FAILURE:
      return {
        ...state,
        exportTradeTransaction: getCommonFailure(action.error),
      };

    case SINGLE_TRADE_TRANSACTION_START:
      return { ...state, singleTradeTransaction: getCommonStart() };
    case SINGLE_TRADE_TRANSACTION_SUCCESS:
      return {
        ...state,
        singleTradeTransaction: getCommonSuccess(action.data),
      };
    case SINGLE_TRADE_TRANSACTION_FAILURE:
      return {
        ...state,
        singleTradeTransaction: getCommonFailure(action.error),
      };

    case EVENT_KEY_START:
      return {
        ...state,
        eventKey: action.data,
      };

    case SINGLE_WITHDRAW_TRANSACTION_START:
      return { ...state, singleWithdrawTransaction: getCommonStart() };
    case SINGLE_WITHDRAW_TRANSACTION_SUCCESS:
      return {
        ...state,
        singleWithdrawTransaction: getCommonSuccess(action.data),
      };
    case SINGLE_WITHDRAW_TRANSACTION_FAILURE:
      return {
        ...state,
        singleWithdrawTransaction: getCommonFailure(action.error),
      };

    case GET_SWAP_CURRENCIES_START:
      return { ...state, getSwapCurrencies: getCommonStart() };
    case GET_SWAP_CURRENCIES_SUCCESS:
      return {
        ...state,
        getSwapCurrencies: getCommonSuccess(action.data),
      };
    case GET_SWAP_CURRENCIES_FAILURE:
      return {
        ...state,
        getSwapCurrencies: getCommonFailure(action.error),
      };
    
      case SET_TOKEN_EXCHANGE_DATA:
        return { ...state, tokenExchangeData: action.data };
      case RESET_TOKEN_EXCHANGE_DATA:
      return { ...state, tokenExchangeData: initialExchangeData };
    
      case EXCHANGE_CRYPTO_START:
        return { ...state, exchangeCrypto: getCommonStart() };
      case EXCHANGE_CRYPTO_SUCCESS:
        return {
          ...state,
          exchangeCrypto: getCommonSuccess(action.data),
        };
      case EXCHANGE_CRYPTO_FAILURE:
        return {
          ...state,
          exchangeCrypto: getCommonFailure(action.error),
        };

        case GET_FOREX_EXCHANGE_RATE_START:
          return { ...state, forexExchangeRate: getCommonStart() };
        case GET_FOREX_EXCHANGE_RATE_SUCCESS:
          return {
            ...state,
            forexExchangeRate: getCommonSuccess(action.data),
          };
        case GET_FOREX_EXCHANGE_RATE_FAILURE:
          return {
            ...state,
            forexExchangeRate: getCommonFailure(action.error),
          };

          case GET_FOREX_SUPPORTED_CURRENCIES_START:
          return { ...state, forexSupportedCurrencies: getCommonStart() };
        case GET_FOREX_SUPPORTED_CURRENCIES_SUCCESS:
          return {
            ...state,
            forexSupportedCurrencies: getCommonSuccess(action.data),
          };
        case GET_FOREX_SUPPORTED_CURRENCIES_FAILURE:
          return {
            ...state,
            forexSupportedCurrencies: getCommonFailure(action.error),
          };

          case GET_SUPPORTED_COUNTRIES_START:
          return { ...state, supportedCountries: getCommonStart() };
        case GET_SUPPORTED_COUNTRIES_SUCCESS:
          return {
            ...state,
            supportedCountries: getCommonSuccess(action.data),
          };
        case GET_SUPPORTED_COUNTRIES_FAILURE:
          return {
            ...state,
            supportedCountries: getCommonFailure(action.error),
          };

          case PURPOSE_OF_PAYMENT_START:
            return { ...state, purposeOfPayment: getCommonStart() };
          case PURPOSE_OF_PAYMENT_SUCCESS:
            return {
              ...state,
              purposeOfPayment: getCommonSuccess(action.data),
            };
          case PURPOSE_OF_PAYMENT_FAILURE:
            return {
              ...state,
              purposeOfPayment: getCommonFailure(action.error),
            };

    default:
      return state;
  }
};

export default BuyReducer;
