import React, { useState, useEffect } from "react";
import { Image, Button, Form } from "react-bootstrap";
import "../NewLanding/HomeBanner.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { setTokenBuyData } from "../../store/actions/BuyAction";
import { getCurrenciesListStart } from "../../store/actions/BuyAction";
import { getErrorNotificationMessage } from "../helper/ToastNotification";
import KYCUpdateModal from "../KYC/KYCUpdateModal";
import {
  createKycApplicantStart,
  profileStart,
} from "../../store/actions/UserAction";
import CustomLazyLoad from "../helper/CustomLazyLoad";
import { Checkbox, Switch } from "pretty-checkbox-react";
import configuration from "react-global-configuration";
import { getCryptoCurrency } from "../../store/actions/BuyAction";
import { fetchWalletDetailsStart } from "../../store/actions/WalletAction";
import NoDataFound from "../helper/NoDataFound";

let exchangeValue;
const BuyCryptoForm = ({ redirect = false }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const t = useTranslation("buy_crypto_form");
  const tokenBuyData = useSelector((state) => state.buy.tokenBuyData);
  const currencies = useSelector((state) => state.buy.currencies);
  const profile = useSelector((state) => state.user.profile);
  const kycApplicant = useSelector((state) => state.user.kycApplicant);
  const kycStatusUpdate = useSelector((state) => state.user.updateKYC);
  const [skipRender, setSkipRender] = useState(true);
  const [toCurrencyValue, setToCurrencyValue] = useState(0);
  const updateTokenBuyData = (data) => dispatch(setTokenBuyData(data));
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [forexOptions, setForexOptions] = useState([]);
  const [selectedCrypto, setSelectedCrypto] = useState(null);
  const [selectedForex, setSelectedForex] = useState(null);
  const [kyc, setKyc] = useState(false);
  const [cryptoCurrencyList, setCryptoCurrencyList] = useState([]);
  const wallet = useSelector((state) => state.wallet.walletData);
  const [rangeValue, setRangeValue] = useState(0);
  const [walletBalance, setWalletBalance] = useState(0);
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("token") || sessionStorage.getItem("token")
      ? true
      : false
  );

  const closeKycModal = () => {
    setKyc(false);
  };

  useEffect(() => {
    dispatch(getCurrenciesListStart({ user_id: profile.data?.user_id ? profile.data?.user_id : "" }));
  }, []);



  const onCryptoChange = (selectedOption) => {
    const crypto = currencies.data.crypto_currencies.find(
      (crypto) => crypto.id == selectedOption.value
    );
    updateTokenBuyData({
      ...tokenBuyData,
      crypto_exchange_rate: crypto.exchange_rate,
      crypto_source_currency: crypto.source_currency,
      token_type: selectedOption.label,
    });
    setSelectedCrypto(selectedOption);
  };

  const onCryptoTokenChange = (value) => {
    if (Number(value) == value) {
      updateTokenBuyData({
        ...tokenBuyData,
        from_amount: value,
      });
    }
  };

  const onFiatChange = (selectedOption) => {
    const forex = currencies.data.buy_forex_currencies.find(
      (forex) => forex.id == selectedOption.value
    );
    if (Object.keys(wallet.data).length > 0) {
      setWalletBalance(
        Math.round((wallet.data?.user_wallets.find(
          (data) => data?.currency_code == selectedOption.label
        )?.remaining_original) * 100) / 100

      );
      const percentage =
        wallet.data?.user_wallets.find(
          (data) => data?.currency_code == selectedOption.label
        )?.remaining_original > 0 &&
        (parseFloat(tokenBuyData.from_amount) /
          wallet.data?.user_wallets.find(
            (data) => data?.currency_code == selectedOption.label
          )?.remaining_original) *
        100;

      setRangeValue(
        isNaN(percentage) ? 0 : Math.min(percentage, 100).toFixed(2)
      );
    }
    updateTokenBuyData({
      ...tokenBuyData,
      minAmount:
        parseInt(
          currencies.data?.user_commissions?.min_buy_amount
        ) <= 0
          ? 0.0000001
          : parseInt(
            currencies.data?.user_commissions?.min_buy_amount
          ).toFixed(8),
      maxAmount:
        parseInt(
          currencies.data?.user_commissions?.max_buy_amount
        ) <= 0
          ? 100000
          : parseInt(
            currencies.data?.user_commissions?.max_buy_amount
          ).toFixed(8),
      forex_exchange_rate: forex.exchange_rate,
      forex_source_currency: forex.source_currency,
      buy_via_currency: selectedOption.label,
      buy_commission: forex.buy_commission,
    });
    setSelectedForex(selectedOption);
  };

  const onComplete = () => {
    if (
      (!sessionStorage.getItem("userId") || !sessionStorage.getItem("token")) &&
      (!localStorage.getItem("userId") || !localStorage.getItem("token"))
    ) {
      navigate("/login");
    } else if (
      Object.keys(profile.data).length > 0 &&
      ((profile.data.user_type == 1 && profile.data.kyc_verified == 0) || (profile.data.user_type == 2 && profile.data.kyb_status != 3))
    ) {
      profile.data.user_type == 1 ? dispatch(createKycApplicantStart()) : navigate("/kyb-verification");
    } else if (redirect && !tokenBuyData.from_amount) {
      if (tokenBuyData.step == 6) {
        updateTokenBuyData({
          ...tokenBuyData,
          from_amount: tokenBuyData.from_amount,
          token_type: tokenBuyData.token_type,
          buy_via_currency: tokenBuyData.buy_via_currency,
          to_currency_conversion_value: toCurrencyValue,
        });
      }
      navigate("/buy");
    } else if (redirect && tokenBuyData.from_amount) {
      updateTokenBuyData({ ...tokenBuyData, step: 2, to_currency_conversion_value: toCurrencyValue, });
      navigate("/buy");
    } else {
      tokenBuyData.from_amount > 0
        ? updateTokenBuyData({ ...tokenBuyData, step: tokenBuyData.step + 1, to_currency_conversion_value: toCurrencyValue, })
        : getErrorNotificationMessage("Please enter valid amount");
    }
  };

  useEffect(() => {
    if (
      !currencies.loading &&
      Object.keys(currencies.data).length > 0
    ) {
      currencies.data?.crypto_currencies?.map(
        (currency) => currency?.currency_code
      );
      const categories = currencies.data.crypto_currencies.map((item) => ({
        label: item?.currency_code,
        value: item.id,
      }));
      setCategoriesOptions(categories);
      const selected_crypto = tokenBuyData.token_type
        ? currencies.data.crypto_currencies.find(
          (category) => category?.currency_code == tokenBuyData.token_type
        )
        : currencies.data.crypto_currencies[0];

      setSelectedCrypto(
        tokenBuyData.token_type
          ? categories.find(
            (category) => category.label == tokenBuyData.token_type
          )
          : categories[0]
      );

      const forexCurrency = currencies.data?.buy_forex_currencies?.map((forex) => ({
        label: forex.currency_code,
        value: forex.id,
      }));
      setForexOptions(forexCurrency);
      const selected_forex = tokenBuyData?.buy_via_currency
        ? currencies.data?.buy_forex_currencies?.find(
          (forex) => forex.currency_code == tokenBuyData?.buy_via_currency
        )
        : currencies.data?.buy_forex_currencies?.[0];

      setSelectedForex(
        tokenBuyData.buy_via_currency
          ? forexCurrency.find(
            (forex) => forex.label == tokenBuyData.buy_via_currency
          )
          : forexCurrency?.[0]
      );
      updateTokenBuyData({
        ...tokenBuyData,
        minAmount:
          parseInt(
            currencies.data?.user_commissions?.min_buy_amount
          ) <= 0
            ? 0.0000001
            : parseInt(
              currencies.data?.user_commissions?.min_buy_amount
            ).toFixed(8),
        maxAmount:
          parseInt(
            currencies.data?.user_commissions?.max_buy_amount
          ) <= 0
            ? 100000
            : parseInt(
              currencies.data?.user_commissions?.max_buy_amount
            ).toFixed(8),
        token_type: selected_crypto?.currency_code,
        crypto_exchange_rate: selected_crypto?.exchange_rate,
        crypto_source_currency: selected_crypto?.source_currency,
        buy_via_currency: selected_forex?.currency_code,
        forex_exchange_rate: selected_forex?.exchange_rate,
        forex_source_currency: selected_forex?.source_currency,
        buy_commission: selected_forex?.buy_commission,
      });

      const cryptoExchangeUrl = configuration.get("configData.socket_url") != ""
        ? configuration.get("configData.socket_url")
        : "wss://cms-proexchange.rare-able.com:3091";

      const socket = new WebSocket(cryptoExchangeUrl);

      socket.onopen = () => {
        const subscribeMessage = JSON.stringify({
          action: "subscribe",
          asset: currencies.data?.crypto_currencies?.map(
            (currency) => currency?.currency_code
          ),
        });
        socket.send(subscribeMessage);
      };

      socket.onmessage = (event) => {
        try {
          const parsedData = JSON.parse(event.data);

          parsedData.map(([asset, value]) => ({
            asset,
            value,
          }));
          setCryptoCurrencyList(
            parsedData.map(([asset, value]) => ({
              asset,
              value,
            }))
          );
          dispatch(
            getCryptoCurrency(
              parsedData.map(([asset, value]) => ({
                asset,
                value,
              }))
            )
          );
        } catch (error) { }
      };
      return () => {
        if (socket) {
          socket.close();
        }
      };
    }
    setSkipRender(false);
  }, [currencies]);

  useEffect(() => {
    if (
      !skipRender &&
      !currencies.loading &&
      !wallet.loading &&
      Object.keys(currencies.data).length > 0 &&
      Object.keys(wallet.data).length > 0
    ) {
      const selected_forex = tokenBuyData.buy_via_currency
        ? currencies.data?.buy_forex_currencies?.find(
          (forex) => forex?.currency_code == tokenBuyData.buy_via_currency
        )
        : currencies.data?.buy_forex_currencies?.[0];

      setWalletBalance(
        Math.round((wallet.data?.user_wallets.find(
          (data) => data?.currency_code == selected_forex?.currency_code
        )?.remaining_original) * 100) / 100
      );
    }
    setSkipRender(false);
  }, [currencies, wallet]);

  const invalidAmount =
    tokenBuyData.from_amount < tokenBuyData.minAmount ||
    tokenBuyData.from_amount == "" ||
    tokenBuyData.from_amount <= 0;

  const invalidMaxAmount =
    parseFloat(tokenBuyData.from_amount) > tokenBuyData.maxAmount

  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 99999999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 999999999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    input: (provided, state) => ({
      ...provided,
      color: "#7D7D7D",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 200,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "8px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "100%" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid transparent!important",
      borderRadius: "10px!important",
      boxShadow: "none!important",
      height: "42px",
      minWidth: "100px",
      cursor: "pointer",
      fontSize: "1em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#111",
      fontSize: "1.1em",
      fontWeight: "600",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#010101",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#298bff!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#298bff!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#010101",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused || state.isSelected ? "#F9C201" : "#fff",
      color: state.isFocused || state.isSelected ? "#000" : "#000",
      ":hover": {
        backgroundColor: "#F9C201",
        color: "#000",
      },
    }),
  };

  useEffect(() => {
    if (
      !skipRender &&
      !kycApplicant.loading &&
      Object.keys(kycApplicant.data).length > 0
    ) {
      kycApplicant.data?.link && kycApplicant.data?.link !== ""
        ? window.open(kycApplicant.data?.link, "_blank")
        : dispatch(profileStart());
    }
    setSkipRender(false);
  }, [kycApplicant]);

  useEffect(() => {
    if (
      !skipRender &&
      !kycStatusUpdate.loading &&
      Object.keys(kycStatusUpdate.data).length > 0
    ) {
      closeKycModal();
      window.location.reload();
    }
    setSkipRender(false);
  }, [kycStatusUpdate]);

  useEffect(() => {
    if (localStorage.getItem("token") || sessionStorage.getItem("token"))
      dispatch(fetchWalletDetailsStart());
  }, []);

  useEffect(() => {
    if (cryptoCurrencyList.length > 0)
      setToCurrencyValue(
        !isNaN(
          ((tokenBuyData.from_amount * 1) /
            (cryptoCurrencyList?.filter(
              (data) => tokenBuyData.token_type == data.asset
            ))[0]?.value) *
          tokenBuyData.forex_exchange_rate
          // (((tokenBuyData.buy_commission *
          //   tokenBuyData.from_amount *
          //   1) /
          //   (cryptoCurrencyList?.filter(
          //     (data) => tokenBuyData.token_type == data.asset
          //   ))[0]?.value) *
          //   tokenBuyData.forex_exchange_rate) /
          // 100
        )
          ? (
            (((tokenBuyData.from_amount * 1) /
              (cryptoCurrencyList?.filter(
                (data) => tokenBuyData.token_type == data.asset
              ))[0]?.value) *
              1) /
            tokenBuyData.forex_exchange_rate
            // (((tokenBuyData.buy_commission *
            //   tokenBuyData.from_amount *
            //   1) /
            //   (cryptoCurrencyList?.filter(
            //     (data) => tokenBuyData.token_type == data.asset
            //   ))[0]?.value) *
            //   1) /
            // tokenBuyData.forex_exchange_rate /
            // 100
          ).toFixed(8) : 0.000
      );
  }, [cryptoCurrencyList, tokenBuyData]);

  return (
    <>
      {currencies.loading ? (
        <div className="efi-exchange-swap-full-frame">
          <div className="efi-exchange-input-wrapped">
            <div className="efi-exchange-fields-card">
              {[...Array(2)].map((i) => (
                <Skeleton count={1} borderRadius={5} height={177} />
              ))}
            </div>
            <div className="efi-exchange-fields-card">
              {[...Array(2)].map((i) => (
                <Skeleton
                  count={1}
                  className="mt-3"
                  borderRadius={5}
                  height={177}
                />
              ))}
            </div>
            <div className="efi-swap-icons">
              <Skeleton count={1} circle={true} width={70} height={70} />
            </div>
          </div>

          <Skeleton count={1} height={50} borderRadius={10} />

          <div className="efi-swap-action">
            <Skeleton count={1} height={50} borderRadius={10} />
          </div>
          <div className="d-flex justify-content-center align-item-center ">
            <Skeleton count={1} height={22} width={100} borderRadius={10} />
          </div>
        </div>
      ) : Object.keys(currencies.data).length > 0 && currencies.data?.buy_forex_currencies?.length > 0 && currencies.data?.crypto_currencies?.length > 0 ? (
        <div className="efi-exchange-swap-full-frame">
          <div className="efi-exchange-input-wrapped">
            <div className="efi-exchange-fields-card">
              <div className="efi-exchange-fields-enter">
                <div className="efi-fields-label-enter">{t("send")}</div>
                <Form>
                  <Form.Group
                    className=""
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Control
                      type="text"
                      value={tokenBuyData.from_amount}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        const remainingOriginal = walletBalance || 0;

                        const percentage =
                          (parseFloat(newValue) / remainingOriginal) * 100;
                        setRangeValue(
                          isNaN(percentage)
                            ? 0
                            : Math.min(percentage, 100).toFixed(2)
                        );

                        onCryptoTokenChange(
                          newValue <= 100000
                            ? newValue
                            : tokenBuyData.from_amount
                        );
                      }}
                    />
                  </Form.Group>
                  {(localStorage.getItem("token") ||
                    sessionStorage.getItem("token")) && (
                      <Form.Group
                        controlId="exampleForm.ControlInput1"
                        className="trade-percent-range mt-2 mb-2"
                      >
                        <div className="range-label">
                          <span>{rangeValue}%</span>
                          <span>100%</span>
                        </div>
                        <Form.Range
                          disabled={!walletBalance || walletBalance <= 0}
                          min="0"
                          max="100"
                          value={rangeValue}
                          onChange={(e) => {
                            const data =
                              (parseFloat(e.target.value) / 100) *
                              (wallet.data?.user_wallets ? walletBalance : 0);
                            setRangeValue(e.target.value);
                            onCryptoTokenChange(data.toFixed(2));
                          }}
                        />
                        <div className="trade-range-value">
                          {rangeValue} % / 100 %
                        </div>
                      </Form.Group>
                    )}
                  {(localStorage.getItem("token") ||
                    sessionStorage.getItem("token")) && (
                      <div className="trade-stock-max">
                        <div className="trade-stock-max-info-sec">
                          <div className="trade-stock-max-info">
                            Available:
                            <span>{parseFloat(walletBalance)}</span>
                          </div>
                        </div>
                      </div>
                    )}
                </Form>
                {/* <div className="efi-receive-text">
                  {tokenBuyData.from_amount > 0
                    ? (
                        tokenBuyData.from_amount *
                          (1 / tokenBuyData.crypto_exchange_rate) *
                          tokenBuyData.forex_exchange_rate +
                        (tokenBuyData.commission *
                          tokenBuyData.from_amount *
                          (1 / tokenBuyData.crypto_exchange_rate) *
                          tokenBuyData.forex_exchange_rate) /
                          100
                      ).toFixed(4)
                    : "0.0000"}
                </div> */}
              </div>
              {currencies.data.buy_forex_currencies.length > 0 && (
                <div className="efi-exchange-fields-type">
                  <div className="efi-fields-label">
                    <CustomLazyLoad
                      src={
                        tokenBuyData.buy_via_currency
                          ? currencies.data?.buy_forex_currencies?.find(
                            (item) =>
                              item.currency_code ==
                              tokenBuyData.buy_via_currency
                          ).picture
                          : currencies.data?.buy_forex_currencies?.[0]?.picture
                      }
                      className="swap-icon crypto-icon"
                    />
                    <span>
                      {tokenBuyData.buy_via_currency}
                    </span>
                  </div>
                  <Select
                    className="fillert-drop half-sized"
                    options={forexOptions}
                    styles={customStyles}
                    isSearchable={false}
                    value={selectedForex}
                    onChange={(selectedOption) => onFiatChange(selectedOption)}
                  />
                </div>
              )}
            </div>
            <div className="efi-exchange-fields-card">
              <div className="efi-exchange-fields-enter">
                <div className="efi-fields-label-enter">{t("recieve")}</div>
                {/* <Form>
                  <Form.Group
                    className=""
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Control
                      type="text"
                      value={tokenBuyData.from_amount}
                      onChange={(e) => onCryptoTokenChange(e.target.value)}
                    />
                  </Form.Group>
                </Form> */}
                <div className="efi-receive-text">
                  {tokenBuyData.from_amount > 0
                    ?
                    toCurrencyValue
                    : "0.0000"}

                  {/* {tokenBuyData.from_amount > 0
                    ? (
                        tokenBuyData.from_amount *
                          (1 / tokenBuyData.crypto_exchange_rate) *
                          tokenBuyData.forex_exchange_rate +
                        (tokenBuyData.commission *
                          tokenBuyData.from_amount *
                          (1 / tokenBuyData.crypto_exchange_rate) *
                          tokenBuyData.forex_exchange_rate) /
                          100
                      ).toFixed(4)
                    : "0.0000"} */}
                </div>
              </div>
              {currencies.data.crypto_currencies.length > 0 && (
                <div className="efi-exchange-fields-type">
                  <div className="efi-fields-label">
                    <CustomLazyLoad
                      src={
                        tokenBuyData.token_type
                          ? currencies.data?.crypto_currencies?.find(
                            (item) =>
                              item.currency_code == tokenBuyData.token_type
                          ).picture
                          : currencies.data?.crypto_currencies?.[0]?.picture
                      }
                      className="swap-icon crypto-icon"
                    />
                    <span>
                      {tokenBuyData.token_type}
                    </span>
                  </div>
                  <Select
                    className="fillert-drop half-sized"
                    isSearchable={false}
                    options={categoriesOptions}
                    styles={customStyles}
                    value={selectedCrypto}
                    onChange={(selectedOption) =>
                      onCryptoChange(selectedOption)
                    }
                  />
                </div>
              )}
            </div>

            <div className={`efi-swap-icons ${isLoggedIn ? " top-space " : ""}`}>
              <Image
                src={window.location.origin + "/img/icon/swap.svg"}
                className="swap-icon"
              />
            </div>
          </div>
          {invalidAmount && (
            <p className="error-msg text-danger select-date buy-form">
              {t("invalid", { value: tokenBuyData.minAmount.toFixed(8) })}
            </p>
          )}
          {invalidMaxAmount && (
            <p className="error-msg text-danger select-date buy-form">
              {t("max_invalid", { value: tokenBuyData.maxAmount })}
            </p>
          )}

          {/* <div className="buy-radio-frame custome-radio">
            <Checkbox
              icon={
                <Image
                  src={window.location.origin + "/img/tick.svg"}
                  className="tick-mark-checkbox"
                />
              }
            ></Checkbox>
            <p>Partial Trading</p>
          </div> */}
          <div className="efi-swap-exchange-info efi-info-frame">
            <p>
              {" "}
              <span> {t("estimated_rate")} </span>{" "}
              <span>
                {1}{" "}
                {currencies.data.buy_forex_currencies.length > 0
                  ? tokenBuyData.buy_via_currency
                  : "N/A"}
                =
                {!isNaN(
                  (1 /
                    (cryptoCurrencyList?.filter(
                      (data) => tokenBuyData.token_type == data.asset
                    ))[0]?.value) *
                  tokenBuyData.forex_exchange_rate
                  // (((tokenBuyData.buy_commission *

                  //   1) /
                  //   (cryptoCurrencyList?.filter(
                  //     (data) => tokenBuyData.token_type == data.asset
                  //   ))[0]?.value) *
                  //   tokenBuyData.forex_exchange_rate) /
                  // 100
                )
                  ? (
                    ((1 /
                      (cryptoCurrencyList?.filter(
                        (data) => tokenBuyData.token_type == data.asset
                      ))[0]?.value) *
                      1) /
                    tokenBuyData.forex_exchange_rate
                    // (((tokenBuyData.buy_commission *
                    //   1) /
                    //   (cryptoCurrencyList?.filter(
                    //     (data) => tokenBuyData.token_type == data.asset
                    //   ))[0]?.value) *
                    //   1) /
                    // tokenBuyData.forex_exchange_rate /
                    // 100
                  ).toFixed(8) : 0.000}{" "}
                {tokenBuyData.token_type}
              </span>

            </p>
          </div>
          <div className="efi-swap-caution-part">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 32 32"
          >
            <g id="Layer_20" data-name="Layer 20">
              <path fill="#fbd63b" d="M28 28H4L16 6z"></path>
              <path fill="#f8be31" d="m16 6 12 22H16z"></path>
              <path fill="#333" d="m16 21-2-7h4z"></path>
              <circle cx="16" cy="25" r="1" fill="#333"></circle>
              <path d="M16 14h2l-2 7zM16 24a1 1 0 0 1 0 2z"></path>
            </g>
          </svg>
          <div className="caution-info">
          Cryptocurrencies are volatile, and prices can change rapidly, make sure to research and understand the market risks.
          </div>
            </div>
          <div className="efi-swap-action">
            <Button
              className="action-btn primary w-100 item_flex_x_5 justify-content-center"
              disabled={
                invalidAmount ||
                kycApplicant.buttonDisable ||
                parseFloat(tokenBuyData.from_amount) > tokenBuyData.maxAmount
              }
              onClick={onComplete}
            >
              {redirect ? t("exchange") : t("continue")}
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                enableBackground="new 0 0 512 512"
                viewBox="0 0 512 512"
              >
                <path
                  fill="#000"
                  d="M256 0C114.61 0 0 114.61 0 256s114.61 256 256 256c141.38 0 256-114.61 256-256S397.39 0 256 0zM150.85 110.96c76.7-54.95 181.89-41 241.9 29.67l37.1-26.57-8.57 116.84-113.38-29.49 35.85-25.67c-40.49-43.84-108.21-51.67-157.97-16.02-23.54 16.85-39.16 40.66-46.07 66.62l-58.09-14.95c10.29-39.13 33.77-75.03 69.23-100.42zm210.3 290.08c-76.7 54.95-181.89 41-241.9-29.67l-37.1 26.57 8.57-116.84 113.38 29.49-35.85 25.67c40.49 43.84 108.21 51.67 157.97 16.02 23.54-16.85 39.16-40.66 46.07-66.62l58.09 14.95c-10.29 39.13-33.77 75.03-69.23 100.42z"
                  data-name="162"
                  data-original="#000000"
                ></path>
              </svg> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="#171717"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M18.01 19.99A9.964 9.964 0 0112 22c-5.52 0-8.89-5.56-8.89-5.56m0 0h4.52m-4.52 0v5M22 12c0 1.82-.49 3.53-1.34 5M6.03 3.97A9.921 9.921 0 0112 2c6.67 0 10 5.56 10 5.56m0 0v-5m0 5h-4.44M2 12c0-1.82.48-3.53 1.33-5"
                ></path>
              </svg>
            </Button>
          </div>
          <div className="efi-swap-exchange-info">
            <p>{t("no_extra_fees")}</p>
          </div>
        </div>
      ) : <NoDataFound />}
      {kyc && <KYCUpdateModal kyc={kyc} closeKyc={closeKycModal} />}
    </>
  );
};

export default BuyCryptoForm;
